import Auth from "@aws-amplify/auth";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ImagesLoader from "../../../../helpers/ImagesLoader";
import { isModalOpened } from "../../../../redux/auth/auth.actions";
import CategoryButton from "./CategoryButton/CategoryButton";
import "./NavBar.scss";
import NavLinksDrawer from "./NavLinksDrawer/NavLinksDrawer";
import SignInSignUp from "./SignForms/SignInSignUp/SignInSignUp";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../../../config";
import { useState } from "react";
import { useEffect } from "react";

const NavBar = (props) => {
  const [isOpenNavDrawer, setisOpenNavDrawer] = useState(false);
  const [modelView, setmodelView] = useState("login");
  const [isOpenModal, setisOpenModal] = useState(false);
  const [activeLink, setactiveLink] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isSticky, setisSticky] = useState(false);
  const [sobreMenu, setsobreMenu] = useState("closed");
  const [cartCount, setcartCount] = useState([]);
  var searchInput = React.createRef();
  var menuRef = React.createRef();

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpenNavDrawer: false,
  //     modelView: "login",
  //     isOpenModal: false,
  //     activeLink: "",
  //     isLoggedIn: false,
  //     isSticky: false,
  //     sobreMenu: "closed",
  //     cartCount: [],
  //   };
  //   var searchInput = React.createRef();
  //   var menuRef = React.createRef();
  // }

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/cart/${localStorage.getItem("cid")}`)
        .then((res) => {
          console.log(res);
          const { success, data } = res.data;
          if (success) {
            setcartCount(data.products);
            // this.setState({
            //   cartCount: data.products,
            // });
            //console.log(data.products,'nnnnnnnnnnnnnnnnnnnnnn',this.state.cartCount.length)
          } else {
            console.log("something went wrong");
          }
        })
        .catch((Ex) => {});
    } catch (e) {
      console.log(e);
    }

    window.addEventListener("scroll", handleScroll);
  }, [])
  
  // componentDidMount() {
   
  // }

  const handleScroll = () => {
    const navbar = document.getElementsByClassName("navbar-wrapper");
    const sticky = navbar.offsetTop;
    if (window.pageYOffset >= 140) {
      // navbar.classList.add("sticky-navbar")
      if (!isSticky) {
        setisSticky(true);
        // this.setState({ isSticky: true });
      }
    } else {
      // navbar.classList.remove("sticky");
      if (isSticky) {
        setisSticky(false);
        // this.setState({ isSticky: false });
      }
    }
  };

  //handlers

  const signIn = () => {
    // this.setState({ isOpenModal: !isOpenModal, modelView: 'login', activeLink: "login" })
    if (localStorage.getItem("cid") !== null) {
      Auth.signOut();
      setcartCount([]);
      // this.setState({ cartCount: [] });

      localStorage.clear();
      toast.success("Logout successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setisLoggedIn(false);
      // this.setState({ isLoggedIn: false });
      window.location.reload();
    } else
      props.dispatch(
        isModalOpened({ isOpenModal: true, modelView: "login" })
      );
  };

  const closeModal = () => {
    // this.setState({ isOpenModal: !isOpenModal, activeLink: '' })
    props.dispatch(isModalOpened({ isOpenModal: false, modelView: "" }));
  };

  const toggleNavDrawer = () => {
    // const { isOpenNavDrawer } = this.state;
    try {
      setisOpenNavDrawer(!isOpenNavDrawer);
      // this.setState({ isOpenNavDrawer: !isOpenNavDrawer });
    } catch (e) {
      console.log(e);
    }
  };

  // on search button click handler
  const handleSearchClick = (event) => {
    let searchValue = "";
    if (
      !!event &&
      ((!!event.key && event.key === "Enter") ||
        (!!event.type && event.type === "click"))
    ) {
      searchValue = searchInput.current.value;
      props.history.push(`/search-product/query?${searchValue}`);
    } else {
      searchValue = searchInput.current.value;
    }
  };

  const setActiveLink = (navActiveLink) => {
    try {
      setActiveLink(navActiveLink);
      // this.setState({ activeLink: navActiveLink });
    } catch (e) {
      console.log(e);
    }
  };
  // render() {
  //   const {
  //     isOpenNavDrawer,
  //     isOpenModal,
  //     isLoggedIn,
  //     modelView,
  //     activeLink,
  //     isSticky,
  //     sobreMenu,
  //   } = this.state;
    // const { cartCount } = props;
    const credentialsSet = sessionStorage.getItem("number");

    return (
      <>
        <div className={`navbar-wrapper ${isSticky ? "sticky-navbar" : ""}`}>
          <div className={"col col-sm-2 col-md-2 col-lg-2"}>
            <div className={"row"}>
              <div className={"logo-wrapper"}>
                <Link to={"/"}>
                  <div
                    onClick={() => setActiveLink("")}
                    className={"logo"}
                    style={{
                      backgroundImage: `url(${ImagesLoader("logo")})`,
                    }}
                  ></div>
                </Link>
              </div>
              {/*end logo*/}

              {/*search bar*/}
              {/*<div className={ 'search-bar-wrapper' }>*/}
              {/*  <div className="search">*/}
              {/*    <div className="input-group">*/}
              {/*      <input*/}
              {/*        ref={ this.searchInput }*/}
              {/*        onKeyDown={ e => this.handleSearchClick(e) }*/}
              {/*        style={ { fontFamily: '\'Sen\', sans-serif' } }*/}
              {/*        type="text"*/}
              {/*        className="form-control"*/}
              {/*        placeholder="Search for a product, Brand etc."*/}
              {/*      />*/}
              {/*      <div className="input-group-append">*/}
              {/*        <button*/}
              {/*          onClick={ e => this.handleSearchClick(e) }*/}
              {/*          style={ { borderColor: '#ced4da', color: '#6c757d' } }*/}
              {/*          className="btn"*/}
              {/*          type="button">*/}
              {/*          <i className="fa fa-search"></i>*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*end search bar*/}
            </div>
          </div>
          <div className={"nav-links-container col col-sm-8 col-md-8 col-lg-8"}>
            <div className={"nav-links-wrapper stroke"}>
              <ul className="nav-links">
                {/* <li className={"nav-links-btn-wrap"}>
                  <CategoryButton />
                </li> */}
                <li className={"nav-links-btn-wrap"}>
                  <a
                    onClick={() => setActiveLink("sellers")}
                    className={"nav-links-btn"}
                    style={{
                      color: `${
                        activeLink === "sellers"
                          ? "rgb(3, 158, 255)"
                          : "inherit"
                      }`,
                    }}
                    href={"https://seller.bayqi.com/"}
                    target={"_blank"}
                  >
                    Para Seu Negocio
                  </a>
                </li>
                <li className={"nav-links-btn-wrap"}>
                  <Link
                    onClick={() => setActiveLink("faqs")}
                    className={"nav-links-btn"}
                    style={{
                      color: `${
                        activeLink === "faqs" ? "rgb(3, 158, 255)" : "inherit"
                      }`,
                    }}
                    to={"/faqs"}
                  >
                    Centro de Ajuda
                  </Link>
                </li>
                <li className={"nav-links-btn-wrap"}>
                  <div
                    className={"nav-links-btn"}
                    ref={menuRef}
                    onMouseEnter={() => setsobreMenu("open")}
                  >
                    <Link
                      to="/"
                      className={"nav-links-btn"}
                      style={{
                        color: `${
                          activeLink === "sobre"
                            ? "rgb(3, 158, 255)"
                            : "inherit"
                        }`,
                      }}
                    >
                      Sobre BayQi
                    </Link>
                    <i className="fa fa-angle-down"></i>
                  </div>

                  <ControlledMenu
                    state={sobreMenu}
                    anchorRef={menuRef}
                    onMouseLeave={() => setsobreMenu("closed")}
                    onClose={() => setsobreMenu("closed")}
                  >
                    <MenuItem
                      onClick={() => props.history.push("/sobrebayqi")}
                    >
                      Sobre Nós
                    </MenuItem>
                    <MenuItem
                      onClick={() => props.history.push("/baixeapp")}
                    >
                      Baixe o App
                    </MenuItem>
                  </ControlledMenu>
                </li>

                {/* <li className={"nav-links-btn-wrap"}>
                  <Link
                    onClick={() => setActiveLink("blogs")}
                    className={"nav-links-btn"}
                    style={{
                      color: `${
                        activeLink === "blogs" ? "rgb(3, 158, 255)" : "inherit"
                      }`,
                    }}
                    to={"/blog"}
                  >
                    Blog
                  </Link>
                </li> */}

                {/* <li className={"nav-links-btn-wrap"}>
                  <Link
                    onClick={() => setActiveLink("blogs")}
                    className={"nav-links-btn"}
                    style={{
                      color: `${
                        activeLink === "blogs" ? "rgb(3, 158, 255)" : "inherit"
                      }`,
                    }}
                    to={"/panel"}
                  >
                    Panel
                  </Link>
                </li> */}
              </ul>

              {/*<SignInSignUp*/}
              {/*  screenType={ modelView }*/}
              {/*  isOpenModal={ isOpenModal }*/}
              {/*  closeModal={ () => this.setState({ isOpenModal: !isOpenModal, activeLink: '' }) }*/}
              {/*  isLoggedIn={ () => this.setState({ isLoggedIn: !isLoggedIn }) }*/}
              {/*/>*/}
              <SignInSignUp
                screenType={props.auth.modelView}
                isOpenModal={props.auth.isOpenModal}
                closeModal={() => closeModal()}
                isLoggedIn={() => setisLoggedIn(!isLoggedIn)}
              />
            </div>
          </div>
          {/* <div className={"col col-sm-2 col-md-2 col-lg-2"}>
            <div className={"d-flex flex-row flex-wrap"}>
              <div
                onClick={() => props.history.push("/cart")}
                className={"cart-wrap"}
              >
                <div className={"d-flex flex-row flex-wrap"}>
                  <i
                    style={{ color: "#5db7fe" }}
                    className="fa fa-2x fa-shopping-bag"
                  >
                    <sup style={{ color: "red", fontSize: "19px" }}>
                      {cartCount.length === 0
                        ? ""
                        : cartCount.length}
                    </sup>
                  </i>
                </div>
              </div>

              <div
                className={"profile-btn"}
                onClick={() => {
                  signIn();
                }}
              >
                <div style={{ margin: "0 1em 0 0" }}>
                  {localStorage.getItem("cid") !== null
                    ? "Sign Out"
                    : "Sign In"}
                </div>

                <div>
                  <i className="fa fa-user-circle"></i>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className={"small-navbar-wrapper"}>
          <div className={"logo-wrapper"}>
            <Link to={"/"}>
              <div
                onClick={() => setActiveLink("")}
                className={"logo"}
                style={{
                  backgroundImage: `url(${ImagesLoader("logo")})`,
                }}
              ></div>
            </Link>
          </div>
          <div className={"navbar-hamburger-btn"}>
            <i
              onClick={toggleNavDrawer}
              style={{ fontSize: "1.5em", color: "inherit", cursor: "pointer" }}
              className="fa fa-bars"
            ></i>
          </div>
        </div>

        <NavLinksDrawer
          isOpenNavDrawer={isOpenNavDrawer}
          toggleNavDrawer={toggleNavDrawer}
        />
      </>
    );
  }

const mapStateToProps = (state) => {
  const cartCount =
    !!state.cart.cartItems && state.cart.cartItems.length > 0
      ? state.cart.cartItems.length
      : 0;
  const auth = state.auth;
  return {
    cartCount,
    auth,
  };
};

export default withRouter(connect(mapStateToProps)(NavBar));
