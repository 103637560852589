import React        from 'react';
import { useTranslation } from 'react-i18next';
import { Link }     from 'react-router-dom';
import ImagesLoader from '../../../../helpers/ImagesLoader';
import './SectionThree.scss';
import slideImg1 from "../../../../assets/images/01.jpg"
import slideImg2 from "../../../../assets/images/02.jpg"
import slideImg3 from "../../../../assets/images/03.jpg"
import slideImg4 from "../../../../assets/images/04.jpg"
import slideImg5 from "../../../../assets/images/05.jpg"
// import StartImg3 from "../../../../assets/images/02 couple.jpg"
function SectionThree(props) {
  const [t] = useTranslation();
  return (
    <div className={ "d-flex flex-row flex-wrap  mb-5" }>
      <div className={ "ads-sec-left" }>
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={ slideImg1 } alt="First slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={ slideImg2 } alt="Second slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={ slideImg3 } alt="Third slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={ slideImg4 } alt="Third slide"/>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={ slideImg5 } alt="Third slide"/>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">{t("Previous")}</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">{t("Next")}</span>
          </a>
        </div>

      </div>
      <div className={ "ads-sec-right" }>
        <div>
          <Link to={ "/" }>
            <div className={ "right-sec-ads-img" } style={ {
              backgroundImage: `url(${ ImagesLoader('watch-pic') })`,
            } }>
            </div>
          </Link>
        </div>
        <div>
          <Link to={ "/" }>
            <div className={ "right-sec-ads-img" } style={ {
              backgroundImage: `url(${ ImagesLoader('qr-code-pic') })`,
            } }>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SectionThree;


