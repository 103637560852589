import React, { useState, useLayoutEffect, useEffect } from "react";
import "./SideDrawer.css";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TrendingUp,
  Poll,
  CreditCard,
  Money,
  TransferWithinAStation,
  AccountBalanceWallet,
  AccountBalance,
  People,
  FeaturedVideo,
  LocalOffer,
  Book,
  Payment,
  Business,
  AddLocation,
  ChevronLeft,
  ChevronRight,
  Tool,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
const Sidebar = ({ children, isOpen }) => {
  const useStyles = makeStyles((theme) => ({
    listItemText: {
      fontSize: "13px",
      color: "#fff",
    },
    listItem: {
      "&:hover": {
        backgroundColor: "rgba(14, 75, 116, 0.4) ",
      },
    },
    icon: {
      color: "red",
    },
  }));
  const classes = useStyles();
  const [t] = useTranslation();
  const [isOpenState, setIsOpen] = useState(null);
  const [routes, setRoutes] = useState([
    {
      id: 1,
      path: "/panel",
      name: t("Stats"),
      Icon: <TrendingUp style={{ color: "#fff" }} />,
      active: true,
    },
    {
      id: 2,
      path: "/panel/transactions",
      name: t("Transactions"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 3,
      path: "/panel/transaction-history",
      name: t("Transaction History"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 4,
      path: "/panel/generate-link",
      name: t("Generate Link"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 5,
      path: "/panel/pay-per-link",
      name: t("Pay Per Link"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 6,
      path: "/panel/pay-by-catalog",
      name: t("Pay By Catalog"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 7,
      path: "/panel/categories",
      name: t("Categories"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 8,
      path: "/panel/products",
      name: t("Products"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 9,
      path: "/panel/definitions",
      name: t("Definitions"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 11,
      path: "/panel/swing",
      name: t("Swing"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 12,
      path: "/panel/bank-transfer",
      name: t("Bank Transfer"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
    {
      id: 13,
      path: "/panel/bank-transfer-history",
      name: t("Bank Transfer History"),
      Icon: <CreditCard style={{ color: "#fff" }} />,
      active: false,
    },
  ]);
  const selectTab = (index) => {
    let dupRoutes = [...routes];
    dupRoutes.forEach((element, i) => {
      if (index === i) {
        dupRoutes[i].active = true;
      } else {
        dupRoutes[i].active = false;
      }
    });
    setRoutes(dupRoutes);
  };
  const updateDimensions = () => {
    if (window.innerWidth < 900) {
      setIsOpen(false);
    } else if (window.innerWidth > 900) {
      setIsOpen(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        backgroundColor: "#f7f7f7",
      }}
    >
      {isOpenState ? (
        <div className={"open"}>
          <List>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                style={{
                  boxShadow: "none",
                }}
                onClick={() => setIsOpen(!isOpenState)}
              >
                <ChevronLeft style={{ color: "#fff" }} />
              </IconButton>
            </div>
            {routes.map((item, index) => (
              <ListItem
                disableRipple
                className={classes.listItem}
                button
                onClick={() => selectTab(index)}
                style={{
                  backgroundColor: item.active
                    ? "rgba(14, 75, 116, 0.8)"
                    : "rgba(0,0,0,0)",
                }}
                component={Link}
                to={item.path}
                key={index}
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </div>
      ) : (
        <div className={"side-drawer"}>
          <List>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                style={{
                  boxShadow: "none",
                }}
                onClick={() => setIsOpen(!isOpenState)}
              >
                <ChevronRight style={{ color: "#fff" }} />
              </IconButton>
            </div>
            {routes.map((item, index) => (
              <Tooltip placement="right-end" title={item.name}>
                <ListItem
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  button
                  component={Link}
                  to={item.path}
                  key={index}
                >
                  <ListItemIcon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.Icon}
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </div>
      )}
      <div
        style={{
          width: "100%",
          backgroundColor: "#f7f7f7",
          padding: "3% 0px 3% 0px",
          height: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
