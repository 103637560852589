import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagesLoader }     from '../../../helpers';
import './SobrePage.scss';


export default function SobrePage() {
  const [t] = useTranslation();
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <div className={ "sobre-wrapper" }>

      <div className={ "sobre-sec-wrapper" }>
        <div className={ "sobre-sec-left" }>
          <div className={ "text" }>
            <div className={ "title" }>{t("A visão de conectar a África com o Mundo")}</div>
            <div className={ "detail" }>
              {t("BayQi foi inaugurado no dia 26 de Abril de 2016 em Luanda, dentro do apartamento numa sala pequena com um computador e 6 pessoas. A visão de trazer para o mundo algo novo, que adiciona e muda a vida das pessoas e que conecta África com o mundo move-nos todos os dias")}
            </div>
          </div>
        </div>
        <div className={ "sobre-sec-right" }>
          <div style={ { backgroundImage: `url(${ ImagesLoader('sobre1') })` } } className={ "img" }></div>
        </div>
      </div>


      <div className={ "sobre-sec-wrapper" }>
        <div className={ "sobre-sec-right" }>
          <div style={ { backgroundImage: `url(${ ImagesLoader('sobre2') })` } } className={ "img" }></div>
        </div>
        <div className={ "sobre-sec-left" }>
          <div className={ "text" }>
            <div className={ "title" }>
              {t("Construindo um novo mundo (Cashless)")}
            </div>
            <div className={ "detail" }>
              {t("Inovamos pelo futuro, apostamos numa sociedade cashless, numa maior segurança digital e conectamos com o físico ao permitir pagar com QR Code. Nossa responsabilidade é de garantir confiança e máxima protecção no comércio tornando o sonho de muitos possível.")}
            </div>
          </div>
        </div>
      </div>


      <div className={ "sobre-sec-wrapper" }>
        <div className={ "sobre-sec-left" }>
          <div className={ "text" }>
            <div className={ "title" }>
              {t("Apaixonados pela mudança")}
            </div>
            <div className={ "detail" }>
              {t("Inovação, obsessão, evolução e persistência estão no DNA da BayQi. Estamos a frente na transformação, inclusão financeira e digital. Acreditamos que a tecnologia tem o poder de transformar o mundo")}
            </div>
          </div>
        </div>
        <div className={ "sobre-sec-right" }>
          <div style={ { backgroundImage: `url(${ ImagesLoader('sobre3') })` } } className={ "img" }></div>
        </div>
      </div>
    </div>
  )
}