import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import NewBlogCard from "./NewBlogCard";
import RightBlogCard from "./RightBlogCard";

const NewActiveBlogPage = (props) => {
  const [t] = useTranslation();
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     list: [],
  //     currentPage: 1,
  //     itemsPerPage: 3
  //   }
  // }
  useEffect(() => {
    setList(props.list);
    setCurrentPage(1);
    setItemsPerPage(3);
  }, [JSON.stringify(props.list)]);
  // componentDidMount() {
  //   // const { activeTab } = this.props;
  //   // if (activeTab === 'all') {
  //   //   this.setState({ list: Items.map(x => x) })
  //   // }
  //   // else {
  //   //   const filteredList = Items.filter(li => li.type === activeTab)
  //   //   this.setState({ filteredList })
  //   // }
  //   const {list}=this.props;
  //   this.setState({list})
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {

  //   const {list}=this.props;
  //   if(JSON.stringify(list)!==JSON.stringify(prevProps.list)){
  //     this.setState({list,currentPage:1,itemsPerPage:3})
  //   }
  // }
  // const { activeTab } = this.props;
  // console.log("............................ ",activeTab)
  //
  // if (activeTab !== prevProps.activeTab) {
  //   if (activeTab === 'all') {
  //     console.log("............................ ",activeTab)
  //
  //     this.setState({ list: Items.map(x => x) })
  //   }
  //   else {
  //     console.log("............................ ",activeTab)
  //
  //     const filteredList = Items.filter(li => li.type === activeTab)
  //     console.log("............................ ",filteredList)
  //
  //     this.setState({ list: filteredList })
  //   }
  // }

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const { getBlogDetail } = props;
  // Logic for displaying items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className={"d-flex flex-wrap flex-row mt-4 mb-2"}>
      <div className={"col col-lg-9 col-md-9 col-sm-12"}>
        <div className={"d-flex flex-row flex-wrap"}>
          {/* {
              !!list && list.length > 0 ? currentItems.map((li, idx) => {
                return <NewBlogCard key={ idx } card={ li } getBlogDetail={getBlogDetail}/>
              }) : ('')
            } */}
          {/* {currentItems.map((li, idx) => {
                return <NewBlogCard  getBlogDetail={getBlogDetail}/>
              })} */}
          <NewBlogCard getBlogDetail={getBlogDetail} />
        </div>
        <div style={{ margin: "2em auto", width: "fit-content" }}>
          {Math.ceil(list.length / itemsPerPage) > 1 && (
            <ReactPaginate
              previousLabel={<i className="fa fa-chevron-left"></i>}
              nextLabel={<i className="fa fa-chevron-right"></i>}
              breakLabel={"..."}
              pageCount={Math.ceil(list.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
      <div className={"col col-lg-3 col-md-3 col-sm-12"}>
        <h6>{t("Latest Posts")}</h6>
        <hr />
        <div className={"d-flex flex-row flex-wrap"}>
          {!!currentItems && currentItems.length > 0
            ? currentItems.map((li, idx) => {
                return <RightBlogCard key={idx} card={li} />;
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default NewActiveBlogPage;

// const Items = [
//   {
//     id:1,
//     title: 'Artifact title',
//     description: 'Artifact des',
//     type: 'art',
//     src: '/'
//   },
//   {
//     id:2,
//     title: 'Artifact title',
//     description: 'Artifact des',
//     type: 'art',
//     src: '/'
//   },
//   {
//     id:3,
//     title: 'Artifact 2 title',
//     description: 'Artifact 2 des',
//     type: 'art',
//     src: '/'
//   },
//   {
//     id:4,
//     title: 'news title',
//     description: 'news des',
//     type: 'news',
//     src: '/'
//   },
//   {
//     id:5,
//     title: 'news2 title',
//     description: 'news2 des',
//     type: 'news',
//     src: '/'
//   },
//   {
//     id:6,
//     title: 'magazine title',
//     description: 'magzine des',
//     type: 'mag',
//     src: '/'
//   }
//   ,
//   {
//     id:7,
//     title: 'magazine title',
//     description: 'magzine des',
//     type: 'mag',
//     src: '/'
//   }
//   ,
//   {
//     id:8,
//     title: 'magazine title',
//     description: 'magzine des',
//     type: 'mag',
//     src: '/'
//   }
//   ,
//   {
//     id:9,
//     title: 'magazine title',
//     description: 'magzine des',
//     type: 'mag',
//     src: '/'
//   }
//   ,
//   {
//     id:10,
//     title: 'magazine title',
//     description: 'magzine des',
//     type: 'mag',
//     src: '/'
//   }
// ]
