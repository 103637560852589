import React    from 'react';
import { Link } from 'react-router-dom';
import './FooterLinkList.css';

class FooterLinkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      links: []
    }
  }
  componentDidMount() {
    const {title,links} = this.props;
    this.setState({
      title,
      links
    })
  }

  render() {
    return (
      <div className={ 'links-column' }>
        {this.getList()}
      </div>
    )
  }

  //helper
  getList=()=>{
    const {title,links}=this.state;
    return (
      <>
        <div className={ 'links-column-title' }>{title}</div>
        <ul className={ 'links-column-list' }>
          {
            links.map((link,idx)=><li key={idx}><Link className={ 'footer-links' } to={ link.to }>{link.linkTitle}</Link></li>)
          }
        </ul>
      </>
    )
  }

}

export default FooterLinkList;


