import React, { useState }                from 'react';
import { connect }          from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { userLinks }        from '../../../../../DummyData';
import { isModalOpened }    from '../../../../../redux/auth/auth.actions';

import './NavLinksDrawer.scss';

const NavLinksDrawer = (props) => {
  const [modelView, setmodelView] = useState('signIn');
  const [isOpenModal, setisOpenModal] = useState(false);
  const [links, setlinks] = useState([
    {
      title: "My Cart",
      subLinks: [],
      link: "/cart"
    },
    {
      title: "My Orders",
      link: "/"
    },
    {
      title: "BayQi Wallet",
      link: "/"
    },
    {
      title: "Login / Sing Up",
      link: "/"
    },
  ]);
  const [ClientLinks, setClientLinks] = useState([
    {
      title: "Client support (+244 939 725 027)",
      link: "/cart"
    },
    {
      title: "Track Order",
      link: "/"
    },
    {
      title: "Our Payment Methods",
      link: "/"
    },
    {
      title: "Help",
      link: "/"
    },
  ]);
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     modelView: 'signIn',
  //     isOpenModal: false,
  //     links: [
  //       {
  //         title: "My Cart",
  //         subLinks: [],
  //         link: "/cart"
  //       },
  //       {
  //         title: "My Orders",
  //         link: "/"
  //       },
  //       {
  //         title: "BayQi Wallet",
  //         link: "/"
  //       },
  //       {
  //         title: "Login / Sing Up",
  //         link: "/"
  //       },
  //     ],
  //     ClientLinks: [
  //       {
  //         title: "Client support (+244 939 725 027)",
  //         link: "/cart"
  //       },
  //       {
  //         title: "Track Order",
  //         link: "/"
  //       },
  //       {
  //         title: "Our Payment Methods",
  //         link: "/"
  //       },
  //       {
  //         title: "Help",
  //         link: "/"
  //       },
  //     ]
  //   }
  // }

  
  // componentDidMount() {
  //   // fetch nav bar links
  // }

  
  const toggleNavDrawer = () => {
    try {
      props.toggleNavDrawer()
    }
    catch (e) {
      console.log(e)
    }
  }

  const createAccount = () => {
    props.dispatch(isModalOpened({ isOpenModal: true, modelView: 'login' }))
    toggleNavDrawer()
  }

  const signUp = () => {
    props.dispatch(isModalOpened({ isOpenModal: true, modelView: 'create' }))
    toggleNavDrawer()
  }

  const getClientSupportLinks = () => {
    // const { ClientLinks } = this.state;
    try {
      if (!!ClientLinks && ClientLinks.length > 0) {
        return ClientLinks.map((link, idx) => {
          return (
            <li key={ idx } className={ 'client-support-links-drawer-btn-wrap' }>
              <Link className={ 'client-support-links-drawer-btn' } style={ { color: 'inherit' } }
                    to={ link.link }>{ link.title }</Link>
            </li>
          )
        })
      }
      else {
        return '';
      }
    }
    catch (e) {
      console.log(e)
    }
  }

    // const { links, isOpenModal } = this.state;
    const { isOpenNavDrawer } = props;
    return (
      <div className={ `nav-links-drawer-wrapper ${ isOpenNavDrawer ? 'drawer-opened' : 'drawer-closed' } ` }>
        <div className={ "nav-links-drawer-head" }>
          <div className={ "nav-links-drawer-head-close-btn" }>
            <i onClick={ toggleNavDrawer } style={ { fontSize: '2em', color: 'inherit', cursor: 'pointer' } }
               className="fa fa-times"></i>
          </div>
        </div>

        <div className={ "nav-links-drawer-ul-wrap" }>
          <ul className="nav nav-links-drawer-ul" style={ { flexFlow: 'wrap' } }>

            {/* <li className={ 'nav-links-drawer-btn-wrap' }>
              <div className={ 'nav-links-drawer-btn' }>Utilizadores</div>
              <ul>

                {
                  userLinks.map((navLink, index) => {
                    return (
                      <li key={index}>
                        <div className={ 'nav-links-drawer-btn' }>{ navLink.title }</div>
                        <ul>
                          {
                            navLink.links.map((links, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    onClick={ () => toggleNavDrawer() }
                                    className={ 'nav-links-drawer-btn' }
                                    style={ { color: 'inherit' } }
                                    to={ links.link }>
                                    { links.title }
                                  </Link>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </li>
                    )
                  })
                }
              </ul>
            </li> */}

            <li className={ 'nav-links-drawer-btn-wrap' }>
              <a
                onClick={ () => toggleNavDrawer() }
                className={ 'nav-links-drawer-btn' }
                style={ { color: 'inherit' } }
                href={ "https://bayqi-seller-web-app.web.app/" } target={ "_blank" }>
               Para o Seu Negócio 
              </a>
            </li>

            <li className={ 'nav-links-drawer-btn-wrap' }>
              <Link
                onClick={ () => toggleNavDrawer() }
                className={ 'nav-links-drawer-btn' }
                style={ { color: 'inherit' } }
                to={ "/faqs" }>
                Centro de Ajuda
              </Link>
            </li>

            <li className={ 'nav-links-drawer-btn-wrap' }>
              <div className={ 'nav-links-drawer-btn' }>Sobre BayQi</div>
              <ul>
                <li>
                  <Link
                    onClick={ () => toggleNavDrawer() }
                    className={ 'nav-links-drawer-btn' }
                    style={ { color: 'inherit' } }
                    to={ "/sobrebayqi" }>
                    Sobre Nós
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={ () => toggleNavDrawer() }
                    className={ 'nav-links-drawer-btn' }
                    style={ { color: 'inherit' } }
                    to={ "/baixeapp" }>
                    Baixe o App
                  </Link>
                </li>
              </ul>

            </li>

            {/* <li className={ 'nav-links-drawer-btn-wrap' }>
              <Link
                onClick={ () => toggleNavDrawer() }
                className={ 'nav-links-drawer-btn' }
                style={ { color: 'inherit' } }
                to={ "/blog" }>
                Blog
              </Link>
            </li> */}

          </ul>
        </div>
        <div className={ "client-support-links" }>
          <ul className="nav client-support-links-drawer-ul" style={ { flexFlow: 'column wrap', margin: "2rem" } }>

            <li><a onClick={ () => createAccount() }><i className="fa fa-user-circle"></i> Create Account</a>
            </li>
            <li><a onClick={ () => signUp() }><i className="fa fa-sign-in"></i> Sign Up</a></li>

          </ul>
        </div>

      </div>
    )
  }

export default withRouter(connect(null)(NavLinksDrawer));


