import React from "react";
import ProductCard from "../../../LandingPage/Section5/ProductCard/ProductCard";
import "./FilteredProducts.scss";

function FilteredProducts({ props }) {
  const [products, setProducts] = React.useState([props.products]);

  //helpers
  // get filtered products
  const getFilteredProducts = () => {
    try {
      return products.map((pro, idx) => {
        return (
          <ProductCard
            key={idx}
            id={pro.id}
            img={pro.img}
            label={pro.label}
            discount={pro.discount}
            originalPrice={pro.originalPrice}
            discountedPrice={pro.discountedPrice}
            category={pro.category}
            categoryName={pro.categoryName}
            {...pro}
          />
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"filtered-products-wrap"}>{getFilteredProducts()}</div>
  );
}

export default FilteredProducts;
