import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../config";
import {
  provinces,
  luandaDelMethods,
  notLuandaDelMethod,
} from "../../../../DummyData";
import { ImagesLoader } from "../../../../helpers";
import Select from "react-select";
import "./DeliveryDetailCard.scss";
import { useHistory, withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const PaymentDeliveryCard = (props) => {
  const [t] = useTranslation();
  const [address, setaddress] = useState("");
  const [number, setnumber] = useState("");
  const [email, setemail] = useState("");
  const [province, setprovince] = useState("");
  const [delMethod, setdelMethod] = useState("BayQi Provinces");
  const [billingAddress, setbillingAddress] = useState("");
  const [error, seterror] = useState({});
  const [formError, setformError] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userName, setuserName] = useState("");
  const [userInfo, setuserInfo] = useState({});

  const history = useHistory();

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     address: "",
  //     number: "",
  //     email: "",
  //     province: "",
  //     delMethod: "BayQi Provinces",
  //     billingAddress: "",
  //     error: {},
  //     formError: "",
  //     userEmail: "",
  //     userName: "",
  //     userInfo: {},
  //   };
  // }

  useEffect(() => {
    window.$amount = props.bagTotal;
    console.log("THIS IS THE VAL", window.$amount);
    // this is not functional rn
    axios
      .get(`${baseUrl}/customers/${localStorage.getItem("cid")}`)
      // .then((res) => console.log("user api response: ", res.data.data))
      .then((res) => setuserInfo(res.data.data))
      .then((res) => (window.$userInformation = res.data.data.phoneNumber))
      .catch((err) => console.error("got error fething user data: ", err));
  });

  // componentDidMount() {
  //   window.$amount = this.props.bagTotal;
  //   console.log("THIS IS THE VAL", window.$amount);
  //   // this is not functional rn
  //   axios
  //     .get(`${baseUrl}/customers/${localStorage.getItem("cid")}`)
  //     // .then((res) => console.log("user api response: ", res.data.data))
  //     .then((res) => this.setState({ userInfo: res.data.data }))
  //     .then((res) => (window.$userInformation = res.data.data.phoneNumber))
  //     .catch((err) => console.error("got error fething user data: ", err));

  //   // console.log("THIS IS INFO OF USER: ", this.state.userInfo);
  // }

  const setValues = (event) => {
    // const name = event.target.name;
    // const value = event.target.value;
    // const { error } = this.state;
    // this.setState({
    //   [name]: value,
    //   error: {
    //     ...error,
    //     [name]: "",
    //   },
    // });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const confirmOrder = () => {
    // this.setState({ formError: "" });
    setformError("");

    if (delMethod === "") {
      setdelMethod("BayQi Provinces");
      // this.setState({ delMethod: "BayQi Provinces" });
    }

    if (
      address === "" ||
      province === "" ||
      userName === "" ||
      userEmail === "" ||
      billingAddress === "" ||
      (province === "Luanda" && delMethod === "")
    ) {
      setformError(t("all fields are mandatory"));
      // this.setState({ formError: "all fields are mandatory" });
    }
    // console.log()
    // if (province !== "Luanda" && delMethod === "") {
    //   this.setState({ delMethod: "BayQi Provinces" });
    // }
    else {
      axios
        .post(`${baseUrl}/orders`, {
          products: props.cartItems,
          paymentMethod: "EMIS",
          totalPrice: props.bagTotal,
          province: province,
          deliveryMethod: delMethod,
          customerId: localStorage.getItem("cid"),
          customerName: userName,
          customerEmail: userEmail,
          shippingAddress: address,
          billingAddress,
        })
        .then((res) => console.log("got this res on order api: ", res))
        .then(() => history.push("/order-payment"))
        .catch((err) => console.error("got err creating order: ", err));
    }
    // axios
    //   .put(
    //     `${baseUrl}/customers`,
    //     {
    //       id: localStorage.getItem("cid"),
    //       name: "Nouman",
    //       email: "nouman@mail.com",
    //       phoneNumber: "23232333",
    //       billingAddress: address,
    //       profile: null,
    //       shippingAddress: address,
    //     },
    //     { headers: { "Access-Control-Allow-Origin": "*" } }
    //   )
    //   .then((res) => console.log("got this res: ", res))
    //   .catch((err) => console.log("Got error updating customer: ", err));

    // fields
    // address === "" ||
    // province === "" ||
    // userName === "" ||
    // userEmail === "" ||
    // billingAddress
  };

  const validator = () => {
    const regex = /^\s*$/;
    let customError = {};

    if (address.length === 0) {
      customError = {
        ...customError,
        address: t("Address is required"),
      };
    } else {
      if (regex.test(address)) {
        customError = {
          ...customError,
          address: t("Empty space is not allowed"),
        };
      }
    }

    if (number.length === 0) {
      customError = {
        ...customError,
        number: t("Contact number is required"),
      };
    } else {
      if (regex.test(number)) {
        customError = {
          ...customError,
          number: t("Empty space is not allowed"),
        };
      }
    }

    if (!validateEmail(email) && email.length > 3) {
      customError = {
        ...customError,
        email: t("Wrong email"),
      };
    }

    if (Object.keys(customError).length > 0) {
      seterror(customError);
      // this.setState({
      //   error: customError,
      // });
    } else {
      props.getConfirmationDetails({ address, number, email });
    }
  };

  // console.log("cartItems: ", this.props.cartItems);
  return (
    <div className={"delivery-detail-wrap d-flex flex-column flex-wrap"}>
      <div className={"delivery-detail-head d-flex flex-row flex-wrap"}>
        <div className={"col col-sm-3 col-md-3 col-lg-3"}>
          <div
            style={{
              backgroundImage: `url(${ImagesLoader("delivery-truck")})`,
            }}
            className={"delivery-detail-head-icon "}
          />
          {/*icon*/}
        </div>
        <div className={"col col-sm-9 col-md-9 col-lg-9"}>
          <div className={"delivery-detail-head-title "}>
            {/* Delivery Details */}
            {t("Confirm Delivery")}
          </div>
        </div>
      </div>

      <div className={"delivery-detail-detail d-flex flex-column flex-wrap"}>
        {/* Name */}
        <div className="form-group">
          <label
            className={"delivery-detail-input-label"}
            htmlFor="ShippingAddress"
          >
            {t("Username")}
          </label>
          <input
            name={"address"}
            type="text"
            className={`delivery-detail-input form-control`}
            id="ShippingAddress"
            aria-describedby="ShippingAddress"
            placeholder={"Ex: R Pescador Bacalhoeiro"}
            value={userName}
            onChange={(e) => setuserName(e.target.value)}
          />
        </div>

        {/* Email */}
        <div className="form-group">
          <label
            className={"delivery-detail-input-label"}
            htmlFor="ShippingAddress"
          >
            {t("Email")}
          </label>
          <input
            name={"address"}
            type="text"
            className={`delivery-detail-input form-control`}
            id="ShippingAddress"
            aria-describedby="ShippingAddress"
            placeholder={"Ex: R Pescador Bacalhoeiro"}
            value={userEmail}
            onChange={(e) => setuserEmail(e.target.value)}
          />
        </div>

        {/* Province */}
        <div className="form-group">
          <label
            className={"delivery-detail-input-label"}
            htmlFor="ShippingAddress"
          >
            {t("Province")}
          </label>
          <Select
            options={provinces}
            // value={this.state.province}
            onChange={({ value }) => {
              setprovince(value);
              if (value === "Luanda") {
                setdelMethod("");
              } else {
                setdelMethod("BayQi Provinces");
              }
            }}
          />
        </div>

        {/* delivery method */}
        {province === "Luanda" && (
          <div className="form-group">
            <label
              className={"delivery-detail-input-label"}
              htmlFor="ShippingAddress"
            >
              {t("Delivery Method")}
            </label>

            <Select
              options={luandaDelMethods}
              onChange={({ value }) => setdelMethod(value)}
            />
          </div>
        )}

        <>
          {/* <div className="form-group">
            <label
              className={"delivery-detail-input-label"}
              htmlFor="ContactNumber"
            >
              Contact Number
            </label>
            <input
              name={"number"}
              type="number"
              className={`delivery-detail-input form-control
              ${!!error.number && error.number.length > 0 ? "is-invalid" : ""}`}
              id="ContactNumber"
              aria-describedby="ContactNumber"
              placeholder={"Ex: 21 224 792 7649"}
              value={number}
              min="0"
              onChange={(event) => this.setValues(event)}
            />
            {!!error.number && error.number.length > 0 && (
              <div className="invalid-feedback">{error.number}</div>
            )} 
          </div>

          <div className="form-group">
            <label
              className={"delivery-detail-input-label"}
              htmlFor="EmailAddress"
            >
              Email Address
            </label>
            <input
              name={"email"}
              type="email"
              className={`delivery-detail-input form-control ${
                !!error.email && error.email.length > 0 ? "is-invalid" : ""
              }`}
              id="EmailAddress"
              aria-describedby="EmailAddress"
              placeholder={"Ex: Miriam-T-Roy@gmail.com"}
              value={email}
              onChange={(event) => this.setValues(event)}
            />
            {!!error.email && error.email.length > 0 && (
              <div className="invalid-feedback">{error.email}</div>
            )}
          </div> */}
        </>

        {/* shipping address */}
        <div className="form-group">
          <label
            className={"delivery-detail-input-label"}
            htmlFor="ShippingAddress"
          >
            {t("Shipping Address")}
          </label>
          <input
            name={"address"}
            type="text"
            className={`delivery-detail-input form-control`}
            id="ShippingAddress"
            aria-describedby="ShippingAddress"
            placeholder={"Ex: R Pescador Bacalhoeiro"}
            value={address}
            onChange={(event) => {
              setaddress(event.target.value);
              seterror({ ...error, address: "" });
            }}
          />
          {/* {!!error.address && error.address.length > 0 && (
              <div className="invalid-feedback">{error.address}</div>
            )} */}
        </div>

        {/* billing address */}
        <div className="form-group">
          <label
            className={"delivery-detail-input-label"}
            htmlFor="BillingAddress"
          >
            {t("Billing Address")}
          </label>
          <input
            name={"address"}
            type="text"
            className={`delivery-detail-input form-control`}
            id="BillingAddress"
            aria-describedby="BillingAddress"
            placeholder={"Ex: R Pescador Bacalhoeiro"}
            value={billingAddress}
            onChange={(e) => setbillingAddress(e.target.value)}
          />
          {/* {!!error.address && error.address.length > 0 && (
              <div className="invalid-feedback">{error.address}</div>
            )} */}
        </div>

        {/* form/api errors */}
        {formError && (
          <p style={{ color: "red", fontSize: "13px" }}>*{t(`${formError}`)}</p>
        )}

        {/* confirm Button */}
        <div className={"delivery-detail-confirm-btn-wrap"}>
          <div
            // onClick={this.validator}
            onClick={confirmOrder}
            className={"delivery-detail-confirm-btn"}
          >
            {t("Confirm and Proceed Order")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentDeliveryCard);
