import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

export default function Graphs() {
  const [data, setData] = useState([
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
  ]);
  const [data2, setData2] = useState([
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [t] = useTranslation();
  const handleClick = (data, index) => {
    setActiveIndex(index);
  };

  return (
      <div>
    <Row style={{ height: "0%", marginTop: "2.5%",display:"flex",flexDirection:'row',padding:'0px 25px' }}>
      <Col  className="mb-6 mb-xl-0" xl="6"  >
        <Card className="bg-gradient-default shadow">
          <CardHeader className="bg-transparent">
            <h6
              style={{ color: "#8884d8", fontWeight: "bold" }}
              className=" ls-1 mb-1"
            >
              {t("Analytics")}
            </h6>
          </CardHeader>
          <CardBody>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="pv"
                  fill="#8884d8"
                  background={{ fill: "#eee" }}
                />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Col>
      <Col xl="6"   >
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <h6
              style={{ color: "#8884d8", fontWeight: "bold" }}
              className=" ls-1 mb-1"
            >
              {t("Revenue Sources")}
            </h6>
          </CardHeader>
          <CardBody>
            <ResponsiveContainer width={"100%"} height={300}>
              <AreaChart
                data={data2}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row style={{ height: "0%", marginTop: "2.5%",display:"flex",flexDirection:'row',padding:'0px 25px' }}>
      <Col className="mb-6 mb-xl-0" xl="6">
        <Card className="bg-gradient-default shadow">
          <CardHeader className="bg-transparent">
            <h6
              style={{ color: "#8884d8", fontWeight: "bold" }}
              className=" ls-1 mb-1"
            >
              {t("Analytics")}
            </h6>
          </CardHeader>
          <CardBody>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="pv"
                  fill="#8884d8"
                  background={{ fill: "#eee" }}
                />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Col>
      <Col xl="6">
        <Card className="shadow">
          <CardHeader className="bg-transparent">
            <h6
              style={{ color: "#8884d8", fontWeight: "bold" }}
              className=" ls-1 mb-1"
            >
              {t("Revenue Sources")}
            </h6>
          </CardHeader>
          <CardBody>
            <ResponsiveContainer width={"100%"} height={300}>
              <AreaChart
                data={data2}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Col>
    </Row>
      </div>

  );
}
