import React from "react";
import { connect } from "react-redux";
import {
  addFilter,
  removeFilter,
} from "../../../../../redux/Filters/filters.actions";
import "../FilterLeftSection.css";

function FilterSearchBox({ props }) {
  const [name, setName] = React.useState("");
  const [count, setCount] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(true);
  const [prevProps, setPrevProps] = React.useState(props);
  const [id, setId] = React.useState(123);

  React.useEffect(() => {
    const { name, count, isChecked } = props;

    setName(name);
    setCount(count);
    setIsChecked(isChecked);
  }, []);

  React.useEffect(() => {
    const { isChecked } = props;
    if (isChecked !== prevProps.isChecked) {
      setIsChecked(isChecked);
    }

    // if(
    //   (JSON.stringify(filters) !== JSON.stringify(prevProps.filters)) &&
    //   filters.map(x=>x.value).includes(name)
    // ){
    //   this.setState({isChecked:!this.state.isChecked})
    // }
    setPrevProps(props);
  }, [props.isChecked]);

  const markCheckBox = (event) => {
    try {
      props.updateCheckedBoxes(name);
      if (isChecked) {
        props.dispatch(removeFilter({ label: name, value: name }));
      } else {
        props.dispatch(addFilter({ label: name, value: name }));
      }
      setIsChecked(isChecked);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <li className={"filter-search-list-li"}>
      <div className={"filter-search-list-item"}>
        <div className="custom-control custom-checkbox flex-grow-1">
          <input
            onChange={(e) => markCheckBox(e)}
            checked={isChecked}
            type="checkbox"
            className="custom-control-input"
            id={name + id}
          />
          <label
            style={{ paddingTop: "0.4em" }}
            className="custom-control-label"
            htmlFor={name + id}
          >
            {name}
          </label>
        </div>
        <div className={"filter-search-list-li-count"}>{count}</div>
      </div>
    </li>
  );

  //handler
  // mark check to check box
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters.filters,
  };
};

export default connect(mapStateToProps)(FilterSearchBox);
