import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import "./ProductCard.css";

const ProductCard = (props) => {
  const [isHover, setisHover] = useState(false);

  const history = useHistory();
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isHover: false,
  //   };
  // }

  //handlers
  const onHoverEffect = () => {
    setisHover(!isHover);
    // this.setState({ isHover: !this.state.isHover });
  };

  // on click product card
  const handleClick = () => {
    const { id, cat } = props;
    console.log(cat, "kkkkkkkklllllllmmmm");
    try {
      history.push({
        pathname: `/products/${id}`,
        state: id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  //helpers calculate discount from original price and return discounted price
  const numberWithCommas = (price, discount) => {
    const totalValue = Math.abs(
      parseFloat(price) - parseFloat(price) * parseFloat(discount)
    );
    return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const { img, originalPrice, discount, label, Name, Price, Images } = props;
  const onHoverEffectStyle = {
    borderRadius: `${isHover ? "8px" : "0px"}`,
    boxShadow: `${isHover ? "0 12px 75px 0 rgba(0, 0, 0, 0.12)" : "0px 0px"}`,
    border: `${isHover ? "solid 1px #f0f0f0" : "solid 1px #ffffff00"}`,
  };

  return (
    <div
      onClick={handleClick}
      onMouseLeave={onHoverEffect}
      onMouseEnter={onHoverEffect}
      style={onHoverEffectStyle}
      className={"row product-card-wrap"}
    >
      {/* Image */}
      {/* {!!img && img.length > 0 ? ( */}
      {Images[0] ? (
        <div
          style={{ backgroundImage: `url(${Images[0]})` }}
          className={"product-card-img"}
        ></div>
      ) : (
        <div className={"product-card-img"}>
          <i className="fa fa-image"></i>
        </div>
      )}

      <p className={"product-card-img-title"}>{Name}</p>
      <div className={"product-card-price-wrap"}>
        <div className={"product-card-price"}>
          {/* {numberWithCommas(originalPrice, discount).concat("AZK")}{" "} */}
          {new Intl.NumberFormat().format(Price)} AKZ
        </div>
        {/* {discount !== 0 && (
            <div className={"product-card-discount"}>-{discount}%</div>
          )} */}
      </div>
      {/* <div className={"product-card-original-price"}>
          {discount !== 0 ? originalPrice.toString().concat("AZK") : ""}{" "}
        </div> */}
    </div>
  );
};

export default withRouter(ProductCard);
