import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagesLoader }     from '../../../helpers';
import './BaixeAppPage.scss';

export default function BaixeAppPage() {
  const [t] = useTranslation();
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div className={ "baixe-app-wrapper" }>
      <div className={ "baixe-app-title" }>
        {t("Baixe o aplicativo BayQi para o seu smartphone")}
      </div>
      <div className={ "baixe-app-image-body" }>
        <div className={ "image-wrapper" }>
          <div style={ { backgroundImage: `url(${ ImagesLoader('sec-two-phones') })` } } className={ "img" }></div>
        </div>
        <div className={ "store-btns-wrapper" }>
          <a href="https://apps.apple.com/pk/app/bayqi/id1669111904" target="_blank" rel="noopener noreferrer">
            <div className={ "store-btns" } style={ {
              backgroundImage: `url(${ ImagesLoader('on-apple-app') })`
            } }>
            </div>
          </a>
          <a href={ "https://play.google.com/store/apps/details?id=com.stackone.bayqi" } target={ "_blank" }>
            <div className={ "store-btns" } style={ {
              backgroundImage: `url(${ ImagesLoader('on-android-app') })`
            } }>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}