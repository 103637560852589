import { useTranslation } from "react-i18next";
import { ImagesLoader } from "../helpers";
import { Link, withRouter, useHistory } from "react-router-dom";

const DownloadApp = () => {
  const [t] = useTranslation();
  return (
    // <a href={ "https://apps.apple.com/pk/app/bayqi/id1669111904" } target={ "_blank" } className={ "btn btn-dark d-flex" }
    //    style={ { width: "fit-content" } }>
    //   {t("Download BayQi App")}&nbsp;&nbsp;&nbsp;
    //   <div className={ "m-auto d-flex flex-row align-items-center" }>
    //   <a style={{color:"white"}} href="https://apps.apple.com/pk/app/bayqi/id1669111904" target="_blank" rel="noopener noreferrer">
    //     <i className="mr-3 fa fa-apple"></i>
    //     </a>
    //     <div style={ {
    //       backgroundImage: `url(${ ImagesLoader('play-store-icon') })`,
    //       width: "1rem",
    //       height: "1rem",
    //       backgroundPosition: "center",
    //       backgroundSize: "contain",
    //       backgroundRepeat: "no-repeat"
    //     } }></div>
    //   </div>
    // </a>
    <div className={"d-flex flex-row flex-wrap w-100"}>
      <a
        href="https://apps.apple.com/app/bayqi/id1669111904"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <Link to="apps.apple.com/pk/app/bayqi/id1669111904"> */}
        <div
          style={{
            backgroundImage: `url(${ImagesLoader("on-apple-app")})`,
            height: "3em",
            width: "9em",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            margin: "0 1.5em 0.2em 0",
            cursor: "pointer",
          }}
        ></div>
        {/* </Link> */}
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.stackone.bayqi"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          style={{
            backgroundImage: `url(${ImagesLoader("on-android-app")})`,
            height: "3em",
            width: "9em",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
          }}
        ></div>
      </a>
    </div>
  );
};
export default DownloadApp;
