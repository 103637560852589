import React, { useState, useRef } from "react";
import "./PayByCatalog.css";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import {
  ButtonToggle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
} from "reactstrap";
import FileUploadLogo from "../../../../assets/images/uploadfile.png";
import { useTranslation } from "react-i18next";
export default function PayByCatalog() {
  const [t] = useTranslation();
  //ADD Category
  const fileInputRef = useRef(null);
  const [postImage, setPostImage] = useState(null);
  const [postTitle, setPostTitle] = useState(null);
  const [postDesc, setPostDesc] = useState(null);
  const [selectedPostCategory, setSelectedPostCategory] = useState(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    setPostImage(URL.createObjectURL(files[0]));
    console.log("FILES", URL.createObjectURL(files[0]));
  };
  const pickFile = () => {
    fileInputRef.current.click();
  };
  //edit
  const [tableData, setTableData] = useState([0, 1, 2, 3, 4, 5]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [isOpenAddModal, setIsOpenAddModal] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const toggle = () => {
    setModal(!modal);
    setDisabled(true);
  };
  const openAddModal = () => setIsOpenAddModal(!isOpenAddModal);
  const AddForm = () => (
    <div style={{ padding: "0px 30px" }}>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Col lg={6} xl={6} md={6}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Select Post Image")}
          </label>
          {/* <ButtonToggle type="file" color={"danger"} onClick={pickFile}>
            <span style={{ color: "#fff" }}>picker</span>
          </ButtonToggle> */}
          <div
            onClick={pickFile}
            style={{
              width: "100px",
              height: "100px",
              // background: "red",
              borderRadius: "8px",
              display: "flex",
              border: "1px solid #CED4DA",

              background: "#f7f7f7",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              <img
                src={postImage ? postImage : FileUploadLogo}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
          />
          {/* <FileDrop ref={fileInputRef} onTargetClick={onTargetClick} /> */}
        </Col>
        <Col lg={6} xl={6} md={6}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Delivery")}
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              // color={'light'}
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {t("Select Delivery")}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px" }}>
              <DropdownItem>{t("Free Delivery")}</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>{t("Own Delivery")}</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>{t("Collect on Site")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Name")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Price")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Quantity")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Delivery time (Working days)")}
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              // color={'light'}
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {t("Select Delivery Time")}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px" }}>
              <DropdownItem>1</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>3</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <ButtonToggle
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "#fff" }}>{t("Publish")}</span>

        {/* <AiOutlinePlus
                // style={{ left: "20px" }}
                size={"1.2rem"}
                color={"#fff"}
              /> */}
      </ButtonToggle>
    </div>
  );
  const EditForm = () => (
    <div style={{ padding: "0px 30px" }}>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Col lg={6} xl={6} md={6}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Select Post Image")}
          </label>
          {/* <ButtonToggle type="file" color={"danger"} onClick={pickFile}>
            <span style={{ color: "#fff" }}>picker</span>
          </ButtonToggle> */}
          <div
            onClick={pickFile}
            style={{
              width: "100px",
              height: "100px",
              // background: "red",
              borderRadius: "8px",
              display: "flex",
              border: "1px solid #CED4DA",

              background: "#f7f7f7",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              <img
                src={postImage ? postImage : FileUploadLogo}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <input
            disabled={disabled}
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
          />
          {/* <FileDrop ref={fileInputRef} onTargetClick={onTargetClick} /> */}
        </Col>
        <Col lg={6} xl={6} md={6}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Delivery")}
          </label>
          <UncontrolledDropdown disabled={disabled} style={{ width: "100%" }}>
            <DropdownToggle
              // color={'light'}
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {t("Select Delivery")}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px" }}>
              <DropdownItem>{t("Free Delivery")}</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>{t("Own Delivery")}</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>{t("Collect on Site")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Name")}
          </label>
          <input
            disabled={disabled}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Price")}
          </label>
          <input
            disabled={disabled}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          //   alignSelf: "center",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Product Quantity")}
          </label>
          <input
            disabled={disabled}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
            }}
            id="Title"
            //placeholder='Last Name'
            type="dropdown"
            // value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={6}></Col>
        <Col xl={3}></Col>
        <Col xl={3}>
          <ButtonToggle
            onClick={() => setDisabled(!disabled)}
            style={{
              margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "#fff" }}>
              {disabled ? t("Edit") : t("Update")}
            </span>

            {/* <AiOutlinePlus
                // style={{ left: "20px" }}
                size={"1.2rem"}
                color={"#fff"}
              /> */}
          </ButtonToggle>
        </Col>
      </Row>
    </div>
  );
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "40px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={6}
            lg={6}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Pay By Catalog")}
              </span>
            </div>
          </Col>
          <Col
            xl={3}
            lg={3}
            style={{
              //  backgroundColor: "yellow",
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder={t("Search")}
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col
            xl={3}
            lg={3}
            style={{
              //  backgroundColor: "yellow",
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",

                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {t("Show All")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          {/* <Col xl={2} lg={2}>
            <ButtonToggle
              onClick={openAddModal}
              style={{
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px",
              }}
            >
              <span>Add</span>
            </ButtonToggle>
          </Col> */}
        </Row>
      </div>

      {/* <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          // padding: "12px 0px",
          marginTop: "50px",
          padding: "12px 5px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            xl={7}
            lg={7}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Generate Link
              </span>
            </div>
          </Col>
          <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>
          <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>
          <Col xl={1} lg={1}>
            <ButtonToggle
              onClick={openAddModal}
              style={{
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px",
              }}
            >
              <span>Add</span>
            </ButtonToggle>
          </Col>
        </Row>
      </div> */}
      {/* TABLE VIEW */}
      {/* <MDBDataTable
      // striped
      // bordered
      small
      bord
      data={data}
    /> */}
      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ alignSelf: "center", padding: "20px" }}>{t("Order ID")}</th>
            <th style={{ alignSelf: "center", padding: "20px" }}>{t("Date")}</th>
            <th style={{ alignSelf: "center", padding: "20px" }}>{t("Client")}</th>
            <th style={{ alignSelf: "center", padding: "20px" }}>
              {t("Descriptive")}
            </th>
            <th style={{ alignSelf: "center", padding: "20px" }}>{t("Amount")}</th>
            <th style={{ alignSelf: "center", padding: "20px" }}>{t("State")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr
              //   onClick={toggle}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ alignSelf: "center", padding: "20px" }}>3682</td>
              <td style={{ alignSelf: "center", padding: "20px" }}>
                2021-02-26 21:26:20
              </td>
              <td style={{ alignSelf: "center", padding: "20px" }}>
                Florebela beats
              </td>
              <td style={{ alignSelf: "center", padding: "20px" }}>
                Pay by Catalog
              </td>
              <td style={{ alignSelf: "center", padding: "20px" }}>
                53.00 AKZ
              </td>
              <td style={{ alignSelf: "center", padding: "20px" }}>Pending</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <div>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination>
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <Pagination.Item
                    onClick={() => setActivePage(item)}
                    active={activePage === item}
                  >
                    {item}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                  // color={'light'}
                  style={{
                    backgroundColor: "#fff",
                    color: "#333",
                    borderColor: "#CED4DA",
                    fontSize: "0.9rem",
                    width: "100%",
                  }}
                  caret
                >
                  <span
                    style={{
                      color: "#333",
                      fontSize: "0.7rem",
                    }}
                  >
                    {t("Items per page")}
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    1
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: "5px" }}>
                  <DropdownItem>1</DropdownItem>
                  <DropdownItem>2</DropdownItem>
                  <DropdownItem>3</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </div>
      </Row>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Update Category")}</ModalHeader>
        <ModalBody>
          <EditForm />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      <Modal centered isOpen={isOpenAddModal} toggle={openAddModal}>
        <ModalHeader toggle={openAddModal}>{t("Add Category")}</ModalHeader>
        <ModalBody>
          <AddForm />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
