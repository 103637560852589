import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "../FilterLeftSection.css";
import { RangeSliderHook } from "./RangeSlider";

function FilterPriceRange({ props }) {
  const [values, setValues] = React.useState({ min: 265, max: 1569 });
  const [t] = useTranslation();

  const setRangeValues = (values) => {
    setValues(values);
  };

  const { min, max } = values;
  return (
    <div className={"filter-price-range-wrap"}>
      <div className={"filter-price-range-title"}>{t("Price")}</div>
      <div className={"filter-price-range-bar"}>
        <div className="range-slider-container">
          {/*<RangeSlider*/}
          {/*  setRangeValues={ this.setRangeValues }*/}
          {/*/>*/}
          <RangeSliderHook setRangeValues={setRangeValues} />
        </div>
      </div>
      <div className={"filter-price-range-end-points"}>
        <div className={"col col-sm-4 col-md-4 col-lg-4 p-0"}>
          <div className={"filter-price-range-left"}>AKZ {min}</div>
        </div>
        <div className={"col col-sm-4 col-md-4 col-lg-4 p-0"}>
          <div className={"filter-price-range-center"}>{t("To")}</div>
        </div>
        <div className={"col col-sm-4 col-md-4 col-lg-4 p-0"}>
          <div className={"filter-price-range-right"}>AKZ {max}</div>
        </div>
      </div>
    </div>
  );

}

export default withRouter(FilterPriceRange);
