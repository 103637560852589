import React from "react";
import { useTranslation } from "react-i18next";
import { ImagesLoader } from "../../../helpers";
import SectionTwo from "../LandingPage/Section2/SectionTwo";
import { MissingThingsToggles } from "../LinkedPagesTemplate";
import "./QRCodePage.scss";

function QRCodePage({ props }) {
  const [t] = useTranslation();
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={"w-100"}>
      {/*<LeftTextRightImage*/}
      {/*  title={ "Pague em lojas com seu Telemovél" }*/}
      {/*  description={ "Deixe o dinheiro em casa e escaneie o código QR!" }*/}
      {/*  img={ `${ ImagesLoader('link-pg-qr') }` }*/}
      {/*/>*/}
      <SectionTwo
        hideTitleOne={false}
        titleOne={""}
        titleTwo={t("Pague em lojas com seu Telemovél")}
        titleThree={t("Deixe o dinheiro em casa e escaneie o código QR!")}
        img={`${ImagesLoader("link-pg-qr")}`}
        backgroundStyle={{
          backgroundImage: "inherit",
          color: "inherit",
        }}
      />
      <MissingThingsToggles />
    </div>
  );
}

export default QRCodePage;
