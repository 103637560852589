import React, { useState, useEffect, useRef } from "react";
import "./Slider.css";

const ProductsSlider = ({ children }) => {
  const myRef = useRef(null);
  const [state, setState] = useState({
    prevDisable: true,
    nextDisable: false,
    byPassNull: false,
  });

  const checkButtons = (offsetWidthValue, scrollWidthValue) => {
    setState({
      ...state,
      prevDisable: myRef.current.scrollLeft <= 0,
      nextDisable:
        myRef.current.scrollLeft + offsetWidthValue >= scrollWidthValue,
    });
  };

  useEffect(() => {
    const offsetWidthValue = myRef.current.offsetWidth;
    const scrollWidthValue = myRef.current.scrollWidth;

    
    checkButtons(offsetWidthValue, scrollWidthValue);

    
    if (!state.byPassNull) {
      setState({ ...state, byPassNull: true });
    }
  }, [myRef.current, state.byPassNull]); 

  const { prevDisable, nextDisable } = state;

  const handlePrevClick = () => {
    const offsetWidthValue = myRef.current.offsetWidth;
    myRef.current.scrollLeft -= offsetWidthValue / 2;
    checkButtons(offsetWidthValue, myRef.current.scrollWidth);
  };

  const handleNextClick = () => {
    const offsetWidthValue = myRef.current.offsetWidth;
    myRef.current.scrollLeft += offsetWidthValue / 2;
    checkButtons(offsetWidthValue, myRef.current.scrollWidth);
  };

  const disablePrevBtn = {
    display: `${prevDisable ? "none" : "block"}`,
  };

  const disableNextBtn = {
    display: `${nextDisable ? "none" : "block"}`,
  };

  return (
    <div className="slider-container" ref={myRef}>
      <div className="slider-wrapper">{children}</div>
      <div
        style={disablePrevBtn}
        className="btn-prev"
        onClick={handlePrevClick}
      >
        <i style={{ fontSize: "1.5em", color: "black" }} className="fa fa-angle-left"></i>
      </div>
      <div
        style={disableNextBtn}
        className="btn-next"
        onClick={handleNextClick}
      >
        <i style={{ fontSize: "1.5em", color: "black" }} className="fa fa-angle-right"></i>
      </div>
    </div>
  );
};

export default ProductsSlider;
