import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  removeFilter,
  resetFilters,
} from "../../../../../redux/Filters/filters.actions";
import "./FilteredTags.scss";

function FilteredTags({ props }) {
  const [t] = useTranslation();
  const [tags, setTags] = React.useState(props.tags);
  const [prevProps, setPrevProps] = React.useState(props);

  React.useEffect(
    (prevProps, prevState, snapshot) => {
      if (JSON.stringify(tags) !== JSON.stringify(prevProps.tags)) {
        setTags(tags);
      }
      setPrevProps(props);
    },
    [props.tags]
  );

  const clearTags = () => {
    setTags([]);
    props.dispatch(resetFilters());
  };

  //helpers
  const getFilteredTags = () => {
    try {
      return tags.map((tag, idx) => {
        return (
          <div key={idx} className={"result-right-head-tag"}>
            <div className={"head-tag-label"}>{tag}</div>
            <div onClick={() => removeTag(tag)} className={"head-tag-icon"}>
              <i
                style={{ fontSize: "0.8em !important", color: "#cccccc" }}
                className={`accordion-item__icon fa fa-times`}
              ></i>
            </div>
          </div>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeTag = (tagsName) => {
    try {
      props.dispatch(removeFilter({ label: tagsName, value: tagsName }));
      setTags(tags.filter((item) => item !== tagsName));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"result-right-head-tags-wrap"}>
      <div className={"result-right-head-tags"}>
        {!!tags && tags.length > 0 && getFilteredTags()}
      </div>
      {!!tags && tags.length > 0 && (
        <div onClick={clearTags} className={"result-right-head-tags-clear-all"}>
          {t("Clear All")}
        </div>
      )}
    </div>
  );
}

export default connect(null)(FilteredTags);
