import React, { useEffect } from 'react';
import { cookiesPolicies }  from '../../../DummyData';
import { ImagesLoader }     from '../../../helpers';
import Accordion            from '../FAQsPage/Accordion/Accordion';
import './CookiesPolicies.scss';

function CookiesPolicies() {

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <div className={ "cp-wrap container" }>
      <div style={ { backgroundImage: `url(${ ImagesLoader('cookies-cover') })` } } className={ "cp-image" }></div>
      <div className={ "cp-toggles-wrap" }>
        <Accordion tabData={ cookiesPolicies }/>
      </div>
    </div>
  )
}

export default CookiesPolicies;