import categoriesTypes                                   from './categories.types';
import { handleAddToCategory, handleRemoveCategoryItem } from './categories.utils'

const INITIAL_STATE = {
  categories: []
};
const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case categoriesTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case categoriesTypes.ADD_TO_CATEGORY:
      return {
        ...state,
        categories: handleAddToCategory({
          prevCartItems: state.cartItems,
          nextCartItem: action.payload
        })
      };
    case categoriesTypes.REMOVE_CATEGORY_ITEM:
      return {
        ...state,
        categories: handleRemoveCategoryItem({
          prevCartItems: state.cartItems,
          cartItemToRemove: action.payload
        })
      };
    default:
      return state;
  }
};

export default categoriesReducer;