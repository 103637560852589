import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { termsConditions } from "../../../DummyData";
import Accordion from "../FAQsPage/Accordion/Accordion";
import "./TermsConditions.scss";

export default function TermsConditions(props) {
  const [t] = useTranslation();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="privacy-div">
      <h1 style={{ textAlign: "center", fontSize: " 40px", marginTop: "20px" }}>
        TERMOS E CONDIÇÕES{" "}
      </h1>
      <h1
        style={{
          textAlign: "left",
          fontSize: "22px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Termos e Condições – BayQi
      </h1>
      <h1 style={{ textAlign: "left", fontSize: "22px", fontWeight: "bold" }}>
        PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA
      </h1>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        1. Aplicação dos Termos e Condições Gerais
      </h3>
      <p>
        1.1. Estes termos e condições gerais ("Termos e Condições Gerais")
        constituem uma parte integrante do Contrato de Adesão BayQi é uma
        inovadora aplicação, desenvolvida pela empresa PagaQi – Sociedade
        Prestadora De Serviços De Pagamento (Su), Lda. (adiante designada
        "PagaQi")
      </p>
      <p>
        1.2. Os Termos e Condições Gerais são aplicáveis na íntegra, a menos que
        a PagaQi e o Cliente concordem em contrário por escrito.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>2. Definições</h3>
      <p>
        Para efeitos do presente Contrato, os seguintes termos são definidos
        como se segue:
      </p>
      <ul>
        <li>
          <strong>"Amigos BayQi"</strong> significa os contactos existentes na
          rede de pagamentos BayQi, para os quais poderá enviar ou solicitar
          dinheiro, sujeito aos Termos e Condições Gerais;
        </li>
        <li>
          <strong>"Aplicação BayQi"</strong> significa a aplicação
          disponibilizada pela BayQi através da Apple Store ou Google Play, a
          qual deve ser instalada no dispositivo móvel utilizado pelo Cliente
          para efeitos de subscrição e utilização;
        </li>
        <li>
          <strong>"Autenticação"</strong> significa os procedimentos definidos
          pela BayQi para verificar a identidade do Cliente ou a validade da
          utilização de um Método de Pagamento específico. Estes procedimentos
          incluem a utilização de Dados de Segurança Personalizados e Dados de
          Identificação.
        </li>
        <li>
          <strong>"Autenticação Forte de Cliente"</strong> significa os
          procedimentos de Autenticação definidos pela BayQi, incluindo a
          utilização de dois (2) ou mais elementos independentes categorizados
          como conhecimento, posse e inerência, permitindo estabelecer uma
          ligação dinâmica entre a Transacção, o montante e o Beneficiário.
        </li>
        <li>
          <strong>"PagaQi"</strong> significa PAGAQI – SOCIEDADE PRESTADORA DE
          SERVIÇOS DE PAGAMENTO (SU), LDA., com sede em Luanda, Rua António
          Feliciano Castilho – Bairro Vila Alice, Nº 218/220, titular do Cartão
          de Contribuinte Fiscal Nº 5001025368.
        </li>
        <li>
          <strong>"BayQi Wallet"</strong> significa a BayQi Wallet operada pela
          PagaQi em nome do Cliente, que consiste numa carteira de moeda
          electrónica pré-paga, utilizada para efeitos de implementação de
          operações de pagamento. Em nenhum caso a BayQi Wallet será tratada
          como uma conta de depósito. A BayQi Wallet é denominada em Kwanzas.
        </li>
        <li>
          <strong>"Beneficiário"</strong> significa uma pessoa singular ou
          jurídica residente cambial no Território que é credor de uma Operação
          de Pagamento emitida pelo Cliente.
        </li>
        <li>
          <strong>"BNA"</strong> significa Banco Nacional de Angola.
        </li>
        <li>
          <strong>"Cliente"</strong> significa uma pessoa singular residente
          cambial no Território ou não residente cambial agindo em seu próprio
          nome e em cujo nome é aberta uma BayQi Wallet para utilizar o Serviço.
        </li>
        <li>
          <strong>"Comerciante"</strong> (aceitante) significa a entidade em
          nome individual ou empresa que pretende providenciar aos seus clientes
          serviços ou produtos, cujo pagamento será autorizado através da
          Aplicação BayQi.
        </li>
        <li>
          <strong>"Contrato"</strong> significa os Termos e Condições Gerais,
          juntamente com o Formulário de Subscrição, o Preçário e qualquer
          confirmação de encomenda fornecida à BayQi pelo Cliente, que
          conjuntamente constituem todo o Contrato relativo ao Serviço.
        </li>
        <li>
          <strong>"Dados de Identificação"</strong> significa o Identificador
          Único do Cliente e a senha que lhe permite aceder ao seu Espaço
          Pessoal.
        </li>
        <li>
          <strong>"Dados de Segurança Personalizados"</strong> são os dados
          personalizados fornecidos pela BayQi ao Cliente para fins de
          Autenticação. Incluem os Dados de Identificação e possivelmente
          quaisquer outros dados relacionados com os procedimentos de
          Autenticação ou de Autenticação Forte do Cliente.
        </li>
        <li>
          <strong>"Dados Pessoais"</strong> significa qualquer informação
          relativa a um Titular de Dados que seja recolhida em conexão com a
          prestação do Serviço. Os Dados Pessoais podem incluir, mas não se
          limitam, dados recolhidos com a finalidade de executar operações de
          pagamento.
        </li>
        <li>
          <strong>"Dia Útil"</strong> significa um dia diferente de um sábado,
          domingo ou feriado em Angola, quando os bancos estão abertos em
          horário de expediente.
        </li>
        <li>
          <strong>"Espaço Pessoal"</strong> significa o ambiente dedicado do
          Cliente, acessível no site da BayQi, que lhe permite aceder à sua
          BayQi Wallet e utilizar o Serviço.
        </li>
        <li>
          <strong>"Formulário de Subscrição"</strong> significa o formulário a
          ser preenchido por qualquer potencial cliente que deseje subscrever o
          Serviço, acessível no site da BayQi no momento do registo.
        </li>
        <li>
          <strong>"Identificador Único"</strong> significa uma combinação de
          letras, números e símbolos que identifica claramente outro utilizador
          de serviço de pagamento ou a respectiva BayQi Wallet.
        </li>
        <li>
          <strong>"Lei Aplicável"</strong>inclui todos e quaisquer estatutos,
          regulamentos, portarias, estatutos, directivas, códigos de prática,
          circulares, notas de orientação, sentenças e decisões de qualquer
          autoridade competente, ou qualquer órgão governamental,
          intergovernamental ou supranacional, agência, departamento ou
          regulador, autorregulamentação ou outra autoridade ou organização, e
          qualquer substituição ou alteração dos mesmos e outras disposições
          similares que imponham obrigações legalmente aplicáveis a cada Parte.
        </li>
        <li>
          <strong>"Lei do Sistema de Pagamentos"</strong>significa a Lei n.º
          40/20, de 16 de Dezembro, prorrogada, aplicada ou alterada de tempos a
          tempos e inclui qualquer legislação subordinada
        </li>
        <li>
          <strong>"Meio Duradouro"</strong>significa qualquer instrumento que
          permita ao Cliente armazenar informações dirigidas pessoalmente ao
          Cliente, a fim de poder consultá-las posteriormente durante um período
          de tempo adequado à finalidade a que as informações se destinam e que
          permita a reprodução idêntica das informações armazenadas. Tal
          consiste, geralmente, num ficheiro PDF.
        </li>
        <li>
          <strong>"Método de Pagamento"</strong>significa qualquer método de
          pagamento autorizado sempre aceitável para efeitos de crédito ou
          débito da BayQi Wallet
        </li>
        <li>
          <strong>"Operação de Pagamento"</strong>significa uma transferência
          periódica ou única, ordenada pelo Cliente, que debita a BayQi Wallet.
        </li>
        <li>
          <strong>"Ordem de Pagamento"</strong>significa a instrução dada pelo
          Cliente à BayQi, de acordo com o procedimento estabelecido nestes
          Termos e Condições Gerais para a realização de uma Operação de
          Pagamento através da Aplicação BayQi
        </li>
        <li>
          <strong>"Página de Pagamento"</strong>significa a página assegurada
          pelo fornecedor de serviços de pagamento electrónico da BayQi.
        </li>
        <li>
          <strong>"Parte"</strong> significa cada uma das partes deste Contrato,
          designadamente a BayQi e o Cliente.
        </li>
        <li>
          <strong>"Preçário"</strong> significa o documento que descreve os
          termos e condições financeiras acordados entre a BayQi e o Cliente
          para a prestação do Serviço, incluindo as taxas previstas no Contrato,
          o qual pode ser revisto a todo o tempo pela BayQi, conforme as
          disposições destes Termos e Condições Gerais.
        </li>
        <li>
          <strong>"Serviço"</strong> significa os serviços de pagamento
          definidos na cláusula 3.
        </li>
        <li>
          <strong>"Titular dos Dados"</strong> significa qualquer pessoa
          singular cujos Dados Pessoais são processados no contexto da
          celebração do presente Contrato.
        </li>
        <li>
          <strong>"Violação de Dados Pessoais"</strong> significa uma quebra de
          segurança que leva à destruição acidental ou ilícita, perda,
          alteração, divulgação não autorizada ou acesso a Dados Pessoais
          transferidos, armazenados ou processados de outra forma.
        </li>
        <li>
          <strong>"Território"</strong> significa Angola.
        </li>
        <li>
          <strong>"Terceiro Prestador de Serviços de Pagamento"</strong>{" "}
          significa qualquer instituição, que não a BayQi, autorizada a prestar
          serviços de pagamento.
        </li>
      </ul>
      <p>
        2.2. As referências feitas a cláusulas respeitam às cláusulas dos Termos
        e Condições Gerais.
      </p>
      <p>
        2.3. Os títulos das cláusulas e dos anexos não afetam a interpretação
        dos Termos e Condições Gerais.
      </p>
      <p>
        2.4. Salvo quando do contexto resulte de outro modo, as palavras no
        singular devem incluir o plural e no plural devem incluir o singular.
      </p>
      <p>
        2.5. Quaisquer palavras a que se sigam os termos incluindo, em
        particular, por exemplo, ou qualquer expressão similar devem ser
        interpretadas como ilustrativas e não devem limitar o sentido das
        palavras, descrição, definição, frase ou termo anterior a esses termos.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        3. Objectivo e Âmbito
      </h3>
      <p>
        3.1. O objectivo das Condições Gerais é definir os termos e condições
        sob os quais a BayQi fornece o Serviço ao Cliente em contrapartida de
        uma remuneração, tal como definido na Cláusula k) dos Termos e
        Condições.
      </p>
      <p>3.2. O Serviço inclui:</p>
      <ul>
        <li>a) a abertura e gestão da BayQi Wallet;</li>
        <li>
          b) o crédito da BayQi Wallet, para o recebimento de transferências
          através de qualquer Método de Pagamento;
        </li>
        <li>
          c) débito da BayQi Wallet, permitindo a transferência de fundos para o
          Beneficiário, a cobrança das taxas devidas nos termos deste Contrato e
          a inversão de transferências de fundos.
        </li>
      </ul>
      <p>
        3.3. A BayQi Wallet não estará sujeita a qualquer descoberto,
        adiantamento, crédito ou desconto.
      </p>
      <p>
        3.4. Todos os pagamentos e transferências feitos ao abrigo destes Termos
        e Condições Gerais devem ser feitos em Kwanzas.
      </p>
      <p>
        3.5. A utilização do Aplicativo BayQi pressupõe e exige acesso à
        internet no dispositivo móvel onde este estiver instalado.
      </p>
      <h2>4. Subscrição do Serviço</h2>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        4.1. Inscrição na Aplicação BayQi
      </h3>
      <p>
        (i) Para utilizar o Serviço, o potencial cliente deve criar uma conta,
        registar-se e manter uma conta de Cliente ("Conta Verificada") na
        Aplicação BayQi como cliente ("Cliente da Aplicação BayQi ").
      </p>
      <p>
        (ii) A elegibilidade está sujeita ao cumprimento dos requisitos de
        verificação de identidade e compliance (as "Verificações de Compliance")
        a realizar pela instituição financeira em que esteja sediada a conta
        bancária de depósito do Cliente.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        4.2. Condições e Pré-requisitos para Subscrever o Serviço
      </h3>
      <p>
        (i) Qualquer pessoa singular, residente cambial ou não residente
        cambial, no Território, pode subscrever o Serviço mediante inscrição na
        Aplicação BayQi.
      </p>
      <p>
        (ii) A inscrição na Aplicação BayQi apenas pode ser realizada mediante:
      </p>
      <ul>
        <li>
          a) Fornecimento do nome completo, indicação do género, data de
          nascimento, nacionalidade, número de telemóvel e documento(s) de
          identificação;
        </li>
        <li>b) Aceitação expressa dos Termos e Condições Gerais.</li>
      </ul>
      <p>
        (iii) Ao subscrever o Serviço e durante toda a vigência do Contrato, o
        Cliente declara que:
      </p>
      <ul>
        <li>a) é maior de idade;</li>
        <li>b) está a agir em seu próprio nome;</li>
        <li>
          c) todas as informações fornecidas no momento da inscrição na
          Aplicação BayQi são verdadeiras, precisas e atualizadas; e
        </li>
        <li>
          d) concorda que a Aplicação BayQi pode enviar à BayQi todas as
          informações e documentos de apoio enviados pelo Cliente na Aplicação
          BayQi.
        </li>
      </ul>
      <p>
        (iv) O pedido de subscrição do Cliente é automático, mediante a
        verificação das condições descritas nos números 4.1 e 4.2, sendo a
        utilização da Aplicação BayQi pelo Cliente sujeita a posterior validação
        pela Aplicação BayQi.
      </p>
      <p>
        (v) Sem prejuízo do definido no número (iv) anterior, a BayQi pode, nos
        10 (dez) Dias Úteis após a subscrição do Serviço, sem dar qualquer razão
        justificativa ou sem dar origem a qualquer direito a indemnização a
        favor do Cliente, cancelar a subscrição, a notificar ao Cliente por
        qualquer meio.
      </p>
      <p>
        (vi) A BayQi reserva o direito de solicitar, antes de qualquer registo e
        a qualquer momento durante a vigência do Contrato, documentos adicionais
        relativos ao Cliente, ou a Operações de Pagamento específicas.
      </p>
      <p>
        (vii) Caso o Cliente não seja maior de idade, a subscrição ao Aplicativo
        BayQi está sujeita à entrega de declaração assinada por quem exerce o
        poder paternal sobre o menor, acompanhada dos respectivos documentos de
        identificação, na qual autorizam o menor a aderir aos Termos e Condições
        Gerais e utilizar o Aplicativo BayQi, obrigando-se estes a garantir a
        respectiva supervisão do menor durante a vigência deste Contrato.
      </p>
      <p>
        (viii) A BayQi compromete-se a manter as informações do titular de conta
        atualizadas e precisas para garantir a conformidade com os regulamentos
        aplicáveis e a segurança das transações.
      </p>
      <p>
        (ix) O titular da conta reconhece e concorda que é sua responsabilidade
        manter suas informações pessoais atualizadas por meio do aplicativo ou
        plataforma da BayQi. A BayQi poderá, ocasionalmente, solicitar a
        verificação ou atualização das informações do titular de conta para
        garantir a integridade e a segurança da carteira digital.
      </p>
      <p>
        (x) O titular da conta concorda em cooperar prontamente com quaisquer
        solicitações de atualização de informações feitas pela BayQi, fornecendo
        documentos ou evidências adicionais, conforme necessário.
      </p>
      <p>
        (xi) O titular da conta compreende que informações desatualizadas ou
        imprecisas podem afetar adversamente sua capacidade de utilizar os
        serviços oferecidos pela BayQi e concorda em assumir total
        responsabilidade pela manutenção precisa de suas informações de conta.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        4.3. Celebração do Contrato
      </h3>
      <p>(i) A este contrato é aplicável a língua Portuguesa.</p>
      <p>
        (ii) O titular da conta concorda em receber todas as comunicações,
        incluindo notificações, termos e condições, em língua portuguesa.
      </p>
      <p>
        (iii) Em caso de dúvidas ou necessidade de esclarecimentos adicionais, a
        BayQi compromete-se a fornecer suporte ao cliente na língua portuguesa,
        por meio dos canais de comunicação disponibilizados pela empresa.
      </p>
      <ul>
        <li>
          a. Canal Telefonia Vocal (também designado “Call Center”) do BayQi
          através do número de telefone +244 944 090 618 (chamada rede móvel
          nacional).
        </li>
        <li>b. Canal Electrónico apoiocliente@bayqi.com</li>
      </ul>
      <p>
        (iv) A inscrição na Aplicação BayQi é suficiente para subscrição do
        Serviço e para a celebração do Contrato entre a BayQi e o Cliente.
      </p>
      <p>
        (v) A data de celebração do Contrato é considerada como a data de
        inscrição na Aplicação BayQi.
      </p>
      <p>
        (vi) A qualquer momento durante a vigência do Contrato, o Cliente
        poderá, mediante solicitação, receber os Termos e Condições Gerais e o
        Preçário em papel ou em qualquer outro Meio Duradouro.
      </p>
      <p>
        (vii) A BayQi deverá manter o acesso aos documentos do Contrato por um
        período de 10 (dez) anos a partir do final da vigência do Contrato.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        c. Pagamento através do Aplicativo BayQi
      </h3>
      <p>
        O Aplicativo BayQi irá permitir ao Cliente realizar operações de
        pagamento e pagamentos através de:
      </p>
      <ul>
        <li>a) BayQi Wallet, conforme a Cláusula e) infra;</li>
        <li>
          b) BayQi, que consiste numa opção de pagamento por terceiros inseridos
          na rede de pagamento BayQi – incluindo Amigos BayQi –, que permite
          enviar uma solicitação de pagamento a contactos existentes na sua
          rede;
        </li>
        <li>
          c) Multicaixa Express, sujeita a confirmação no respectivo aplicativo;
        </li>
        <li>
          d) Referência Multicaixa, com um prazo de validade no máximo de 48
          (quarenta e oito) horas para completar o pagamento, caso ultrapasse o
          prazo a sua referência será expirada.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>d. Limites</h3>
      <p>
        i. As operações a crédito e a débito da Aplicação BayQi, de acordo com
        os respectivos Métodos de Pagamento, estão sujeitas aos limites
        determinados pelo Banco Nacional de Angola.
      </p>
      <p>
        ii. As transferências de Amigos PagaQi é de Kz 2.000.000,00 (dois
        milhões de Kwanzas) por ordenante.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        e) FUNCIONAMENTO DA BAYQI WALLET
      </h3>
      <p>a. Disposições Gerais</p>
      <p>
        b. A BayQi pode solicitar ao Cliente que apresente um extrato bancário
        de uma conta aberta em seu nome numa instituição financeira bancária
        angolana, bem como documentos adicionais relativos ao Cliente para
        funcionamento da BayQi Wallet.
      </p>
      <p>
        c. Por livre decisão da BayQi, a utilização de uma BayQi Wallet pode ser
        limitada sem que a BayQi tenha de justificar a sua decisão ao Cliente. A
        BayQi deverá notificar imediatamente o Cliente sobre quaisquer limites
        relacionados com a BayQi Wallet.
      </p>
      <p>
        i. Os montantes creditados na BayQi Wallet resultam de fundos
        transferidos por qualquer Método de Pagamento.
      </p>
      <p>
        ii. Os valores debitados na BayQi Wallet resultam da execução de uma
        Ordem de Pagamento em uma conta aberta nos registos da BayQi ou de um
        Terceiro Prestador de Serviços de Pagamento, do débito pela BayQi das
        taxas devidas pelo Cliente nos termos do Contrato ou do reembolso de uma
        Operação de Pagamento.
      </p>
      <p>
        iii. Após validação do processo de subscrição à Aplicação BayQi, será
        emitido um número único de Cliente e dada ao Cliente a escolha para um
        número PIN secreto, com o qual poderá realizar as Operações de Pagamento
        na Aplicação BayQi.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        d. Transferências para a BayQi Wallet
      </h3>
      <p>
        i. A BayQi Wallet pode ser creditada através de qualquer Método de
        Pagamento.
      </p>
      <p>
        ii. Na presente data, os Métodos de Pagamento aceites para carregamento
        da Wallet BayQi são:
      </p>
      <ul>
        <li>e) Transferência bancária;</li>
        <li>f) Meios disponibilizados pela Rede EMIS e aceites pela BayQi;</li>
        <li>g) Transferências de Amigos BayQi.</li>
      </ul>
      <p>
        iii. Quando os Clientes pretendem transferir fundos para a BayQi Wallet,
        devem identificar-se junto da BayQi, de acordo com os procedimentos de
        autenticação implementados pela BayQi.
      </p>
      <p>
        iv. A ordem de transferência de fundos é inserida numa Página de
        Pagamento específica.
      </p>
      <p>
        v. Se necessário, a BayQi pode recusar qualquer pagamento, a seu
        critério, e sem que esta decisão dê lugar a qualquer tipo de
        compensação.
      </p>
      <p>
        vi. A aceitação de uma ordem de pagamento pela BayQi não garante o
        recebimento dos fundos correspondentes pelo Cliente na sua BayQi Wallet.
        O registo dos fundos na BayQi Wallet está sujeito à recepção efetiva
        pela BayQi dos fundos recolhidos, menos as taxas acordadas no Preçário.
      </p>
      <p>
        vii. A operação de carregamento da BayQi Wallet poderá demorar até 2
        (dois) Dias Úteis até que esteja concluída.
      </p>
      <p>
        viii. Uma vez os fundos recebidos e registados na BayQi Wallet, a BayQi
        fornece ao Cliente um resumo da transação de transferência recebida.
      </p>
      <p>
        ix. Na ausência de recebimento de fundos por razões técnicas
        relacionadas com a BayQi, a BayQi envidará os seus melhores esforços
        para retificar a transação. Se os fundos não forem recebidos por
        qualquer outro motivo, a BayQi informará o Cliente da sua incapacidade
        de creditar a respectiva BayQi Wallet com o montante esperado.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        e. Execução de Operação de Pagamento para débito da BayQi Wallet
      </h3>
      <p>
        i. O Cliente pode a qualquer momento enviar uma ordem para executar uma
        Ordem de Pagamento a um Beneficiário, que tenha uma conta bancária ou de
        pagamento com a BayQi ou com Terceiro Prestador de Serviços de
        Pagamento.
      </p>
      <p>
        ii. Quando o Cliente desejar efetuar uma Operação de Pagamento de acordo
        com a cláusula 5.5.(i), deve identificar no seu Espaço Pessoal os
        respectivos Dados de Identificação e seguir o procedimento de
        Autenticação ou Autenticação Forte do Cliente, conforme o caso,
        especificado pela Aplicação BayQi.
      </p>
      <p>
        iii. Para a execução de uma Ordem de Pagamento o Cliente deverá indicar
        e/ou confirmar na Página de Pagamento, as seguintes informações:
      </p>
      <ul>
        <li>a) o Beneficiário;</li>
        <li>b) o montante da operação de pagamento;</li>
        <li>c) a moeda;</li>
        <li>
          d) a data de implementação da Ordem de Pagamento ou, na ausência de
          qualquer indicação da data de implementação, considera-se que a Ordem
          de Pagamento é imediata; e
        </li>
        <li>
          e) qualquer outra informação considerada como necessária pela BayQi.
        </li>
      </ul>
      <p>
        iv. O Cliente dá o seu consentimento à Ordem de Pagamento seguindo as
        instruções indicadas na Página de Pagamento e, nesta data ("Data da
        Ordem"), a Ordem de Pagamento é considerada irrevogável. O Cliente não
        poderá revogar qualquer Ordem de Pagamento após a Data da Ordem de
        pagamento.
      </p>
      <p>
        v. Antes de enviar uma Ordem de Pagamento, o Cliente deve certificar-se
        de que tem fundos suficientes disponíveis na sua BayQi Wallet para
        cobrir o valor da Operação de Pagamento e as taxas relacionadas,
        conforme acordado no Preçário. Quando aplicável, o Cliente deverá
        creditar na sua BayQi Wallet antes que a Ordem de Pagamento possa ser
        validamente enviada para implementação.
      </p>
      <p>
        vi. As Partes concordam expressamente que as Ordens de Pagamento serão
        execut adas automaticamente após Autenticação pelo Cliente da Operação
        de Pagamento.
      </p>

      <p>
        vii. A BayQi pode recusar-se a executar uma Ordem de Pagamento
        incompleta ou errada, ou bloquear uma Ordem de Pagamento em caso de
        suspeita de utilização fraudulenta da BayQi Wallet, utilização não
        autorizada da BayQi Wallet, violação da segurança da BayQi Wallet, no
        caso de uma medida de congelamento de bens por uma autoridade
        administrativa ou por qualquer outro motivo razoável.
      </p>
      <p>
        viii. Em caso de recusa ou bloqueio de uma Ordem de Pagamento de acordo
        com a cláusula 5.5.(viii), a BayQi deverá informar o Cliente por e-mail,
        indicando os motivos da recusa ou bloqueio, a menos que proibido pela
        lei aplicável, bem como o procedimento a ser seguido pelo Cliente para
        completar ou corrigir os erros ou para desbloquear a Ordem de Pagamento.
        Uma taxa poderá ser cobrada pela BayQi para este serviço específico de
        retificação e desbloqueio.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        f. Link de Pagamento (QRcode)
      </h3>
      <p>
        Quando disponível, poderá efetuar a Operação de Pagamento através de um
        link de pagamento (QRcode) de Comerciante. Ao clicar no link e efetuar o
        pagamento sofrerá um débito na BayQi Wallet.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        g. Mecanismo de reembolso (cash-out)
      </h3>
      <p>
        i. Qualquer pedido de reembolso por parte do Cliente está sujeito ao
        pagamento de uma comissão nos termos e condições definidos no Preçário,
        nos seguintes casos:
      </p>
      <ul>
        <li>
          a) O reembolso ser pedido antes do termo fixado para o fim do
          Contrato;
        </li>
        <li>b) Denúncia antecipada pelo Cliente; ou</li>
        <li>
          c) O reembolso ser solicitado após 12 (doze) meses após o termo do
          contrato.
        </li>
      </ul>
      <p>
        ii. Para efeitos de reembolso, o Cliente deve indicar os detalhes de uma
        conta bancária, aberta, em Kwanzas, domiciliada em instituição
        financeira bancária angolana.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>f) RELATÓRIO</h3>
      <ul>
        <li>
          a. O Cliente tem, no seu Espaço Pessoal, um extrato das Operações de
          Pagamento realizadas na BayQi Wallet.
        </li>
        <li>
          b. Os registos de Operações de pagamento também podem ser
          disponibilizados ao Cliente em outros intervalos, mediante pedido
          específico.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        g) ACESSO À BAYQI WALLET E CONFIDENCIALIDADE DOS DADOS DE SEGURANÇA
        PERSONALIZADOS
      </h3>
      <ul>
        <li>
          a. A BayQi Wallet é acessível online no Espaço Pessoal do Cliente,
          utilizando os Dados de Identificação e de acordo com o procedimento de
          Autenticação solicitado ou Autenticação Forte do Cliente, conforme o
          caso.
        </li>
        <li>
          b. O Cliente é o único responsável por qualquer utilização do seu
          Identificador Único.
        </li>
        <li>
          c. O Cliente deverá tomar todas as medidas razoáveis a fim de manter a
          confidencialidade e a segurança dos seus Dados de Segurança
          Personalizados.
        </li>
        <li>
          d. O Cliente concorda em não divulgar os seus Dados de Segurança
          Personalizados a terceiros.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        h) PROTEÇÃO DOS DADOS DE SEGURANÇA PERSONALIZADOS
      </h3>
      <p>
        O Cliente deve informar a BayQi da perda ou roubo dos seus Dados de
        Segurança Personalizados, a apropriação indevida ou qualquer uso não
        autorizado do seu Espaço Pessoal ou dados relacionados e assim que tiver
        conhecimento para solicitar o seu bloqueio. Esta declaração deve ser
        feita por e-mail para o contacto email: apoiocliente@bayqi.com
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        i) BLOQUEIO DA BAYQI WALLET E RECUSA DE ACESSO À BAYQI WALLET
      </h3>
      <ul>
        <li>
          a. A BayQi reserva-se o direito de bloquear a BayQi Wallet por razões
          objetivamente justificadas relacionadas com a segurança da BayQi
          Wallet, a presunção de utilização não autorizada ou fraudulenta da
          BayQi Wallet ou o aumento significativo do risco de que o Cliente não
          irá cumprir a sua obrigação de pagar as taxas devidas nos termos do
          Contrato.
        </li>
        <li>
          b. Nos casos referidos na cláusula 9.1, o Cliente será informado no
          seu Espaço Pessoal do bloqueio ou da recusa de acesso à BayQi Wallet e
          dos motivos de tal bloqueio ou recusa. Esta informação será comunicada
          ao Cliente, se possível, antes do bloqueio ou recusa de acesso à BayQi
          Wallet e, o mais tardar, imediatamente após o bloqueio ou recusa, a
          menos que o fornecimento desta informação não seja comunicável por
          razões de segurança objetivamente justificadas, ou seja proibido nos
          termos da Lei Aplicável.
        </li>
        <li>
          c. A BayQi desbloqueia a BayQi Wallet ou restabelece o acesso à mesma
          assim que os motivos que justificam o bloqueio ou a recusa de acesso
          deixarem de existir.
        </li>
        <li>
          d. O Cliente pode solicitar que a BayQi Wallet seja desbloqueada a
          qualquer momento, contactando a BayQi para o email
          apoiocliente@bayqi.com. Poderá ser solicitado ao Cliente a definição
          de novos Dados de Identificação.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        j) OPERAÇÕES NÃO AUTORIZADAS
      </h3>
      <ul>
        <li>
          a. O Cliente que pretenda retificar uma Operação de Pagamento que não
          tenha sido devidamente autorizada ou que tenha sido incorretamente
          executada, deve notificar a BayQi por escrito sobre essa operação o
          mais rapidamente possível após ter tomado conhecimento da anomalia e
          nunca após 13 (treze) meses da data do débito.
        </li>
        <li>
          b. A menos que o BayQi tenha motivos razoáveis para suspeitar de
          fraude e comunique esses motivos por escrito ao BNA ou em caso de
          perda, roubo ou apropriação indevida do instrumento de pagamento, a
          BayQi reembolsará o Cliente pelo valor da Operação de Pagamento
          imediatamente após a recepção do pedido de retificação e, em qualquer
          caso, até ao final do primeiro dia útil após ter tomado conhecimento
          da Operação de Pag amento ou da comunicação de retificação do Cliente.
          A BayQi deverá repor a BayQi Wallet no estado em que estaria se a
          Operação de Pagamento não autorizada não tivesse tido lugar.
        </li>
        <li>
          c. Em caso de perda, roubo ou apropriação indevida dos Dados de
          Segurança Personalizados, quaisquer Operações de Pagamento não
          autorizadas realizadas antes da notificação da oposição serão
          suportadas pelo Cliente.
        </li>
        <li>
          d. As operações de pagamento realizadas após a oposição são suportadas
          pela BayQi, exceto em caso de fraude por parte do Cliente.
        </li>
        <li>
          e. O Cliente obriga-se ainda a não: (i) interferir na utilização do
          Aplicativo BayQi de outro Cliente; (ii) associar sem autorização, a
          imagem ou marca da BayQi; (iii) reproduzir, copiar, imprimir, criar
          deviações ou por alguma forma modificar o Aplicativo BayQi; e (iv)
          realizar quaisquer operações ilícitas ou fraudulentas.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        k) CONDIÇÕES FINANCEIRAS
      </h3>
      <ul>
        <li>
          a. Os serviços prestados pela BayQi estão sujeitos ao pagamento das
          taxas e comissões previstas no Preçário.
        </li>
        <li>
          b. Todas as comissões devidas pelo Cliente são automaticamente
          deduzidas da BayQi Wallet pela BayQi.
        </li>
        <li>
          c. O Cliente autoriza a BayQi a compensar a qualquer momento,
          inclusive após o encerramento da BayQi Wallet, qualquer reclamação
          definitiva, líquida e devida que permaneça pendente por qualquer
          motivo. A BayQi pode compensar a provisão na BayQi Wallet contra
          qualquer montante devido, pagável e não pago pelo Cliente à BayQi.
        </li>
        <li>
          d. Em caso de atraso no pagamento das taxas devidas e a pagar pelo
          Cliente à BayQi, o Cliente será responsável pelo pagamento de juros de
          mora pelo período desde a data de vencimento até o pagamento integral.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        l) OBRIGAÇÕES GERAIS DO CLIENTE
      </h3>
      <ul>
        <li>
          a. Sem prejuízo de outras obrigações estabelecidas no Contrato ou
          previstas na Lei Aplicável, o Cliente compromete-se a:
        </li>
        <ul>
          <li>
            i. assegurar que nada no seu Espaço Pessoal viole os direitos de
            terceiros, seja contrário à lei, à ordem pública ou aos bons
            costumes;
          </li>
          <li>
            ii. não implementar o Contrato de forma ilegal ou sob condições que
            possam danificar, incapacitar, sobrecarregar ou prejudicar a
            Aplicação BayQi ou a BayQi;
          </li>
          <li>
            iii. não se fazer passar pela identidade de outra pessoa ou
            entidade, falsificar ou ocultar a sua identidade, idade ou criar uma
            identidade falsa;
          </li>
          <li>
            iv. não distribuir dados pessoais ou informações relativas a
            terceiros, tais como endereços postais, números de telefone,
            endereços de e-mail, números de cartões bancários;
          </li>
        </ul>
        <li>
          b. Sem prejuízo de qualquer ação judicial intentada por terceiros, a
          BayQi tem o direito de intentar qualquer ação judicial, a título
          próprio, para compensar qualquer dano que possa ter sofrido
          pessoalmente como resultado do incumprimento pelo Cliente das suas
          obrigações nos termos do Contrato.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        m) PRAZO E DENÚNCIA
      </h3>
      <ul>
        <li>
          a. O Contrato é celebrado por um período de 12 (doze) meses,
          automaticamente renovável por períodos iguais e sucessivos, sem
          prejuízo da possibilidade de oposição por qualquer uma das Partes,
          sujeito a um aviso prévio por escrito de 1 (um) mês.
        </li>
        <li>
          b. Qualquer Parte pode denunciar livremente o Contrato a qualquer
          momento, sujeito a um aviso prévio por escrito de 1 (um) mês,
          fornecido através de qualquer Meio Duradouro.
        </li>
        <li>
          c. Em caso de denúncia do Contrato:
          <ul>
            <li>a) a BayQi Wallet é fechada;</li>
            <li>
              b) as taxas devidas pelos Serviços serão pagas pelo Cliente
              proporcionalmente ao período até à data da denúncia;
            </li>
            <li>
              c) se o Contrato durar por um período inferior a 6 (seis) meses em
              virtude da denúncia pelo Cliente, as penalidades previstas no
              Preçário serão pagas pelo Cliente;
            </li>
            <li>
              d) qualquer crédito na BayQi Wallet será transferido dentro de 2
              (dois) meses para a conta bancária indicada pelo Cliente para tal
              fim, após a dedução das taxas devidas e a pagar pelo Cliente à
              BayQi.
            </li>
          </ul>
        </li>
        <li>
          d. Em caso de violações graves, fraude ou dívidas não pagas por parte
          do Cliente, a BayQi reserva o direito de suspender ou resolver o
          Contrato, enviando um e-mail ao Cliente acompanhado de uma carta
          registada, sem necessidade de aviso prévio.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>n) SEGURANÇA</h3>
      <ul>
        <li>
          a. A BayQi compromete-se a prestar os Serviços em conformidade com a
          Lei Aplicável e as melhores práticas.
        </li>
        <li>
          b. A BayQi fará todos os esforços para garantir a segurança e
          confidencialidade dos dados do Cliente, de acordo com a Lei Aplicável.
        </li>
        <li>
          c. A BayQi reserva o direito de suspender temporariamente o acesso à
          BayQi Wallet por razões técnicas, de segurança ou de manutenção, sem
          que tal atribua ao Cliente um direito a compensação. A BayQi
          compromete-se a limitar este tipo de interrupção ao estritamente
          necessário.
        </li>
        <li>
          d. A BayQi não pode ser responsabilizada perante o Cliente por
          eventuais erros, omissões, interrupções ou atrasos nas operações
          realizadas através da Aplicação BayQi resultantes do acesso não
          autorizado à mesma.
        </li>
        <li>
          e. A BayQi não pode ser responsabilizada por roubo, destruição ou
          comunicação não autorizada de dados resultantes do acesso não
          autorizado à Aplicação BayQi.
        </li>
        <li>
          f. A BayQi não pode ser responsabilizada pelas falhas, deficiências ou
          negligência que advierem do Método de Pagamento escolhido pelo
          Cliente.
        </li>
        <li>
          g. Se o Identificador Único ou qualquer outra informação necessária
          para a execução de uma Operação de Pagamento fornecida pelo Cliente
          for incorreta, a BayQi não será responsável pela não execução ou
          execução incorreta de tal Operação de Pagamento.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        o) SUSPENSÃO DO SERVIÇO
      </h3>
      <p>
        Em caso de incumprimento das suas obrigações por parte do Cliente, a
        BayQi poderá tomar todas as medidas adequadas para cessar as ações em
        causa e terá ainda o direito de suspender, eliminar e/ou bloquear o
        acesso do Cliente à sua BayQi Wallet e suspender a prestação dos
        Serviços até que o Cliente tenha cumprido tais obrigações.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        p) CONTAS DE PAGAMENTO INATIVAS
      </h3>
      <ul>
        <li>
          a. Qualquer BayQi Wallet inativa pode ser sujeita a uma notificação de
          inatividade enviada por e-mail pela PagaQi seguida de um lembrete 1
          (um) mês depois.
        </li>
        <li>
          b. A BayQi Wallet é considerada inativa quando, ao fim de um período
          de 6 (seis) meses, não tiver sido objeto de nenhuma transação (além da
          cobrança de taxas de administração) por iniciativa do Cliente e não
          tenha comunicado com a BayQi sob qualquer forma.
        </li>
        <li>
          c. Se não houver resposta e não houver utilização do saldo creditado
          na BayQi Wallet dentro do período estabelecido na cláusula 16.2, a
          BayQi poderá encerrar a BayQi Wallet e mantê-la com a única finalidade
          de transferir os montantes devidos para a conta indicada pelo Cliente,
          e sujeito aos Termos e Condições Gerais.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        q) LIMITAÇÃO DA RESPONSABILIDADE DA BAYQI
      </h3>
      <ul>
        <li>
          a. A BayQi não intervém de forma alguma nas relações jurídicas e
          comerciais e nos eventuais litígios entre o Cliente e um Comerciante,
          o Cliente e um Terceiro Prestador de Serviços de Pagamento ou entre o
          Cliente e um Beneficiário.
        </li>
        <li>
          b. A BayQi não tem controlo sobre a conformidade, segurança,
          legalidade, características e adequação dos produtos e serviços que
          são objeto de uma Operação de Pagamento.
        </li>
        <li>
          c. A BayQi não pode ser responsabilizada por quaisquer desacordos ou
          disputas relacionadas ao valor das transações, uma vez que apenas
          facilita a transferência de fundos entre as partes.
        </li>
        <li>
          d. Cada transação realizada pelo Cliente dá origem a um acordo,
          formado diretamente entre o Cliente e um Comerciante, no qual a BayQi
          não está envolvida. Consequentemente, a BayQi não poderá ser
          responsabilizada pelo não cumprimento ou cumprimento indevido das
          obrigações daí resultantes, nem por quaisquer danos causados ao
          Cliente.
        </li>
        <li>
          e. O Aplicativo BayQi pode conter links ou reencaminhar para sites de
          Comerciantes ou Terceiros Prestadores de Serviços de Pagamento. Ao
          acessar a esses links e sites, o Cliente reconhece expressamente que o
          conteúdo e informação providenciado nesses ecossistemas não são da
          responsabilidade da BayQi, não assumindo esta qualquer
          responsabilidade. O Aplicativo BayQi não constitui e não pode ser
          interpretado como qualquer tipo de endosso, patrocínio ou
          representação da BayQi relativamente a esses Comerciantes ou Terceiros
          Prestadores de Serviços de Pagamento.
        </li>
        <li>
          f. Não obstante qualquer disposição em contrário no Contrato, a
          responsabilidade da BayQi para com um Cliente limita-se à indenização
          por danos diretos, tal como previsto na Lei Aplicável.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        r) REGRAS RELATIVAS À LUTA CONTRA O BRANQUEAMENTO DE CAPITAIS E O
        FINANCIAMENTO DO TERRORISMO
      </h3>
      <ul>
        <li>
          a. A PagaQi está sujeita à Lei Aplicável relativa ao combate ao
          branqueamento de capitais e ao financiamento do terrorismo e é,
          portanto, obrigada a obter informações de todos os Clientes para todas
          as transações ou relações comerciais quanto à origem, finalidade e
          destino da transação ou à abertura da BayQi Wallet. A PagaQi também
          deve tomar todas as medidas necessárias para identificar o Cliente e,
          quando aplicável, o beneficiário efetivo da BayQi Wallet e/ou das
          Operações de Pagamento com ela relacionadas.
        </li>
        <li>
          b. O Cliente reconhece que a PagaQi pode, a qualquer momento, cancelar
          ou adiar a utilização de Dados de Segurança Personalizados, o acesso a
          uma BayQi Wallet ou a implementação de uma Operação de Pagamento na
          ausência de informação suficiente sobre a sua finalidade ou natureza.
        </li>
        <li>
          c. O Cliente é informado de que uma transação efetuada ao abrigo do
          presente documento pode estar sujeita ao exercício do direito de
          divulgação às autoridades competentes. O Cliente pode, em conformidade
          com a Lei Aplicável, acessar a todas as informações assim comunicadas,
          desde que este direito de acesso não prejudique o objetivo da luta
          contra o branqueamento de capitais e o financiamento do terrorismo,
          quando estes dados se relacionem com o requerente.
        </li>
        <li>
          d. Nenhuma ação legal ou ação civil pode ser proposta ou qualquer
          sanção profissional imposta contra a Paga Qi, os seus gerentes ou
          funcionários que tenham feito declarações de suspeita, de boa fé, às
          autoridades competentes.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        s) DIREITOS DE PROPRIEDADE INTELECTUAL
      </h3>
      <p>
        A PagaQi manterá a propriedade plena dos títulos de propriedade e
        direitos, de qualquer tipo, ligados aos Serviços oferecidos ao Cliente.
        Nenhum destes direitos de propriedade é transferido para o Cliente.
      </p>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        t) PROTEÇÃO DE DADOS PESSOAIS
      </h3>
      <ul>
        <li>
          a. A PagaQi recolhe e processa todos os Dados Pessoais de acordo com a
          Lei Aplicável relativa à proteção de tais dados.
        </li>
        <li>
          b. Os Dados Pessoais exigidos no momento da subscrição dos Serviços
          são necessários no âmbito dos Serviços prestados em conformidade com o
          Contrato e o não fornecimento dos Dados Pessoais obrigatórios para
          prosseguir com a subscrição dos Serviços pode resultar na recusa do
          acesso do potencial cliente aos Serviços.
        </li>
        <li>
          c. A PagaQi atuará como responsável pelo tratamento dos Dados Pessoais
          no contexto do Contrato.
        </li>
        <li>
          d. O tratamento dos Dados Pessoais é necessário para a execução do
          Contrato e para o cumprimento das obrigações legais a que a PagaQi
          está sujeita, incluindo, em particular, as seguintes finalidades:
          <ul>
            <li>i. a prestação dos Serviços;</li>
            <li>
              ii. a análise de risco do ponto de vista financeiro e notação de
              crédito;
            </li>
            <li>
              iii. a prevenção, investigação e detecção de branqueamento de
              capitais e de financiamento do terrorismo;
            </li>
            <li>iv. o processamento de pedidos de informação e reclamações;</li>
            <li>
              v. a prevenção, investigação e detecção de fraudes em matéria de
              pagamentos; e
            </li>
            <li>vi. a produção de estatísticas.</li>
          </ul>
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        e. PARTILHA DE DADOS PESSOAIS
      </h3>
      <p>
        O Cliente consente que os seus dados pessoais recolhidos sejam
        partilhados com o regulador e operador do Sistema de Pagamentos de
        Angola, para efeitos de superintendência, gestão e funcionamento do
        referido sistema, obrigando-se a PagaQi a salvaguardar o cumprimento do
        disposto no artigo 92.º da Lei de Sistemas de Pagamentos de Angola.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        f. Os Dados Pessoais não serão divulgados a terceiros
      </h3>
      <p>
        Os Dados Pessoais não serão divulgados a terceiros que atuem como
        responsáveis pelo tratamento sem o consentimento expresso dos Titulares
        dos Dados, a menos que a divulgação seja exigida por lei ou decisão
        judicial, seja necessária para o cumprimento de um contrato, cumprimento
        de obrigações legais ou qualquer outra causa legalmente prevista.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        g. Nomeação de terceiros
      </h3>
      <p>
        Para cumprir as suas funções e prestação dos Serviços, a PagaQi poderá
        ter de nomear terceiros para prestarem serviços que impliquem o
        tratamento de dados pessoais em seu nome. Tais terceiros agirão como
        subcontratantes para os fins da Lei Aplicável e só cumprirão as
        instruções emitidas pela PagaQi. Tais destinatários prestam serviços de
        suporte de TI, gestão de documentos, suporte de contabilidade ou
        armazenamento de dados.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        h. Atuação dos subcontratantes
      </h3>
      <p>
        Os subcontratantes mencionados agirão somente sob as instruções da
        PagaQi e exclusivamente em nome da PagaQi e, a PagaQi assegurará que os
        subcontratantes tomam todas as medidas necessárias para preservar a
        segurança e a confidencialidade dos Dados Pessoais. A PagaQi apenas
        nomeia subcontratantes que ofereçam garantias suficientes para
        implementar medidas técnicas e organizativas adequadas, para que o
        tratamento atenda aos requisitos da Lei Aplicável e garanta a proteção
        dos direitos dos Titulares dos Dados.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        i. Revelação de Dados Pessoais
      </h3>
      <p>
        A PagaQi pode revelar Dados Pessoais a pedido de uma autoridade
        competente para cumprir a Lei Aplicável, para proteger ou defender os
        direitos do Cliente ou de um Titular de Dados, se circunstâncias
        imperiosas o justificarem, ou para proteger a segurança do Cliente, dos
        Serviços ou do público.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        j. Retenção de Dados Pessoais
      </h3>
      <p>
        Os Dados Pessoais tratados pela PagaQi no âmbito dos Serviços prestados
        em conformidade com esta cláusula, serão retidos pelo período de tempo
        estritamente necessário para alcançar os fins acima mencionados e para
        cumprir a Lei Aplicável. A menos que a lei preveja um período de
        retenção de dados diferente, a PagaQi reterá os dados durante um período
        de 10 anos.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        k. Direitos dos titulares dos dados
      </h3>
      <p>
        Os titulares dos dados têm os seguintes direitos sobre os seus Dados
        Pessoais, de acordo com as condições estabelecidas na Lei Aplicável:
        direito de informação, direito de acesso, direito de oposição, direito
        de retificação, atualização e eliminação e direito de apresentar uma
        reclamação à autoridade competente para qualquer pedido relacionado com
        os seus Dados Pessoais. Os titulares dos dados podem, a qualquer
        momento, exercer os seus direitos contactando o suporte através do
        email: apoiocliente@bayqi.com. Os titulares dos dados devem indicar o
        nome e sobrenome do requerente e o identificador único e ser acompanhado
        por uma fotocópia de um documento de identidade com a assinatura do
        requerente.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        l. Política de Privacidade
      </h3>
      <p>
        Mais informações sobre o processamento de Dados Pessoais realizado no
        âmbito do Contrato, os períodos de retenção e os direitos dos Titulares
        dos Dados estão disponíveis na Política de Privacidade acessível na
        Aplicação BayQi.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        u) SIGILO PROFISSIONAL
      </h3>

      <ul>
        <li>
          a. A PagaQi está vinculada ao sigilo profissional. Contudo, este
          sigilo pode ser levantado, de acordo com a Lei Aplicável, em virtude
          de uma obrigação legal regulamentar e prudencial, nomeadamente, a
          pedido das autoridades de supervisão, da administração fiscal ou
          aduaneira, bem como do tribunal penal ou, em caso de ordem judicial,
          notificada à PagaQi.
        </li>
        <li>
          b. Não obstante o acima exposto, o Cliente tem o direito de liberar a
          PagaQi da obrigação de sigilo profissional, indicando expressamente à
          PagaQi os terceiros autorizados a receber informações confidenciais.
        </li>
        <li>
          c. O sigilo profissional pode ser levantado pelo efeito dos
          regulamentos, a favor das empresas que fornecem à PagaQi tarefas
          operacionais importantes, no âmbito do presente Contrato.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>v) NOTIFICAÇÕES</h3>
      <ul>
        <li>
          a. Salvo disposição em contrário no Contrato, qualquer notificação
          dada a uma Parte ao abrigo ou em conexão com o Contrato deverá ser
          feita por escrito e deverá ser entregue por e-mail.
        </li>
        <li>
          b. Esta cláusula não se aplica à notificação de qualquer processo ou
          outros documentos em qualquer ação legal ou, quando aplicável,
          qualquer arbitragem ou outro método de resolução de litígios.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        w) ALTERAÇÕES OU MODIFICAÇÃO DO CONTRATO
      </h3>
      <ul>
        <li>
          a. O Contrato reflete o acordo total das Partes relativamente ao
          respetivo objeto na presente data e sobrepõe-se a todos os acordos
          anteriores e substitui todos os contratos, entendimentos e
          compromissos anteriores, escritos ou orais. Nenhuma representação
          feita oralmente ou por escrito, antes ou depois da execução do
          Contrato, fará parte do Contrato ou será utilizada na sua
          interpretação.
        </li>
        <li>
          b. Salvo disposição em contrário da lei aplicável, a PagaQi pode
          alterar ou modificar o Contrato, enviando um e-mail para o endereço de
          e-mail do Cliente registado na Aplicação BayQi pelo menos 1 (um) mês
          antes da data proposta para a aplicação da alteração ou modificação.
        </li>
        <li>
          c. Se o Cliente não concordar com qualquer alteração ou modificação,
          poderá rejeitar a alteração ou modificação e denunciar o Contrato, até
          à data proposta para a aplicação da alteração.
        </li>
        <li>
          d. Se o Cliente não notificar a PagaQi da rejeição da alteração ou
          modificação proposta de acordo com a cláusula 23.3, a alteração ou
          modificação do Contrato será considerada como tendo sido aceite pelo
          Cliente e entrará em vigor na data proposta.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        x) CESSÃO E OUTRAS NEGOCIAÇÕES
      </h3>
      <ul>
        <li>
          a. O Cliente não poderá, sem o prévio consentimento escrito da PagaQi,
          ceder, transferir, cobrar, subcontratar ou negociar de qualquer outra
          forma, todos ou quaisquer dos seus direitos ou obrigações nos termos
          do Contrato.
        </li>
        <li>
          b. O Cliente autoriza expressamente a PagaQi a ceder, transferir,
          cobrar, subcontratar ou negociar de qualquer outra forma, todos ou
          quaisquer dos seus direitos ou obrigações nos termos do Contrato.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>y) RENÚNCIA</h3>
      <p>
        O atraso ou não exercício por uma Parte de qualquer direito ou ação
        previstos no Contrato ou na Lei Aplicável, não constituirá uma renúncia
        a esse ou qualquer outro direito ou ação, nem impedirá ou restringirá o
        exercício posterior desse ou de qualquer outro direito ou ação. O
        exercício total ou parcial de um direito ou ação não impede ou restringe
        o exercício posterior desse ou de qualquer outro direito ou ação.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        z) INVALIDADE OU INEFICÁCIA
      </h3>
      <p>
        Se qualquer disposição do Acordo for determinada como inválida ou
        inaplicável sob qualquer Lei Aplicável, tal disposição será alterada e
        interpretada para cumprir os objetivos da disposição, na medida do
        possível sob qualquer Lei Aplicável e a validade ou aplicabilidade de
        qualquer outra disposição do presente Acordo não será afetada.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>aa) FORÇA MAIOR</h3>
      <ul>
        <li>
          a. A PagaQi não será considerada responsável pelo não cumprimento ou
          atraso no cumprimento das obrigações previstas no Contrato, se o não
          cumprimento ou atraso for resultado de um evento de força maior, tal
          como um terramoto, furacão, tornado, inundação ou outro desastre
          natural, ou em caso de guerra, ação de inimigos estrangeiros,
          atividades terroristas, disputa ou greve laboral, sanção
          governamental, bloqueio, embargo ou falha do serviço elétrico, ou
          cumprimento de outras obrigações legais ou regulamentares,
          nomeadamente relacionadas com o combate ao branqueamento de capitais e
          ao financiamento do terrorismo.
        </li>
        <li>
          b. No caso previsto na cláusula 27.1, a PagaQi deve envidar todos os
          esforços razoáveis para minimizar o atraso no cumprimento das
          respectivas obrigações.
        </li>
        <li>
          c. Se o evento de força maior continuar por mais de 60 (sessenta)
          dias, a PagaQi poderá resolver o Contrato.
        </li>
      </ul>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        bb) APOIO AO CLIENTE
      </h3>
      <p>
        O Cliente pode contactar a PagaQi através de qualquer ligação de apoio
        ao cliente, serviço ou via e-mail para apoiocliente@bayqi.com, em
        relação a qualquer resposta, perguntas ou reclamações. Ao contactar a
        PagaQi, o Cliente deverá fornecer à PagaQi o seu nome e qualquer outra
        informação que a PagaQi possa necessitar para identificar o Cliente, a
        BayQi Wallet e a respectiva Operação de Pagamento.
      </p>

      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>cc) MEDIAÇÃO</h3>
      <ul>
        <li>
          a. Em caso de disputa ou reclamação decorrente ou relacionada com o
          Contrato, as Partes entrarão em contato de forma a negociar e alcançar
          uma solução satisfatória dentro do prazo de 30 (trinta) dias.
        </li>
        <li>
          b. Perante qualquer disputa relacionada com uso do Serviço, a PagaQi
          conduzirá inicialmente a sua própria investigação e, o Cliente
          concorda desde já em cooperar, na medida do razoável, com a
          investigação da PagaQi, fornecer informações solicitadas e
          consequentemente:
          <ul>
            <li>
              i. se for determinado pela PagaQi com uma fundamentação razoável
              que os montantes são devidos pelo Cliente com base em erro, fraude
              ou semelhante, o Cliente concorda em fazer o pagamento à PagaQi,
              de acordo com a decisão da PagaQi, dentro de 15 (quinze) dias;
            </li>
            <li>
              ii. se for determinado pela PagaQi com uma fundamentação razoável
              que os montantes são devidos ao Cliente, a PagaQi concorda em
              fazer o pagamento desses montantes ao Cliente no prazo de 15
              (quinze) dias.
            </li>
          </ul>
        </li>
        <li>
          c. As reclamações ou sugestões dos Clientes devem ser enviadas para:
          <ul>
            <li>b) por email: reclamacoes@bayqi.com; apoiocliente@bayqi.com</li>
            <li>
              c) por telefone, no horário de expediente da PagaQi: +244 944 090
              618;
            </li>
            <li>
              d) por correio: Rua António Feliciano Castilho N.º 218/220, Vila
              Alice;
            </li>
          </ul>
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        dd) RESOLUÇÃO DE LITÍGIOS
      </h3>
      <ul>
        <li>
          a. Além do recurso à Mediação, cada Parte terá o direito a que
          qualquer disputa que surja entre as Partes, nos termos do Contrato ou
          relacionada com o mesmo, seja resolvida por meio de arbitragem.
        </li>
        <li>
          b. O litígio será resolvido por um árbitro, que terá competência
          exclusiva para resolver qualquer disputa decorrente ou relacionada com
          a interpretação, aplicação, execução ou formação do Contrato,
          incluindo, mas não se limitando a, qualquer reivindicação de que a
          totalidade ou parte do requerimento arbitral, é nulo ou anulável.
        </li>
        <li>
          c. A arbitragem deve proceder somente nos termos e para os efeitos da
          presente cláusula arbitral e segundo as Lei da Arbitragem Voluntária –
          Lei n.º 16/03, de 26 de Julho (LAV ou Regras) –, prevalecendo as
          regras desta cláusula em caso de divergência entre elas e as Regras.
          Se as Regras mudarem após a submissão da ação, os procedimentos em
          vigor quando a ação foi submetida serão aplicáveis.
        </li>
        <li>
          d. A menos que as Partes acordem em contrário, a arbitragem será
          conduzida em língua portuguesa e em Luanda, Angola.
        </li>
        <li>
          e. Se o pedido do Cliente não exceder o montante em Kwanzas
          equivalente a USD 10.000 (dez mil dólares americanos), então a
          arbitragem será conduzida unicamente com base em documentos que as
          Partes submetem ao árbitro, a menos que o Cliente solicite uma
          audiência ou que o árbitro determine que esta é necessária. Se o
          pedido do Cliente exceder o montante em Kwanzas equivalente a USD
          10.000 (dez mil dólares americanos), o direito do Cliente a uma
          audiência será determinado pelas regras da LAV.
        </li>
        <li>
          f. Respeitando as Regras, o árbitro poderá livremente orientar uma
          razoável troca de informações pelas Partes, consistente com a natureza
          expedita da arbitragem.
        </li>
      </ul>
      <h3 style={{ fontSize: " 19px", fontWeight: "bold" }}>
        ee) LEI APLICÁVEL
      </h3>
      <p>
        O Contrato e quaisquer obrigações extracontratuais dele decorrentes ou
        com ele relacionadas serão regidos e interpretados de acordo com as leis
        da República de Angola.
      </p>
    </div>
  );
}
