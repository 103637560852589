import categoriesTypes from './categories.types';

export const addCategoryItem = (nextCategoryItem) => ({
  type: categoriesTypes.ADD_TO_CATEGORY,
  payload: nextCategoryItem
});

export const removeCategoryItem = (categoryItem) => ({
  type: categoriesTypes.REMOVE_CATEGORY_ITEM,
  payload: categoryItem
});

export const setCategories = (categories) => ({
  type: categoriesTypes.SET_CATEGORIES,
  payload: categories
});