import Auth from "@aws-amplify/auth";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import ImagesLoader from "../../../../helpers/ImagesLoader";
import { isModalOpened } from "../../../../redux/auth/auth.actions";
import CategoryButton from "./CategoryButton/CategoryButton";
import "./NavBar.scss";
import NavLinksDrawer from "./NavLinksDrawer/NavLinksDrawer";
import SignInSignUp from "./SignForms/SignInSignUp/SignInSignUp";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../../../config";
import { useTranslation } from "react-i18next";

const NavBar = (props) => {
  const [t] = useTranslation();
  const [isOpenNavDrawer, setisOpenNavDrawer] = useState(false);
  const [modelView, setmodelView] = useState("login");
  const [isOpenModal, setisOpenModal] = useState(false);
  const [activeLink, setactiveLink] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isSticky, setisSticky] = useState(false);
  const [sobreMenu, setsobreMenu] = useState("closed");
  const [cartCount, setcartCount] = useState("");
  const searchInput = React.createRef();
  const menuRef = React.createRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  const reduxStoreData = () => {
    const storeCartItems = reduxState.cart.cartItems;
    const cartCount =
      !!storeCartItems && storeCartItems.length > 0 ? storeCartItems.length : 0;
    const auth = reduxState.auth;
    return {
      cartCount,
      auth,
    };
  };

  const { storeCartCount, auth } = reduxStoreData();
  const credentialsSet = sessionStorage.getItem("number");

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isOpenNavDrawer: false,
  //     modelView: "login",
  //     isOpenModal: false,
  //     activeLink: "",
  //     isLoggedIn: false,
  //     isSticky: false,
  //     sobreMenu: "closed",
  //     cartCount: ''
  //   };
  //   this.searchInput = React.createRef();
  //   this.menuRef = React.createRef();
  // }

  //   componentDidMount() {

  //     try {
  //       console.log(this.state.cartCount,'jjjjjjjjjjjjjjjjjjjjjjjjjjj')

  //       axios
  //         .get(`${baseUrl}/cart/${localStorage.getItem("cid")}`)
  //         .then((res) => {
  //           console.log(res)
  //           const { success, data } = res.data;
  //           if (success) {
  //             this.setState({
  //               cartCount: data.products.length,
  //             });

  //           } else {
  //             console.log("something went wrong");
  //           }
  //         })
  //         .catch((Ex) => {});
  //     } catch (e) {
  //       console.log(e);
  //     }
  //  window.addEventListener("scroll", this.handleScroll);

  //   }

  useEffect(() => {
    let prods = [];
    try {
      axios
        .get(`${baseUrl}/cart/${localStorage.getItem("cid")}`)
        .then((res) => {
          console.log(res);
          const { success, data } = res.data;
          if (success) {
            setcartCount(data.products);
            // this.setState({
            //   cartCount: data.products,
            // });
            console.log(cartCount);
          } else {
            console.log("something went wrong");
          }
        })
        .catch((Ex) => {});
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleScroll = () => {
    const navbar = document.getElementsByClassName("navbar-wrapper");
    const sticky = navbar.offsetTop;
    if (window.pageYOffset >= 140) {
      // navbar.classList.add("sticky-navbar")
      if (!isSticky) {
        setisSticky(true);
        // this.setState({ isSticky: true });
      }
    } else {
      // navbar.classList.remove("sticky");
      if (isSticky) {
        setisSticky(false);
        // this.setState({ isSticky: false });
      }
    }
  };

  //handlers

  const signIn = () => {
    // this.setState({ isOpenModal: !isOpenModal, modelView: 'login', activeLink: "login" })
    if (localStorage.getItem("cid") !== null) {
      Auth.signOut();
      localStorage.clear();
      toast.success(t("Logout successfully"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setisLoggedIn(false);
      // this.setState({ isLoggedIn: false });
    } else dispatch(isModalOpened({ isOpenModal: true, modelView: "login" }));
  };

  const closeModal = () => {
    // this.setState({ isOpenModal: !isOpenModal, activeLink: '' })
    dispatch(isModalOpened({ isOpenModal: false, modelView: "" }));
  };

  const toggleNavDrawer = () => {
    try {
      setisOpenNavDrawer(!isOpenNavDrawer);
      // this.setState({ isOpenNavDrawer: !isOpenNavDrawer });
    } catch (e) {
      console.log(e);
    }
  };

  // on search button click handler
  const handleSearchClick = (event) => {
    let searchValue = "";
    if (
      !!event &&
      ((!!event.key && event.key === "Enter") ||
        (!!event.type && event.type === "click"))
    ) {
      searchValue = searchInput.current.value;
      history.push(`/search-product/query?${searchValue}`);
    } else {
      searchValue = searchInput.current.value;
    }
  };

  const setActiveLinkMethod = (navActiveLink) => {
    try {
      setactiveLink(navActiveLink);
      // this.setState({ activeLink: navActiveLink });
    } catch (e) {
      console.log(e);
    }
  };

  console.log("kkkkkkkkkkkk", cartCount);

  return (
    <>
      <div className={`navbar-wrapper ${isSticky ? "sticky-navbar" : ""}`}>
        <div className={"col col-sm-2 col-md-2 col-lg-2"}>
          <div className={"row"}>
            <div className={"logo-wrapper"}>
              <Link to={"/"}>
                <div
                  onClick={() => setActiveLinkMethod("")}
                  className={"logo"}
                  style={{
                    backgroundImage: `url(${ImagesLoader("logo")})`,
                  }}
                ></div>
              </Link>
            </div>
            {/*end logo*/}

            {/*search bar*/}
            {/*<div className={ 'search-bar-wrapper' }>*/}
            {/*  <div className="search">*/}
            {/*    <div className="input-group">*/}
            {/*      <input*/}
            {/*        ref={ this.searchInput }*/}
            {/*        onKeyDown={ e => this.handleSearchClick(e) }*/}
            {/*        style={ { fontFamily: '\'Sen\', sans-serif' } }*/}
            {/*        type="text"*/}
            {/*        className="form-control"*/}
            {/*        placeholder="Search for a product, Brand etc."*/}
            {/*      />*/}
            {/*      <div className="input-group-append">*/}
            {/*        <button*/}
            {/*          onClick={ e => this.handleSearchClick(e) }*/}
            {/*          style={ { borderColor: '#ced4da', color: '#6c757d' } }*/}
            {/*          className="btn"*/}
            {/*          type="button">*/}
            {/*          <i className="fa fa-search"></i>*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*end search bar*/}
          </div>
        </div>
        <div className={"nav-links-container col col-sm-8 col-md-8 col-lg-8"}>
          <div className={"nav-links-wrapper stroke"}>
            <ul className="nav-links">
              <li className={"nav-links-btn-wrap"}>
                <CategoryButton />
              </li>
              <li className={"nav-links-btn-wrap"}>
                <a
                  onClick={() => setActiveLinkMethod("sellers")}
                  className={"nav-links-btn"}
                  style={{
                    color: `${
                      activeLink === "sellers" ? "rgb(3, 158, 255)" : "inherit"
                    }`,
                  }}
                  href={"https://bayqi-seller-web-app.web.app/"}
                  target={"_blank"}
                >
                  {t("Para Seu Negocio")}
                </a>
              </li>
              <li className={"nav-links-btn-wrap"}>
                <Link
                  onClick={() => setActiveLinkMethod("faqs")}
                  className={"nav-links-btn"}
                  style={{
                    color: `${
                      activeLink === "faqs" ? "rgb(3, 158, 255)" : "inherit"
                    }`,
                  }}
                  to={"/faqs"}
                >
                  {t("Centro de Ajuda")}
                </Link>
              </li>
              <li className={"nav-links-btn-wrap"}>
                <div
                  className={"nav-links-btn"}
                  ref={menuRef}
                  onMouseEnter={() => setsobreMenu("open")}
                >
                  <Link
                    to="/"
                    className={"nav-links-btn"}
                    style={{
                      color: `${
                        activeLink === "sobre" ? "rgb(3, 158, 255)" : "inherit"
                      }`,
                    }}
                  >
                    {t("Sobre BayQi")}
                  </Link>
                  <i className="fa fa-angle-down"></i>
                </div>

                <ControlledMenu
                  state={sobreMenu}
                  anchorRef={menuRef}
                  onMouseLeave={() => setsobreMenu("closed")}
                  onClose={() => setsobreMenu("closed")}
                >
                  <MenuItem onClick={() => history.push("/sobrebayqi")}>
                    {t("Sobre Nós")}
                  </MenuItem>
                  <MenuItem onClick={() => history.push("/baixeapp")}>
                    {t("Baixe o App")}
                  </MenuItem>
                </ControlledMenu>
              </li>

              <li className={"nav-links-btn-wrap"}>
                <Link
                  onClick={() => setActiveLinkMethod("blogs")}
                  className={"nav-links-btn"}
                  style={{
                    color: `${
                      activeLink === "blogs" ? "rgb(3, 158, 255)" : "inherit"
                    }`,
                  }}
                  to={"/blog"}
                >
                  {t("Blog")}
                </Link>
              </li>
            </ul>

            {/*<SignInSignUp*/}
            {/*  screenType={ modelView }*/}
            {/*  isOpenModal={ isOpenModal }*/}
            {/*  closeModal={ () => this.setState({ isOpenModal: !isOpenModal, activeLink: '' }) }*/}
            {/*  isLoggedIn={ () => this.setState({ isLoggedIn: !isLoggedIn }) }*/}
            {/*/>*/}
            <SignInSignUp
              screenType={auth.modelView}
              isOpenModal={auth.isOpenModal}
              closeModal={() => closeModal()}
              isLoggedIn={() => setisLoggedIn(!isLoggedIn)}
            />
          </div>
        </div>
        <div className={"col col-sm-2 col-md-2 col-lg-2"}>
          <div className={"d-flex flex-row flex-wrap"}>
            {/* <div
                onClick={() => this.props.history.push("/cart")}
                // TODO older route was /checkout-process
                className={"cart-wrap"}
              >
                <div className={"d-flex flex-row flex-wrap"}>
                  <i
                    style={{ color: "#5db7fe" }}
                    className="fa fa-2x fa-shopping-bag"
                  ></i>
                  <span className="badge">
                    {localStorage.getItem("length")}
                  </span>
                </div>
              </div> */}
            <Link to="/" className="profile-btn-link">
              <div
                className={"profile-btn"}
                onClick={() => {
                  signIn();
                }}
              >
                <div style={{ margin: "0 1em 0 0" }}>
                  {localStorage.getItem("cid") !== null
                    ? t("Sign Out")
                    : t("Sign In")}
                </div>

                <div>
                  <i className="fa fa-user-circle"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className={"small-navbar-wrapper"}>
        <div className={"logo-wrapper"}>
          <Link to={"/"}>
            <div
              onClick={() => setActiveLinkMethod("")}
              className={"logo"}
              style={{
                backgroundImage: `url(${ImagesLoader("logo")})`,
              }}
            ></div>
          </Link>
        </div>
        <div className={"navbar-hamburger-btn"}>
          <i
            onClick={toggleNavDrawer}
            style={{ fontSize: "1.5em", color: "inherit", cursor: "pointer" }}
            className="fa fa-bars"
          ></i>
        </div>
      </div>

      <NavLinksDrawer
        isOpenNavDrawer={isOpenNavDrawer}
        toggleNavDrawer={toggleNavDrawer}
      />
    </>
  );
};

// const mapStateToProps = (state) => {
//   const cartCount =
//     !!state.cart.cartItems && state.cart.cartItems.length > 0
//       ? state.cart.cartItems.length
//       : 0;
//   const auth = state.auth;
//   return {
//     cartCount,
//     auth,
//   };
// };

export default withRouter(NavBar);
