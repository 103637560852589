import { applyMiddleware, createStore } from 'redux';
import { createLogger }                 from 'redux-logger';
import thunk                            from 'redux-thunk';
import reducers                         from '../redux';
import authTypes                        from '../redux/auth/auth.types';

const logger = createLogger({ collapsed: true, });

const middlewares = process.env.REDUX_LOGGER === '1' ? [thunk, logger] : [thunk, logger];

const initialState = reducers({}, {});

const rootReducer = (state, action) => reducers(action.type === authTypes.SIGNOUT_SUCCESS ? initialState : state, action);

const storeEnhancers = applyMiddleware(...middlewares)



/**
 * @type {Store<any> & {dispatch: any}}
 */
const store = createStore(rootReducer, {}, storeEnhancers);

if (module.hot) {
  module.hot.accept('../redux', () => {
    console.log('reducer replacing');
    const nextReducer = require('../redux').default;
    store.replaceReducer(nextReducer)
  })
}

export { store }
