import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { baseUrl } from "../../../../config";
import { products } from "../../../../DummyData";
import FilteredProducts from "./FilteredProducts/FilteredProducts";
import FilteredTags from "./FilteredTags/FilteredTags";
import "./ResultRightSection.css";

function ResultRightSection({ props }) {
  const [t] = useTranslation();
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // fetch data filtered products
    try {
      axios
        .get(`${baseUrl}/products`)
        .then((productsResponse) => {
          console.log("products rrrrrrrr ", productsResponse);
          this.setState({ products, loading: false });
          setProducts(products);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error.message);
          this.setState({ products: [], loading: false });
          setProducts([]);
          setLoading(false);
        });
    } catch (e) {
      console.log("error: ", e.message);
    }
  }, []);

  const { tags } = props;
  const { searchString } = props.match.params;
  if (loading) {
    return <div>{t("Loading..")}</div>;
  } else {
    if (!!products && products.length > 0) {
      return (
        <>
          <div className={"result-right-sec"}>
            <div className={"result-right-head"}>
              <div className={"result-right-head-title-wrap"}>
                <div className={"result-right-head-title"}>{searchString}</div>
                <div className={"result-right-head-count"}>(121)</div>
              </div>
              <FilteredTags tags={tags} />
            </div>
            <hr />
            <div className={"result-right-filtered-products"}>
              <FilteredProducts products={products} />
            </div>
          </div>
          <div className={"searched-results-pagination"}>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <Link className="page-link" to="/search-product/query">
                    {t("Previous")}
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/search-product/query"}>
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/search-product/query"}>
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/search-product/query"}>
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="/search-product/query">
                    {t("Next")}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>
      );
    } else {
      return <div>{t("No record found")}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  const tags =
    !!state.filters.filters && state.filters.filters.length > 0
      ? state.filters.filters.map((x) => x.value)
      : [];
  return {
    tags,
  };
};

export default withRouter(connect(mapStateToProps)(ResultRightSection));
