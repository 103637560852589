import React, { useState } from "react";
import "./AccountSettings.css";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";
import classnames from "classnames";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
export default function AccountSettings() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          marginTop: "50px",
          padding: "12px 5px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            xl={12}
            lg={12}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Account Settings")}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={{
          marginTop: "2%",
          alignSelf: "center",
        }}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("Administrator Data")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("Safety")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane style={{}} tabId="1">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    {t("Name")}
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    {t("BayQi")}
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    {t("Email")}
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    apoiocliente@bayqi.com
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    {t("Mobile phone")}
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    939725027
                  </span>
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row
                style={{
                  // marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  //   alignSelf: "center",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    {t("Current password")}
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="Amount"
                    //placeholder='Last Name'
                    // type="dropdown"
                    // value={values.lastName}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "1%",
                  display: "flex",
                  flexDirection: "row",
                  //   alignSelf: "center",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    {t("New password")}
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="NewPass"
                    //placeholder='Last Name'
                    // type="dropdown"
                    // value={values.lastName}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "1%",
                  display: "flex",
                  flexDirection: "row",
                  //   alignSelf: "center",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    {t("Confirm password")}
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="ConfirmPass"
                    //placeholder='Last Name'
                    // type="dropdown"
                    // value={values.lastName}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
