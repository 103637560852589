import React, { useEffect, useState } from "react";
import ActiveBlogPage from "./ActiveBlogPage";
import "./BlogPage.scss";
import BlogDetailPage from "./BlogDetailPage";
import NewActiveBlogPage from "./NewActiveBlogPage";
import { blogs } from "../../../DummyData";
import { useTranslation } from "react-i18next";

const BlogPage = () => {
  const [t] = useTranslation();
  const [list, setList] = useState(blogs);
  const [activeTab, setActiveTab] = useState("all");
  const [tabs, setTabs] = useState([
    { label: t("All"), tab: "all" },
    { label: t("Artifacts"), tab: "art" },
    { label: t("News"), tab: "news" },
    { label: t("Magazine"), tab: "mag" },
  ]);
  const [showBlogDetail, setShowBlogDetail] = useState(false);
  const [blogDetail, setBlogDetail] = useState({});

  // state = {
  //   list:blogs,
  //   activeTab: 'all',
  //   tabs: [
  //     { label: "All", tab: 'all' },
  //     { label: "Artifacts", tab: 'art' },
  //     { label: "News", tab: 'news' },
  //     { label: "Magazine", tab: 'mag' },
  //   ],
  //   showBlogDetail:false,
  //   blogDetail:{}
  // }

  useEffect(() => {
    window.scroll(0, 0);
  });

  // componentDidMount() {
  // }

  // active tab
  const currentTab = (tab) => {
    if (tab === "all") {
      setList(blogs);
      setActiveTab(tab);
      setShowBlogDetail(false);
      // this.setState({ list: blogs, activeTab: tab, showBlogDetail: false });
    } else {
      const filteredList = blogs.filter((li) => li.type === tab);
      setList(filteredList);
      setActiveTab(tab);
      setShowBlogDetail(false);
      // this.setState({
      //   list: filteredList,
      //   activeTab: tab,
      //   showBlogDetail: false,
      // });
    }
    // this.setState({activeTab: tab,showBlogDetail:false  })
  };

  const getBlogDetail = (blog) => {
    const BlogDetail = {
      ...blog,
      author: "Jhon David",
      authorBio: "Professional blogger working at Times news paper.",
    };

    setShowBlogDetail(true);
    setBlogDetail(BlogDetail);
    // this.setState({ showBlogDetail: true, blogDetail: BlogDetail });
  };

  return (
    <div className={"blog-page-container"}>
      <div className={"blog-page-tabs-wrap"}>
        <ul className={"blog-page-tabs-ul"}>
          {tabs &&
            tabs.length > 0 &&
            tabs.map((item, idx) => {
              return (
                <li
                  className={`blog-page-tabs-li ${
                    item.tab === activeTab ? "active-tab" : ""
                  }`}
                  key={idx}
                  onClick={() => currentTab(item.tab)}
                >
                  {item.label}
                </li>
              );
            })}
        </ul>
      </div>
      <hr />

      {showBlogDetail ? (
        <BlogDetailPage blog={blogDetail} />
      ) : (
        <NewActiveBlogPage
          activeTab={activeTab}
          list={list}
          getBlogDetail={getBlogDetail}
        />
      )}
    </div>
  );
};

export default BlogPage;

// const BlogDetail = {
//   title:"",
//   detail:"",
//   blogShareLink:"",
//   author:"",
//   authorBio:"",
// }
