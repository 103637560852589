export const existingCategoryItem = ({ prevCategoryItems, nextCategoryItem }) => {
  return prevCategoryItems.find(
    categoryItem => categoryItem.documentID === nextCategoryItem.documentID
  );
};

export const handleAddToCategory = ({ prevCategoryItems, nextCategoryItem }) => {
  const quantityIncrement = 1;
  const categoryItemExists = existingCategoryItem({ prevCategoryItems, nextCategoryItem });

  if (categoryItemExists) {
    return prevCategoryItems.map(categoryItem =>
      categoryItem.documentID === nextCategoryItem.documentID
        ? {
          ...categoryItem,
          quantity: categoryItem.quantity + quantityIncrement
        } : categoryItem
    );
  }

  return [
    ...prevCategoryItems,
    {
      ...nextCategoryItem,
      quantity: quantityIncrement
    }
  ];
};

export const handleRemoveCategoryItem = ({ prevCategoryItems, categoryItemToRemove }) => {
  return prevCategoryItems.filter(item => item.documentID !== categoryItemToRemove.documentID);
}
