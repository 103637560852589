import "./Accordion.css";
import React, { useEffect, useState } from "react";

const Accordion = (props) => {
  const [activeTab, setActiveTab] = useState("");
  // state = {
  //   activeTab: ''
  // }

  // const setActiveTab = (activeTabParam) => {
  //   setActiveTab(activeTabParam);
  //   // this.setState({ activeTab })
  // }
  const { tabData } = props;

  return (
    <div className={"accordion-wrapper"}>
      <ul className={"accordion-list"}>
        {tabData.map((data, key) => {
          return (
            <li key={key} className={"accordion-list__item"}>
              <AccordionItem
                {...data}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const AccordionItem = (props) => {
  const [opened, setOpened] = useState(false);
  // state = {
  //   opened: false
  // }

  useEffect(() => {
    setOpened(false);
  }, [props.title]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.props.title !== prevProps.title) {
  //     this.setState({ opened: false })
  //   }
  // }

  const { title, paragraph, setActiveTab, activeTab, id } = props;

  return (
    <div
      className={`accordion-item ${opened ? "accordion-item--opened" : ""}`}
      onClick={() => {
        setOpened(!opened);
        // this.setState({ opened: !opened });
        setActiveTab(id);
      }}
    >
      <div
        className={`accordion-item__line ${activeTab === id ? "active" : ""}`}
      >
        <div className={"accordion-item__title"}>{title}</div>

        <div className={"accordion-item__icon__wrap"}>
          <i
            style={{
              fontSize: "1em",
              color: "inherit",
              width: "5%",
              fontWeight: "bold",
            }}
            className={`accordion-item__icon fa  ${
              opened ? "fa-times" : "fa-plus"
            }`}
          ></i>
        </div>

        {/*<span className={"accordion-item__icon"}/>*/}
      </div>
      <div className={"accordion-item__inner"}>
        <div className={"accordion-item__content"}>
          <div className={"accordion-item-list-group-wrap"}></div>
          <div className={"accordion-item__paragraph"}>{paragraph}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
