import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ImagesLoader from "../../../helpers/ImagesLoader";

const RightBlogCard = (props) => {
  const [card, setCard] = useState({});
  const [t] = useTranslation();
  // state = {
  //   card: {}
  // }

  useEffect(() => {
    const { card } = props;
    setCard(card);
  }, [JSON.stringify(props.card)]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { card } = this.props;
  //   if (JSON.stringify(card) !== JSON.stringify(prevProps.card)) {
  //     this.setState({ card })
  //   }
  // }

  const { propsCard } = props;
  const { title, description } = propsCard;
  return (
    <div className="card mb-3">
      <img
        style={{ width: "100%", height: "auto" }}
        src={`${ImagesLoader("lv-model")}`}
        alt="..."
      />
      <div className="card-body">
        <h6 className="card-title">Blog title something</h6>
      </div>
    </div>
  );
};

export default RightBlogCard;
