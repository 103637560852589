import NavBar from "../NavBar/NavBar";
import react, { useState } from "react";
import SideDrawer from "../SideDrawer/SideDrawer";
import "../SideDrawer/SideDrawer.css";
function HeaderWithDrawer({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const toggleDrawer = () => setIsOpen(!isOpen);
  return (
    <div style={{ height: "100%" }}>
      <NavBar toggleDrawer={toggleDrawer} />
      <SideDrawer isOpen={isOpen}>{children}</SideDrawer>
    </div>
  );
}

export default HeaderWithDrawer;
