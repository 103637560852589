import { MenuItem, SubMenu, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { userLinks } from "../../../../../DummyData";

import "./CategoryButton.css";

const CategoryButton = () => {
  const [t] = useTranslation();
  const [openDropDown, setopenDropDown] = useState(true);
  const [title, settitle] = useState(t("Shop By Category"));
  const [links, setlinks] = useState([]);
  const [utilizadoresMenu, setutilizadoresMenu] = useState("closed");

  const utilizadoresRef = React.createRef();
  const history = useHistory();

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     openDropDown: true,
  //     title: "Shop By Category",
  //     links: [],
  //     utilizadoresMenu: 'closed'
  //   }
  //   this.utilizadoresRef = React.createRef();
  // }

  useEffect(() => {
    setlinks(userLinks);
  }, []);

  // componentDidMount() {
  //   this.setState({links:userLinks})
  // }

  // handlers

  // category click handler
  const handleClickDropDown = (event) => {
    if (event.type === "click") {
      setopenDropDown(!openDropDown);
      // this.setState({ openDropDown: !this.state.openDropDown })
    } else {
      setopenDropDown(false);
      // this.setState({ openDropDown: false })
    }
  };

  // helpers
  const getCategoriesLinks = () => {
    try {
      if (!!links && links.length > 0) {
        return links.map((link, idx) => {
          return (
            <SubMenu key={idx} label={link.title}>
              {link.links.map((l) => {
                return (
                  <MenuItem onClick={() => history.push(l.link)}>
                    {l.title}
                  </MenuItem>
                );
              })}
            </SubMenu>
          );
        });
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"category-btn-wrapper"}>
      <div
        className={"nav-links-btn"}
        ref={utilizadoresRef}
        onMouseEnter={() => setutilizadoresMenu("open")}
      >
        {t("Utilizadores")}
        <i className="fa fa-angle-down ml-3"></i>
      </div>

      <ControlledMenu
        state={utilizadoresMenu}
        anchorRef={utilizadoresRef}
        onMouseLeave={() => setutilizadoresMenu("closed")}
        onClose={() => setutilizadoresMenu("closed")}
      >
        {links && getCategoriesLinks()}
      </ControlledMenu>
      {/*<Menu  menuButton={<div className={"nav-links-btn"}>Utilizadores <i className="fa fa-angle-down"></i></div>}>*/}
      {/*  { links && this.getCategoriesLinks() }*/}
      {/*</Menu>*/}
    </div>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     categories: state.categories.categories,
//   };
// };

export default withRouter(CategoryButton);
