import authTypes from './auth.types';


export const isModalOpened = (data) => ({
  type: authTypes.IS_MODAL_OPENED,
  payload: data
})

export const signOutRequest = () => ({
  type: authTypes.SIGNOUT_REQUEST,
  payload: null
})

export const signOutSuccess = (flag) => ({
  type: authTypes.SIGNOUT_SUCCESS,
  payload: flag
})

export const signOutFailure = (flag) => ({
  type: authTypes.SIGNOUT_FAILURE,
  payload: flag
})

export const signInRequest = (flag) => ({
  type: authTypes.SIGNIN_REQUEST,
  payload: flag
})

export const signInSuccess = () => ({
  type: authTypes.SIGNIN_SUCCESS
})

export const signInFailure = (flag) => ({
  type: authTypes.SIGNIN_FAILURE,
  payload: flag
})

export const signUpRequest = (flag) => ({
  type: authTypes.SIGNUP_REQUEST,
  payload: flag
})

export const signUpSuccess = (flag) => ({
  type: authTypes.SIGNUP_SUCCESS,
  payload: flag
})

export const signUpFailure = (flag) => ({
  type: authTypes.SIGNUP_FAILURE,
  payload: flag
})