import { combineReducers } from "redux";
import { authReducer } from "./auth/auth.reducer";
import cartReducer from "./Cart/cart.reducer";
import categoriesReducer from "./Categories/categories.reducer";
import filtersReducer from "./Filters/filters.reducer";
import ordersReducer from "./Orders/orders.reducer";
import productsReducer from "./Products/products.reducer";
import userReducer from "./User/user.reducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  products: productsReducer,
  cart: cartReducer,
  orders: ordersReducer,
  categories: categoriesReducer,
  filters: filtersReducer,
});
