import ImagesLoader from "../helpers/ImagesLoader";

// Provinces
const provinces = [
  { value: "Bengo", label: "Bengo" },
  { value: "Benguela", label: "Benguela" },
  { value: "Cabinda", label: "Cabinda" },
  { value: "Cunene", label: "Cunene" },
  { value: "Huambo", label: "Huambo" },
  { value: "Huíla", label: "Huíla" },
  { value: "Kuando Kubango", label: "Kuando Kubango" },
  { value: "Kwanza Norte", label: "Kwanza Norte" },
  { value: "Kwanza Sul", label: "Kwanza Sul" },
  { value: "Luanda", label: "Luanda" },
  { value: "Lunda Norte", label: "Lunda Norte" },
  { value: "Lunda Sul", label: "Lunda Sul" },
  { value: "Malange", label: "Malange" },
  { value: "Moxico", label: "Moxico" },
  { value: "Namibe", label: "Namibe" },
  { value: "Uíge", label: "Uíge" },
  { value: "Zaire", label: "Zaire" },
];

const luandaDelMethods = [
  { value: "Bayqi Normal", label: "Bayqi Normal" },
  { value: "Bayqi Plus", label: "Bayqi Plus" },
  { value: "Bayqi Ponto", label: "Bayqi Ponto" },
];

const notLuandaDelMethod = [
  { value: "BayQi Provinces", label: "BayQi Provinces" },
];

const shoppingMalls = [
  {
    src: `${ImagesLoader("amazon-shopping")}`,
    title: "Amazon Shopping",
    link: "/",
    count: null,
    color: null,
    tag: null,
  },
  {
    src: `${ImagesLoader("cidade-da-china")}`,
    title: "Cidade da china",
    link: "/",
    count: null,
    color: null,
    tag: null,
  },
  {
    src: `${ImagesLoader("mdc-shopping")}`,
    title: "MDC Shopping",
    link: "/",
    count: null,
    color: null,
    tag: null,
  },
  {
    src: `${ImagesLoader("talagona-shopping")}`,
    title: "Talagona Shopping",
    link: "/",
    count: null,
    color: null,
    tag: null,
  },
];

const events = [
  {
    src: `${ImagesLoader("amazon-shopping")}`,
    title: "Amazon Shopping",
    link: "/",
    count: 4,
    color: "orange",
    tag: "New",
  },
  {
    src: `${ImagesLoader("cidade-da-china")}`,
    title: "Cidade da china",
    link: "/",
    count: 7,
    color: "red",
    tag: "New",
  },
];

const categories = [
  {
    id: 1,
    label: "Women's Fashion",
    name: "womenFashion",
    img: `${ImagesLoader("women-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 2,
    label: "Men's Fashion",
    name: "mensFashion",
    img: `${ImagesLoader("mens-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 3,
    label: "Kids Fashion",
    name: "kidsFashion",
    img: `${ImagesLoader("kids-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 4,
    label: "Beauty, Health",
    name: "beautyHealth",
    img: `${ImagesLoader("beauty-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 5,
    label: "Jewelry",
    name: "jewelry",
    img: `${ImagesLoader("jewelry-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 6,
    label: "Phones",
    name: "phones",
    img: `${ImagesLoader("mobiles")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 7,
    label: "Laptops",
    name: "laptops",
    img: `${ImagesLoader("laptops")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 8,
    label: "Consumer Electronics",
    name: "electronics",
    img: `${ImagesLoader("electronics")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 9,
    label: "Watches",
    name: "watches",
    img: `${ImagesLoader("watches")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 10,
    label: "Rings",
    name: "rings",
    img: `${ImagesLoader("jewelry-fashion")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 11,
    label: "Mobiles",
    name: "mobiles",
    img: `${ImagesLoader("mobiles")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 12,
    label: "Computers",
    name: "computers",
    img: `${ImagesLoader("laptops")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 13,
    label: "New Electronics",
    name: "electronics",
    img: `${ImagesLoader("electronics")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 14,
    label: "New Watches",
    name: "watches",
    img: `${ImagesLoader("watches")}`,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
];

const bagItems = [
  {
    id: 1,
    src: `url(${ImagesLoader("cart-item-1")})`,
    name: "Vivo V17 8 GB 128 GB Midnight Ocean",
    soldBy: "Kiaan Marketing",
    price: "100000",
    originalPrice: "200000",
    offerAdded: false,
    saveForLater: false,
  },
  {
    id: 2,
    src: `url(${ImagesLoader("cart-item-2")})`,
    name: "Apple iPhone 11 64 GB Green(Without Earphone & Adapter)",
    soldBy: "Saleskart",
    price: "980000",
    originalPrice: "200000",
    offerAdded: false,
    saveForLater: false,
  },
  {
    id: 3,
    src: `url(${ImagesLoader("cart-item-3")})`,
    name: "Samsung Galaxy M21 4 GB 64 GB Midnight Red",
    soldBy: "Samsung Authorised ",
    price: "12000",
    originalPrice: "200000",
    offerAdded: false,
    saveForLater: false,
  },
];

const products = [
  {
    id: 1,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    // img:`${ ImagesLoader('product2') }`,
    img: ``,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 2,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 3,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 4,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 5,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 6,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 7,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 8,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 9,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 10,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 11,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("product2")}`,
    category: "womenFashion",
    categoryName: "Women's Fashion",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 12,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("electronics")}`,
    category: "electronics",
    categoryName: "Electronics",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 13,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("electronics")}`,
    category: "electronics",
    categoryName: "Electronics",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 14,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("electronics")}`,
    category: "electronics",
    categoryName: "Electronics",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 15,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("electronics")}`,
    category: "electronics",
    categoryName: "Electronics",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 16,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("electronics")}`,
    category: "electronics",
    categoryName: "Electronics",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 17,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("laptops")}`,
    category: "laptops",
    categoryName: "Laptops",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 18,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("laptops")}`,
    category: "laptops",
    categoryName: "Laptops",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 19,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("laptops")}`,
    category: "laptops",
    categoryName: "Laptops",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
  {
    id: 20,
    label: "Girl Fashion Summer Dress Sleeveless",
    discountedPrice: 240,
    discount: 17,
    originalPrice: 100000,
    img: `${ImagesLoader("laptops")}`,
    category: "laptops",
    categoryName: "Laptops",
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
  },
];

const productImages = [
  {
    src: `product-3-detail-1`,
  },
  {
    src: `product-3-detail-2`,
  },
  {
    src: `product-3-detail-3`,
  },
  {
    src: `product-3-detail-4`,
  },
];

const productDetail = {
  name: "Apple iPhone XR 64GB (Black)",
  favourite: true,
  soldBy: "Apple Authorised reseller",
  soldByLogo: "",
  sold: false,
  originalPrice: "4100,000",
  discountedPrice: "3100,000",
  discount: "16",
  ratingRatio: "4.5",
  ratingsCount: "298", //
  reviewsCount: "27", //
  category: "iphone",
  parentCategory: "mobiles",
  images: [
    `${ImagesLoader("product-3-detail-1")}`,
    `${ImagesLoader("product-3-detail-2")}`,
    `${ImagesLoader("product-3-detail-3")}`,
  ],
  highlights: [
    "Camera: 12 MP Rear / 7 MP Front",
    "ROM : 64 GB",
    "Screen Size : 15.49 cm (6.1 inch) Display",
    "Sim Type : Single Sim",
    "A12 Bionic chip processor",
    "iOS 13 compatible",
  ],
  specification: [
    {
      label: "Overview",
      headerDetail:
        "13th Cross, 30th Main Road, Banashankari, 2nd Stage Praça Dom Pedro IV 39, 1100-200 Lisboa, Portugal Customer Care: 01204606060",
      detail: [
        {
          title: "General",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Memory",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Display & Resolution",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Camera & Video Features",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
      ],
    },
    {
      label: "Description",
      headerDetail: null,
      detail: [
        {
          title: "General",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Memory",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Display & Resolution",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
      ],
    },
    {
      label: "Manufacturing Info",
      headerDetail: null,
      detail: [
        {
          title: "Display & Resolution",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Camera & Video Features",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
      ],
    },
    {
      label: "Return Policy",
      headerDetail: null,
      detail: [
        {
          title: "General",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Memory",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
        {
          title: "Camera & Video Features",
          list: [
            {
              label: "Product Type",
              value: "Smart Phones",
            },
            {
              label: "Brand",
              value: "Apple",
            },
            {
              label: "Product Code",
              value: "MOBAPPLE-IPHONESTRI27368446C6345A",
            },
            {
              label: "Product Detail",
              value:
                "The iPhone XR is made from aero-grade aluminum material and comes with a IP67 certification for water and dust resistance. The phone comes with a 6.1-inch Liquid Retina LCD display with 1792 x 828 pixels with 328ppi pixel density. The iPhone XR comes with a single camera lens with a 12-megapixel wide-angle lens with f/1.8 aperture, optical image stabilization, 1.4-micron pixel size and 6-element lens",
            },
            {
              label: "Processor",
              value: "Hexa core (Apple A12 Bionic)",
            },
            {
              label: "Operating System",
              value: "iOS",
            },
          ],
        },
      ],
    },
  ],
};

const faqsTopics = [
  {
    id: 1,
    title: "BayQi",
    detail: [
      {
        id: 11,
        title: "O que é o BayQi?",
        paragraph:
          "BayQi é uma empresa angolana de tecnologia, especializada em sistema de pagamentos digital, comércio eletrônico e serviços financeiros. BayQi esta autorizada pelo Banco Nacional como uma Instituição de Pagamentos através da sociedade PagaQi - Sociedade Prestadora de Serviços de Pagamentos com o número de registro 423 e sede registrada no endereço Rua Feliciano Castilho, N 218/220, Vila Alice, Luanda – Angola",
      },
      {
        id: 12,
        title: "Quais As Funcionalidades Que A App BayQi Oferece?",
        paragraph:
          "Com BayQi poderá pagar tudo que deseja com o seu telemóvel, além de receber pagamentos, enviar dinheiro, pagar diversos serviços (recargas, mensalidades, etc) e pagar em estabelecimentos como: cantinas, restaurantes, supermercados, boutiques, feiras.",
      },
      {
        id: 13,
        title: "Como funciona?",
        paragraph:
          "Para pagar terá que baixar o aplicativo BayQi (Play Store/Apple Store), criar uma conta adicionar dinheiro na sua conta através de Multicaixa Express, Transferência Bancária, Internet Banking.",
      },
    ],
  },
  {
    id: 2,
    title: "Tarifa ",
    detail: [
      {
        id: 21,
        title: "BayQi Tem Tarifa?",
        paragraph:
          "Baixar o aplicativo BayQi é grátis. Usar BayQi é grátis, não há anuidade, mensalidade ou qualquer taxa de adesão.",
      },
      {
        id: 22,
        title: "Taxa Carregamento BayQi e-Wallet",
        paragraph:
          "Será aplicada uma taxa sempre que você carregar sua BayQi e-Wallet por meio de Multicaixa Express, Internet Banking ou ATM. Essa taxa varia de acordo com o valor do carregamento, ou seja, o montante que você está inserindo em sua carteira.",
      },
      {
        id: 23,
        title: "Taxa de Processamento Multicaixa Express",
        paragraph:
          "A taxa de processamento do Multicaixa Express é aplicada quando você efetua um pagamento por meio do Multicaixa Express. Essa taxa varia de acordo com o valor da transação, ou seja, o montante que você está pagando por transação.",
      },
      {
        id: 24,
        title: "Transferir Dinheiro da Carteira para Banco é Grátis?",
        paragraph:
          "Sempre que solicitar uma transferência da Carteira BayQi para o seu banco será aplicado uma taxa por cada transferência. ",
      },
    ],
  },
  {
    id: 3,
    title: "Enviar e Solicitar Dinheiro",
    detail: [
      {
        id: 31,
        title: "Como funciona Enviar ou Solicitar dinheiro?",
        paragraph: (
          <div>
            <p>
              Com a opção Enviar ou Solicitar dinheiro, da secção Amigos poderá
              receber ou enviar dinheiro na hora.
            </p>
            <ul>
              <li>
                <p>Selecciona o montante que deseja enviar ou solicitar</p>
              </li>
              <li>
                <p>
                  Selecciona na lista de contactos a quem deseja enviar ou
                  solicitar ou pode adicionar um novo contacto.
                </p>
              </li>
              <li>
                <p>Insira o seu código pin </p>
              </li>
            </ul>
          </div>
        ),
      },
      {
        id: 32,
        title: "Quanto tempo leva para receber o dinheiro?",
        paragraph:
          "O envio ou solicitação são transacções automáticas, sendo aceite poderá estar disponível na hora. ",
      },
      {
        id: 33,
        title: "Posso receber dinheiro de quem não tem carteira BayQi?",
        paragraph:
          "Também poderá receber dinheiro de alguém que não tinha conta na BayQi, caso o mesmo queira enviar dinheiro através de um meio de pagamento, neste caso terá que indicar o seu número de conta",
      },
      {
        id: 34,
        title: "O que é PagaQi?",
        paragraph:
          "PagaQi permite que solicite a um amigo pagar a conta caso não tenha dinheiro na conta e-wallet BayQi.",
      },
    ],
  },
  {
    id: 4,
    title: "Código de Entrega e Resgate",
    detail: [
      {
        id: 41,
        title: "O que é o Código de Entrega? ",
        paragraph:
          "O Código de Entrega aparecerá sempre que efectuar uma compra dentro da BayQi ou pagar com Link, a transacção irá gerar um código que terá que entregar ao Entregador no acto da entrega da encomenda.",
      },
      {
        id: 42,
        title:
          "Caso a minha encomenda não chegue, o que acontece ao Código de Entrega?",
        paragraph:
          "Se a sua encomenda não chegar o Código de Entrega irá desaparecer dando lugar a opção Reembolso para que possa resgatar o seu dinheiro.\n",
      },
      {
        id: 43,
        title: "Quanto tempo leva para Resgatar o meu dinheiro?",
        paragraph:
          "Após fazer o pedido a equipa BayQi irá verificar a situação, o seu pedido ao ser aceite o valor retornará a sua conta automaticamente.",
      },
    ],
  },
  {
    id: 5,
    title: "Link Pagamento",
    detail: [
      {
        id: 51,
        title: "Como uso um link de pagamento?",
        paragraph:
          "Um link de pagamento é rápido e seguro, que te permite pagar seus produtos e serviços pelo WhatsApp, Facebook, Instagram, e-mail ou qualquer rede social. O vendedor irá partilhar um link de pagamento, basta abri-lo, escolher o tipo de entrega e pagar!",
      },
    ],
  },
  {
    id: 6,
    title: "Código QR",
    detail: [
      {
        id: 61,
        title: "Código QR?",
        paragraph:
          "Código QR é um código de barras bidimensional que pode ser facilmente escaneado usando o seu smartphone.",
      },
      {
        id: 62,
        title: "Como funciona o pagamento por Código QR BayQi? ",
        paragraph:
          "Código QR BayQi é um código único vinculado à conta da BayQi de um vendedor que permite com que possa efectuar pagamentos presencial.",
      },
    ],
  },
  {
    id: 7,
    title: "Segurança",
    detail: [
      {
        id: 71,
        title: "BayQi é seguro?",
        paragraph:
          "Extremamente seguro!\n" +
          "Os seus dados são privados, todas as informações recebidas pela BayQi são criptografadas e armazenadas em servidores seguros. Seguimos todas as normas e padrões de segurança de dados nacionais e internacionais.  Todo sistema da BayQi foi elaborado para garantir a máxima segurança ao criar a sua conta solicitamos que crie o seu PIN, sendo necessário para realização de todos os pagamentos.\n",
      },
      {
        id: 72,
        title: "Não lembro dos meus dados para fazer login?",
        paragraph:
          "Caso esteja nesta situação terá que recuperar o acesso a sua conta, confirmando algumas informações que ajudaram a ter acesso. Abra a app, escolha a opção recuperar/esquece senha e siga os passos que iremos indicar",
      },
      {
        id: 73,
        title: "Para quê serve a autorização de dispositivo?",
        paragraph:
          "Sempre que aceder a sua conta num telemóvel que não seja seu, BayQi irá pedir uma confirmação para autentificá-lo(a).",
      },
      {
        id: 74,
        title:
          "Quais são as principais dicas de segurança para minha conta BayQi?",
        paragraph: (
          <div>
            <ul>
              <li>
                <p>Não partilhe a sua conta BayQi com outras pessoas. </p>
              </li>
              <li>
                <p>
                  Não partilhe os seus dados pessoais, código pin ou senha.{" "}
                </p>
              </li>
              <li>
                <p>
                  Coloque sempre uma senha difícil, não aconselhamos a usar
                  datas de nascimento ou aniversário.
                </p>
              </li>
              <li>
                <p>Não use a mesma senha para todas as suas contas</p>
              </li>
            </ul>
          </div>
        ),
      },
      {
        id: 75,
        title: "Desconfio que a minha conta foi invadida. O que eu faço?",
        paragraph:
          "Nesta situação terá que contactar o mais rápido possivel a BayQi através da linha de apoio em  +244 944 090 618 ou pelo whatsapp BayQi,  que iremos analisar de forma completa a segurança da sua conta ou ajudar a recuperar a mesma.",
      },
      {
        id: 76,
        title: "O que fazer em caso de perda ou roubo do telemóvel?",
        paragraph:
          "BayQi assegura a protecção da sua carteira, pois sem o código pin não será permitido fazer transacções. Neste caso aconselhamos a contactar de imediato a BayQi através da linha de apoio em +244 944 090 618 ou pela linha de apoio no  whatsapp BayQi  +244 939 725 027  para ajudarmos o mais breve possível.",
      },
    ],
  },
];

const policies = [
  {
    id: 1,
    title: "DEFINIÇÕES",
    paragraph: (
      <div>
        <p>
          Os termos “dados pessoais” “tratamento”, “responsável pelo
          tratamento”, presentes na presente política de privacidade
          (“Política”) correspondem às definições previstas na Lei n.º 22/11 de
          17 de Junho. Relembramos que a presente Política deve ser lida e
          interpretada em conjunto com os Termos e Condições do Serviço. Em tudo
          o que não se dispuser em contrário e expressamente na presente
          Política, deverão considerar-se as definições constantes dos Termos e
          Condições.
        </p>
        <p>Em particular, recordamos as seguintes definições:</p>
        <p>
          {" "}
          A. Contrato – a subscrição dos Termos e Condições pelo Utilizador,
          tornando-o assim num Utilizador registado; B. Serviço – uma plataforma
          de comércio eletrónico que permite aos utilizadores acederem a uma
          grande diversidade de produtos, podendo obtê-los através do respetivo
          pagamento; C. Plataforma – o Site ou a Aplicação (disponível nas lojas
          Apple Store ou Google Play para smartphones, tablets ou quaisquer
          outros dispositivos smart compatíveis com a mesma) que permitem aceder
          ao Serviço; D. Conteúdos – todos os ficheiros, dados, informações,
          textos, ou outros que o utilizador enviar, carregar, reencaminhar,
          publicar ou, por qualquer outro modo, transmitir na Plataforma.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "RESPONSÁVEL PELO TRATAMENTO",
    paragraph: (
      <div>
        <p>
          O responsável pelo tratamento dos dados é a Empresa, cuja designação
          social é BayQi – Comércio & Serviços, Lda.,registada na Conservatória
          do Registo Comercial de Luanda sob o número 476-19, NIF 5000168173
        </p>
        <p>
          Pode contactar a Empresa através de apoiocliente@bayqi.com ou pelo
          contacto +244 944 090 618
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "QUE DADOS PESSOAIS SÃO TRATADOS?",
    paragraph: (
      <div>
        <p>
          Caso seja um Utilizador convidado, apenas trataremos cookies nos
          termos que nos tiver autorizado ao aceder à nossa Política de Cookies.
          Em particular, e quando nos autorizar expressamente, podemos utilizar
          dados sobre a sua localização
        </p>
        <p>
          Caso seja um Utilizador registado, para além dos cookies nos termos
          acima descritos, no âmbito do seu registo, trataremos obrigatoriamente
          o seu [nome, apelido, data de nascimento e e-mail].
        </p>
        <p>
          Iremos tratar também todos os Conteúdos que partilhar connosco através
          da Plataforma.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    title: "COM QUE FINALIDADES TRATAMOS OS SEUS DADOS?",
    paragraph: (
      <div>
        <p>Execução e gestão do contrato com a Empresa </p>
        <p>
          Ao subscrever os Termos e Condições, o Utilizador está a formalizar um
          contrato com a Empresa na qualidade de Utilizador Registado da
          Plataforma. A Empresa trata, por isso, os dados pessoais dos
          Utilizadores estritamente necessários à gestão da relação contratual
          entre o Utilizador e a Empresa, para que este possa aceder à
          Plataforma e ao Serviço por ela disponibilizado, prestar
          esclarecimentos, gerir os contactos recebidos, verificar a identidade,
          processar o registo como Utilizador, detetar, investigar, prevenir
          violações de acordos relativamente as leis vigentes ou na política
          aplicável ao Website.
        </p>
        <p>
          Caso não disponibilize os seus dados necessários à celebração e
          execução do seu contrato com a Empresa, o mesmo não poderá
          concretizar-se e, nessa medida, não poderá navegar na Plataforma como
          Utilizador registado ou beneficiar das vantagens associadas a esse
          registo.
        </p>
        <p>Cumprimento de obrigações legais </p>
        <p>
          Os seus dados poderão ser tratados no âmbito do cumprimento de
          obrigações legais, e bem assim quando a Empresa for judicial ou
          administrativamente notificada para o efeito
        </p>
        <p>
          <b>Consentimento</b>
        </p>
        <ul>
          <li>
            <p>
              Com base no seu consentimento, a Empresa poderá enviar-lhe
              comunicações sobre eventos, promoções e campanhas da Empresa e/ou
              de parceiros comerciais desta. Poderá ainda a Empresa, também
              mediante o seu consentimento, segmentar a publicidade que lhe é
              endereçada através da Plataforma de acordo com o seu perfil,
              interesses e Conteúdos que tenha publicado ou comentado
              recentemente. Recordamos que poderá revogar a todo o tempo o seu
              consentimento através dos contactos acima identificados.
            </p>
          </li>
          <li>
            <p>
              O consentimento poderá ser recolhido através da Política de
              Cookies (no caso da segmentação de publicidade conforme acima
              descrito). Recordamos que pode revogar os seus consentimentos, a
              todo o tempo e sem qualquer necessidade de apresentar um motivo
              justificativo para o efeito enviando uma comunicação para o nosso
              email de contacto acima identificado
            </p>
          </li>
        </ul>
        <p>
          <b>Interesse legítimo</b>
        </p>
        <ul>
          <li>
            <p>
              A Empresa poderá, ainda, tratar os seus dados pessoais com base
              nos interesses legítimos da Empresa ou de terceiros.{" "}
            </p>
          </li>
          <li>
            <p>
              Os cookies essenciais e de funcionalidade, por exemplo, são
              ficheiros que permitem o bom funcionamento da Plataforma e de
              todas as aplicações associadas e, nessa medida, são utilizados
              considerando o interesse legítimo da Empresa em manter a
              Plataforma a funcionar de forma adequada.
            </p>
          </li>
          <li>
            <p>
              Sempre que a Empresa necessitar de efectuar atualizações ou
              melhorias na Plataforma, poderá ter de aceder à mesma e,
              acessoriamente, ter acesso aos seus dados pessoais, incluindo a
              cookies e a dados de registo na Plataforma.
            </p>
          </li>
          <li>
            <p>
              Quando for um Utilizador registado, os seus dados pessoais podem,
              ainda, ser tratados no âmbito de prospeções de mercado para
              aquisição total ou parcial da Empresa ou da Plataforma por
              terceiros, por via de aumento de capital ou através da transmissão
              de participações sociais.
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 5,
    title: "ONDE TRATAMOS OS SEUS DADOS?",
    paragraph: (
      <div>
        <p>
          A Empresa poderá proceder a transferências de dados pessoais para fora
          do território angolano, designadamente para efeitos de arquivo de
          informação em cloud. No caso de ocorrer uma transferência
          internacional, será para um país que assegure um nível de protecção
          adequado. A transferência internacional de dados para um país que não
          assegure um nível de protecção adequado está sujeita a autorização da
          Agência de Protecção de Dados, a qual só poderá ser concedida mediante
          verificação dos requisitos constantes na lei.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    title: "OS SEUS DADOS PESSOAIS ESTÃO SEGUROS?",
    paragraph: (
      <div>
        <p>
          Usamos diversas tecnologias e procedimentos de segurança com o
          objetivo de evitar perdas, uso indevido, acesso não autorizado ou
          divulgação de informações. Por exemplo, usamos tecnologia de
          criptografia (como SSL) para proteger determinadas informações
          confidenciais (como dados de localização) fornecidas pelo Utilizador à
          Empresa.
        </p>
        <p>
          Saiba que, apesar dos nossos esforços, nenhuma medida de segurança de
          dados pode garantir 100% de segurança em todos os momentos.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    title: "QUAIS OS PRAZOS DE CONSERVAÇÃO DOS SEUS DADOS?",
    paragraph: (
      <div>
        <p>
          Todos os dados pessoais serão conservados de acordo com as normas
          legais em vigor para garantia do cumprimento de obrigações legais pela
          Empresa ou para que esta possa garantir os seus direitos de defesa, no
          âmbito de qualquer acção judicial
        </p>
        <p>Os cookies serão conservados dentro dos seguintes períodos:</p>
        <ul>
          <li>
            <p>
              Cookies essenciais: permitem ao website memorizar opções definidas
              pelo utilizador (ex.: páginas personalizadas ou mudanças ao nível
              do tamanho do texto), possibilitando também funcionalidades como
              comentar num blog{" "}
            </p>
          </li>
          <li>
            <p>
              Cookies de funcionalidade: Guardam as preferências do utilizador
              relativamente à utilização do site, para que não seja necessário
              voltar a configurar o site cada vez que o visita.{" "}
            </p>
          </li>
          <li>
            <p>
              Cookies de sessão: permanecem no seu browser apenas durante a
              visita, desaparecendo assim que sair do website.
            </p>
          </li>
          <li>
            <p>
              Cookies de remarketing: até 12 (doze) meses após a recolha ou até
              à revogação do seu consentimento, consoante o que acontecer
              primeiro.
            </p>
          </li>
          <li>
            <p>
              Relativamente aos dados de registo, e sem prejuízo do que se
              encontre especificamente previsto na legislação aplicável, todos
              os dados pessoais que o Utilizador nos disponibilizar, incluindo
              conteúdos, publicações e comentários, poderão ser conservados
              enquanto o Utilizador mantiver o seu Contrato activo com a
              Empresa. No entanto, a Empresa não tem qualquer obrigação de
              conservação destes dados nem assume qualquer obrigação de
              conservação dos mesmos ou de back up. Todos os registos serão
              apagados se o Utilizador não aceder à Plataforma por período
              superior a 12 (doze) meses, excepto no caso de a Empresa entender
              que os mesmos são necessários para o exercício de um direito ou de
              defesa, no âmbito de qualquer acção judicial.{" "}
            </p>
          </li>
          <li>
            <p>
              Os dados relativos a conteúdos podem ser conservados durante o
              período de vigência do Contrato. Após a cessação do mesmo, poderão
              ser conservados pelo prazo máximo de 12 meses ou até que o
              Utilizador exija o apagamento dos mesmos, nos termos legalmente
              admitidos.
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 8,
    title: "A SUA INFORMAÇÃO SERÁ PARTILHADA COM TERCEIROS?",
    paragraph: (
      <div>
        <p>
          Poderemos partilhar os seus dados pessoais com empresas que prestam
          serviços em nosso nome ou para os quais prestamos serviços. Serão
          sempre terceiros que garantam o cumprimento das medidas técnicas e
          organizativas adequadas e que cumprem o dever de confidencialidade,
          tais como, por exemplo, prestadores de serviços de prevenção de
          fraudes, gestão da base de dados, promoções, marketing, atendimento ao
          cliente, serviços de tecnologia, alertas de produtos, serviços de
          pagamento, entidades com as quais a Empresa mantém acordos comerciais,
          incluindo sites de comerciantes, comerciantes on-line, bancos,
          associações de cartões, redes de pagamento ou instituições
          compradoras, envolvidas no processamento de pagamentos através dos
          Serviços, seguradoras, autoridades governamentais e reguladoras ou
          quaisquer outras organizações às quais a Empresa ou prestadores de
          serviços estejam legalmente obrigados.
        </p>
        <p>
          {" "}
          Apenas serão fornecidos os dados pessoais necessários para a prestação
          dos serviços em causa.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    title: "QUAIS SÃO OS SEUS DIREITOS?",
    paragraph: (
      <div>
        <p>A qualquer momento, pode solicitar-nos: </p>
        <ul>
          <li>
            <p>O acesso aos dados pessoais que mantemos sobre si; </p>
          </li>
          <li>
            <p>
              A rectificação dos dados pessoais, caso estejam inexactos ou
              incompletos;{" "}
            </p>
          </li>
          <li>
            <p>O apagamento dos seus dados pessoais; </p>
          </li>
          <li>
            <p>A oposição ao tratamento, em determinadas situações;</p>
          </li>
        </ul>
        <p>
          {" "}
          Os seus pedidos serão tratados com especial cuidado, por forma a que
          possamos assegurar a eficácia dos seus direitos. Poderá ser-lhe pedido
          que faça prova da sua identidade, de modo a assegurar que a partilha
          dos dados pessoais é apenas efectuada com o seu titular.{" "}
        </p>
        <p>
          Deve ter presente que, em certos casos (por exemplo, devido a
          exigências legais), o seu pedido poderá não ser imediatamente
          satisfeito.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    title: "UTILIZAMOS COOKIES E TECNOLOGIA SEMELHANTE?",
    paragraph: (
      <div>
        <p>Por favor, consulte a nossa Política de Cookies.</p>
      </div>
    ),
  },
  {
    id: 11,
    title: "TEM ALGUMA DÚVIDA?",
    paragraph: (
      <div>
        <p>
          Caso permaneça com alguma dúvida relativamente ao tratamento dos seus
          dados pessoais, ou pretenda exercer algum dos direitos acima
          referidos, por favor contacte-nos através do endereço e-mail
          apoiocliente@bayqi.com{" "}
        </p>
      </div>
    ),
  },
];

const termsConditions = [
  {
    id: 1,
    title: "ÂMBITO DO SERVIÇO",
    paragraph: (
      <div>
        <p>
          O Serviço atua como uma plataforma de comércio eletrónico que permite
          aos utilizadores acederem a uma grande diversidade de produtos,
          podendo obtê-los através do respetivo pagamento.{" "}
        </p>
        <p>
          A Plataforma atua ainda como intermediário entre os seus utilizadores
          e parceiros de negócio de vários tipos de serviços (serviços de
          atendimento de pedidos de produtos ou serviços, transporte, entrega de
          pacotes, envio de correspondência por e-mail, assistência de
          marketing, processamento de pagamentos, transmissão de conteúdo,
          pontuação, avaliação e gestão de risco de crédito e atendimento ao
          cliente, etc.).{" "}
        </p>
        <p>
          Todas as compras de produtos físicos são feitas de acordo com um
          contrato de expedição. Tal significa que a posse e o risco de perda de
          tais produtos fica por conta do Utilizador após a entrega à
          transportadora.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "CONTEÚDOS",
    paragraph: (
      <div>
        <p>
          Quando o Utilizador enviar, carregar, por qualquer outro modo,
          transmitir dados, informações, ou outros (“Conteúdos”),{" "}
        </p>
        <p>concorda que</p>
        <ul>
          <li>
            <p>
              continuará a ser o exclusivo responsável pelos Conteúdos
              publicados na Plataforma
            </p>
          </li>
          <li>
            <p>
              os Conteúdos não serão vendidos pela Empresa nem promovidos por
              qualquer forma, salvo acordo prévio e expresso da Empresa nesse
              sentido
            </p>
          </li>
          <li>
            <p>
              os Conteúdos não violam direitos de terceiros de qualquer tipo e
              em qualquer jurisdição.
            </p>
          </li>
        </ul>
        <p>
          Não é permitido o uso da Plataforma ou do Serviço para qualquer
          propósito ou finalidade ilegal, ilegítima ou imoral, ou para qualquer
          outro âmbito não previsto nos presentes Termos. O Utilizador fica
          designadamente proibido de, sem limitar, utilizar o Serviço de forma a{" "}
        </p>
        <ul>
          <li>
            <p>
              violar os direitos de terceiros, incluindo direitos de privacidade
              ou direitos de propriedade intelectual
            </p>
          </li>
          <li>
            <p>
              publicar conteúdos de qualquer tipo que sejam obscenos, abusivos,
              assustadores, difamatórios, violentos, ilegais, discriminatórios,
              racistas, sexistas, chantagistas ou que incentivem à prática de
              comportamentos ilegais ou a sentimentos de ódio ou de repúdio (de
              acordo com o que for determinado, a todo o tempo, a critério
              exclusivo da Empresa)
            </p>
          </li>
          <li>
            <p>
              publicar conteúdos de qualquer tipo que não estejam em
              conformidade com outras políticas e instruções da Empresa
            </p>
          </li>
          <li>
            <p>
              introduzir vírus ou outro software malicioso que possam afetar a
              Plataforma ou o Serviço que ela disponibiliza.
            </p>
          </li>
        </ul>
        <p>
          Caso o Serviço permita que o Utilizador partilhe a sua opinião ou
          avaliação de um determinado parceiro ou serviço, o Utilizador declara
          e garante que todas as suas opiniões e avaliações são genuínas e
          verdadeiras, e que representam sempre a sua experiência pessoal. Mais,
          declara que em caso algum receberá pagamentos ou quaisquer outros
          tipos de incentivos ou benefícios dos parceiros ou de terceiros para
          divulgar a sua opinião ou avaliação.
        </p>
        <p>
          A Empresa reserva o direito de bloquear, suspender ou remover
          quaisquer Conteúdos que considere, a todo o tempo e a seu exclusivo
          critério, que não respeitam os presentes Termos ou que de alguma
          maneira violam (ou podem violar) direitos de terceiros ou disposições
          legais aplicáveis.
        </p>
        <p>
          Em momento algum a Empresa garante um serviço de back up dos
          conteúdos, não podendo o Utilizador reclamar qualquer direito ou
          responsabilidade sobre a Empresa a esse respeito. A Empresa pode, a
          seu exclusivo critério, apagar temporária ou definitivamente quaisquer
          Conteúdos.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "ACESSO AO SERVIÇOS ",
    paragraph: (
      <div>
        <p>Registo</p>
        <ul>
          <li>
            <p>
              Como Utilizador convidado: será permitida a navegação pela
              Plataforma, bem como selecionar o produto desejado.
            </p>
          </li>
          <li>
            <p>
              Como Utilizador registado: será permitida a navegação pela
              Plataforma, aceder a áreas reservadas, selecionar o produto
              desejado e proceder à sua compra, nos termos descritos na presente
              Cláusula.{" "}
            </p>
          </li>
          <li>
            <p>
              Como Vendedor: para utilizar o serviço como Vendedor, deverá fazer
              o download da aplicação BayQi disponível via Android ou IOS. A
              informação deve ser completa, precisa e atualizada com
              regularidade. Deve registar-se para fins comerciais, conforme
              descrito aquando da entrada/registo da aplicação BayQi. Se a
              natureza ou tipo de negócio, ou tipo de produto e serviço for
              alterado, o Vendedor deverá enviar uma comunicação escrita
              previamente para o nosso endereço de correio eletrónico
              apoiocliente@bayqi.com O Vendedor responsabiliza-se perante a
              Empresa por todas as perdas e prejuízos decorrentes da falha de
              comunicação
            </p>
          </li>
          <li>
            <p>
              Caso seja utilizador registado, o Utilizador terá de fornecer
              informação pessoal, designadamente um endereço de e-mail válido,
              nome e apelido e número de telefone. O Utilizador declara que a
              informação fornecida é real, legítima e legal, e reconhece que
              será responsável exclusivo por qualquer informação falsa ou
              imprópria que disponibilizar, bem como pelo seu uso ilícito de
              informação pessoal de terceiros.
            </p>
          </li>
          <li>
            <p>
              Cada registo é pessoal e intransmissível, salvo o disposto nos
              presentes Termos.
            </p>
          </li>
          <li>
            <p>
              O Utilizador garante ainda que manterá toda a informação
              relacionada com o seu registo correta e atualizada a todo o tempo.
              É também da responsabilidade do Utilizador criar e manter a sua
              senha de acesso confidencial e inacessível a terceiros. Caso
              suspeite que a sua senha de acesso possa ter sido comprometida,
              por favor contacte a Empresa através dos contactos
              disponibilizados no final dos Termos.{" "}
            </p>
          </li>
          <li>
            <p>
              O registo do Utilizador não representa de modo algum uma
              propriedade do mesmo a favor do Utilizador. Na extensão máxima
              permitida por lei, todos os direitos relativos ao registo e
              acessos permitidos por esse registo pertencem exclusivamente à
              Empresa.
            </p>
          </li>
          <li>
            <p>
              O acesso ao Serviço carece de ligação à Internet. O Serviço não
              está disponível em modo offline. Nessa medida, tenha presente que
              podem aplicar-se tarifas de uso em função do seu operador de redes
              móveis (dados móveis).{" "}
            </p>
          </li>
          <li>
            <p>
              A Aplicação pode ser periodicamente sujeita a atualizações. Para
              evitar consumos excessivos, sugerimos que programe o seu
              smartphone, tablet ou qualquer outro dispositivo smart compatível
              com a aplicação a apenas permitir a instalação de atualizações
              quando estiver ligado a redes wi-fi ou a conexões fixas. A Empresa
              não está, no entanto, obrigada a disponibilizar quaisquer
              atualizações ou correções à Plataforma
            </p>
          </li>
          <li>
            <p>
              O acesso à Plataforma poder requerer a utilização de browsers e de
              sistemas operativos atualizados. A Empresa não assume qualquer
              responsabilidade pela impossibilidade de atualização do seu
              browser ou sistema operativo, designadamente, mas não limitando,
              quando o seu smartphone, tablet ou qualquer outro dispositivo
              smart compatível deixe de ser compatível com as atualizações mais
              recentes dos browsers ou dos sistemas operativos.seu browser ou
              sistema operativo, designadamente, mas não limitando, quando o seu
              smartphone, tablet ou qualquer outro dispositivo smart compatível
              deixe de ser compatível com as atualizações mais recentes dos
              browsers ou dos sistemas operativos.
            </p>
          </li>
          <li>
            <p>
              Por favor, tenha presente que, por razões legais ou operativas,
              algumas funcionalidades do Serviço poderão não estar disponíveis
              em todos os países onde a Plataforma esteja disponível. Para mais
              informações sobre a disponibilidade do Serviço, contacte-nos
              através de apoio ao cliente em +244 944 090 618 ou
              apoioclientel@bayqi.com{" "}
            </p>
          </li>
        </ul>

        <p>Conta de Utilizador</p>
        <ul>
          <li>
            <p>
              Depois de concluído o registo, o Utilizador tornar-se-á titular de
              uma conta (“Conta de Utilizador”) cujo acesso será efetuado
              através do endereço de correio eletrónico indicado e da senha de
              acesso definida.{" "}
            </p>
          </li>
          <li>
            <p>
              O Utilizador será o único responsável pelas operações efetuadas
              através da sua Conta de Utilizador, devendo assegurar a
              confidencialidade da mesma.{" "}
            </p>
          </li>
          <li>
            <p>
              A Empresa não se responsabiliza por eventuais danos decorrentes da
              utilização indevida da senha de acesso definida e dos restantes
              dados utilizados pelo Utilizador para aceder à sua conta.
            </p>
          </li>
          <li>
            <p>
              Para utilizar determinados Serviços, poderá ser necessário estar
              conectado através da Conta de Utilizador e ter um método de
              pagamento válido associado. No caso de ocorrer um problema ao
              cobrar através da forma de pagamento selecionada poderemos cobrar.
            </p>
          </li>
          <li>
            <p>
              Através da sua Conta de Utilizador, o Utilizador poderá aceder aos
              seus dados de transações de pagamento executadas e outras
              informações relacionadas com o histórico de transações.
            </p>
          </li>
          <li>
            <p>
              A qualquer momento, o Utilizador poderá solicitar o envio das
              informações detalhadas sobre os seus pagamentos, bem como sobre o
              histórico de transações, por e-mail.{" "}
            </p>
          </li>
          <li>
            <p>
              Poderá igualmente aceder a estas informações, através da secção
              "Histórico" disponível na Conta de Utilizador, descarregando o
              respetivo relatório. A secção "Histórico" disponibiliza ainda
              todas as Tarifas incorridas e outros montantes cobrados na sua
              Conta no período relevante.{" "}
            </p>
          </li>
          <li>
            <p>
              O "Histórico" apenas é atualizado e disponibilizado no caso de
              haver atividade na sua Conta.
            </p>
          </li>
          <li>
            <p>
              A Empresa reserva-se o direito de cobrar uma Tarifa pelo
              fornecimento de informações adicionais quando tal exceda o
              disposto nos presentes Termos.{" "}
            </p>
          </li>
          <li>
            <p>
              A informação sobre os detalhes de cada transação, estará
              disponível na plataforma durante, pelo menos, 13 meses.{" "}
            </p>
          </li>
          <li>
            <p>
              O Utilizador compromete-se e aceita fazer um esforço por aceder à
              informação das transações através do Histórico da Conta, em vez de
              solicitar tal informação através do pedido de envio de extratos
              periódicos por e-mail.
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 4,
    title: "CONTEÚDOS PUBLICITÁRIOS",
    paragraph: (
      <div>
        <p>
          A Plataforma irá incluir anúncios, materiais promocionais e de
          publicidade da própria Empresa e de terceiros. Nos termos legalmente
          exigidos, todo o material publicitário ou promocional será
          expressamente identificado com a palavra “Publicidade”.{" "}
        </p>
        <p>
          A Empresa poderá ainda, mediante o seu consentimento, enviarlhe
          publicidade direcionada, através do envio de email, SMS ou
          notificações push. A Empresa poderá, ainda, através da recolha de
          cookies da Empresa ou de terceiros ou da informação que você nos
          disponibilizar ou a terceiros, enviar-lhe publicidade segmentada e
          ajustada aos seus gostos, pesquisas, publicações, faixa etária, entre
          outros critérios relevantes e não discriminatórios. Para mais
          informações sobre a forma como a Plataforma utiliza os seus dados
          pessoais para envio e segmentação de publicidade, por favor consulte a
          nossa Política de privacidade e a nossa Política de Cookie.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "DIREITOS DE PROPRIEDADDE INTELECTUAL",
    paragraph: (
      <div>
        <p>
          Com exclusão expressa dos seus Conteúdos e do que sobre eles se
          encontra previsto na Cláusula 2 acima, o Utilizador reconhece que o
          Serviço e a Plataforma são propriedade exclusiva da Empresa (ou de
          quaisquer empresas afiliadas da Empresa), e que se encontram
          protegidos por direitos de propriedade intelectual e pelas leis
          aplicáveis em matéria de segredos de negócios. O Utilizador reconhece
          e concorda, sem reservas, que a Empresa (ou as empresas que esta
          indicar) são proprietárias exclusivas e incondicionais de quaisquer
          direitos, títulos ou interesses sobre o Serviço, incluindo todos os
          direitos de propriedade intelectual no mundo (existentes ou futuros,
          originários ou subsequentes, e independentemente de terem sido ou não
          registados) que decorram de ou, de algum modo, se relacionem com o
          Serviço e/ou com a Plataforma.
        </p>
        <p>
          Os presentes Termos não lhe concedem nenhum direito de propriedade
          sobre o Serviço ou sobre a Plataforma, mas tão só uma licença de uso
          que será, a todo o tempo revogável pela Empresa, nas condições
          previstas nestes Termos. Nada nos presentes Termos pode representar
          qualquer direito ou autorização a seu favor para reproduzir, copiar,
          imitar, distribuir, vender, publicar, licenciar ou por qualquer outro
          modo utilizar as marcas, imagens, sinais distintivos de comércio ou
          qualquer outro bem de propriedade intelectual da Empresa, no todo ou
          em parte, para benefício próprio ou de terceiros.{" "}
        </p>
        <p>
          Até à extensão máxima permitida pela legislação aplicável, o
          Utilizador não pode copiar, modificar, criar trabalhos derivados,
          realizar compilação ou engenharia reversa ou extrair códigos fonte do
          Software da Plataforma, nem pode vender, distribuir, redistribuir ou
          sublicenciar a Plataforma ou o Software da Plataforma.
        </p>
        <p>
          O software da Plataforma pode conter softwares sujeitos a licenças de
          «código aberto». Quando usarmos tais softwares, e sempre que seja
          relevante para o Utilizador conhecer ou aceitar os termos e condições
          do fornecedor desse software, a Empresa disponibilizará previamente a
          informação necessária e, quando aplicável, obterá a aceitação expressa
          do Utilizador.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    title: "SERVIÇOS PRESTADOS POR PARCEIROS",
    paragraph: (
      <div>
        <p>
          A Plataforma pode intermediar a relação entre o Utilizador e os seus
          parceiros (“Serviços de Parceiros”). Os parceiros são empresas ou
          organizações que, através de um acordo prévio com a Empresa,
          disponibilizam os seus serviços e produtos através da Plataforma
          (“Parceiros”). A compra, aluguer, aquisição, apropriação ou, por
          qualquer outra forma, a utilização pelo Utilizador de Serviços de
          Parceiros é da exclusiva responsabilidade do Utilizador e do respetivo
          Parceiro.
        </p>
        <p>
          O Utilizador reconhece e concorda que, ao aceder à Plataforma, poderá
          ser, pelo menos temporariamente, exposto a conteúdo inexato, enganoso,
          difamatório, ofensivo ou ilegal. Caso tal situação se verifique, o
          Utilizador deverá contactar imediatamente a Empresa de forma a
          sensibilizar a Empresa para o problema. A Empresa só actuará no
          sentido de suspender ou remover o conteúdo do ou a referência ao
          Parceiro quando, a seu critério exclusivo, confirmar a situação
          ilícita, ilegítima ou desconforme com os presentes Termos ou com os
          direitos de terceiros.
        </p>
        <p>
          A Empresa não terá qualquer responsabilidade relativamente aos
          conteúdos disponibilizados na Plataforma pelos Parceiros, nomeadamente
          no que concerne à descrição dos produtos, dos serviços ou as
          apreciações que outros utilizadores façam dos Parceiros, salvo quando
          não retire ou impossibilite o acesso a essa informação assim que tiver
          conhecimento da respetiva ilicitude.{" "}
        </p>
        <p>
          A Empresa exclui qualquer responsabilidade no que respeita a qualquer
          tipo de negócio concluído pelo Utilizador por intermédio da Plataforma
          com os Parceiros, às quais é totalmente alheia. A Empresa não presta
          nem assume qualquer responsabilidade sobre garantias de eficiência,
          competência e/ou qualidade dos produtos e serviços dos Parceiros.{" "}
        </p>
        <p>
          Qualquer reclamação relativa aos produtos ou aos serviços adquiridos
          ou utilizados, aos comentários constantes dos respetivos descritivos
          ou que esteja relacionada de alguma forma com o negócio relativo aos
          mesmos será reencaminhada para o Parceiro relevante, o qual assumirá
          integralmente a responsabilidade a esse título.
        </p>
        <p>
          A Empresa reserva-se o direito de, a todo o tempo e sem qualquer
          necessidade de aviso prévio, independentemente de causa ou motivo e a
          seu critério exclusivo, suspender ou remover o acesso a Serviços de
          Parceiros da Plataforma. Em momento algum poderá o Utilizador reclamar
          quaisquer compensações, direitos ou quaisquer outras responsabilidades
          sobre a Empresa por essa suspensão ou remoção.
        </p>
        <p>
          Ao publicitar e promover os Serviços de Parceiros na Plataforma, a
          Empresa irá exibir, incluir ou tornar visíveis produtos, serviços,
          materiais, software ou outros conteúdos disponibilizados, licenciados
          e/ou distribuídos pelos Parceiros. Os nossos acordos com os Parceiros
          poderão impor restrições sobre direitos de propriedade intelectual
          que, necessária a consecutivamente, teremos de refletir nestes Termos.
          Nessa medida, em caso algum poderá o Utilizador transferir, copiar,
          fazer o download, divulgar, disponibilizar a terceiros, criar
          trabalhos derivados de ou, por qualquer outra forma, utilizar
          conteúdos protegidos dos parceiros, designadamente, mas não limitando,
          imagens, logótipos, sinais distintivos de comércio ou quaisquer outros
          materiais (em texto ou imagem) que possam identificar ou ter origem na
          catividade do Parceiro, independentemente do Utilizador obter ou não
          alguma vantagem económica ou benefício de qualquer espécie com essa
          utilização. Ao exposto nesta cláusula, acrescerá sempre o disposto nos
          termos e condições do Parceiro, que o Utilizador deverá ler,
          compreender e aceitar antes de concluir com este qualquer transação
          através da Plataforma.{" "}
        </p>
        <p>
          Nos termos máximos permitidos pela lei, a Empresa não será, em caso
          algum, responsável pelos produtos, serviços, materiais, suportes e
          mensagens publicitárias, textos, imagens, logótipos sinais distintivos
          ou outros conteúdos disponibilizados, licenciados e/ou distribuídos
          pelos Parceiros na Plataforma.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    title: "RESPONSABILIDADE",
    paragraph: (
      <div>
        <p>
          A Empresa procurará sempre garantir a maior fiabilidade e eficiência
          possíveis tanto da Plataforma como dos Serviços a ela associados.
        </p>
        <p>
          No entanto, na medida máxima permitida pela lei aplicável, a
          plataforma e os respetivos serviços são disponibilizados "no estado em
          que se encontram" e "conforme disponíveis" e nem nós nem nenhuma das
          empresas nossas afiliadas assumimos qualquer responsabilidade ou
          concedemos qualquer garantia em relação à plataforma, aos serviços, a
          qualquer software ou qualquer dado ou conteúdo enviado, transmitido ou
          exibido pela plataforma,{" "}
        </p>
        <p>Inclusive</p>
        <ul>
          <li>
            <p>
              qualquer declaração, garantia ou obrigação de que a plataforma
              terá um acesso permanente e ininterrupto, seguro ou que estará
              livre de erros ou vírus
            </p>
          </li>
          <li>
            <p>
              que a plataforma ou o software plataforma será compatível com o
              seu dispositivo
            </p>
          </li>
          <li>
            <p>
              que a plataforma ou o software da plataforma será própria para um
              propósito particular ou corporativo
            </p>
          </li>
          <li>
            <p>
              {" "}
              que a plataforma corresponde às expetativas que o utilizador possa
              dela ter legitimamente criado. Na medida máxima permitida pela lei
              aplicável, o Utilizador renuncia a todas e quaisquer declarações,
              garantias e obrigações explícitas ou implícitas que sejam
              consequência da utilização da plataforma ou do serviço por esta
              disponibilizado.
            </p>
          </li>
        </ul>

        <p>
          O Utilizador reconhece e aceita sem reservas que será responsável
          exclusivo por qualquer conteúdo do qual faça o download, publique,
          grave, reencaminhe, divulgue ou, por qualquer outra forma, utilize,
          sendo designadamente responsável por qualquer dano ou prejuízo causado
          por qualquer vírus, spyware, worm ou malware que seja instalado na
          sequência do referido conteúdo.{" "}
        </p>
        <p>
          Na medida máxima permitida pela lei aplicável, a nossa
          responsabilidade total e conjunta com todas as nossas empresas
          afiliadas em relação a todas as reivindicações em conexão com estes
          termos, independentemente do tipo de dano ou de prejuízo, que surjam
          de quaisquer circunstâncias, serão limitados à quantia que o
          Utilizador pagou pelo uso da Plataforma ou dos serviços com a qual a
          sua reclamação esteja relacionada nos últimos seis meses imediatamente
          anteriores à data da sua reclamação
        </p>
        <p>
          Na medida máxima permitida pela lei aplicável, em nenhum caso seremos
          responsáveis, nem qualquer uma das empresas nossas afiliadas, por
        </p>
        <ul>
          <li>
            <p>Quaisquer danos ou perdas causadas por:</p>
            <ul>
              <li>
                <p>
                  qualquer desastre natural como inundações, terremotos ou
                  epidemias
                </p>
              </li>
              <li>
                <p>
                  qualquer evento social como guerras, motins, protestos ou
                  acções governamentais
                </p>
              </li>
              <li>
                <p>
                  qualquer vírus de computador, malware ou outro dano causado
                  por malwares ou hackers
                </p>
              </li>
              <li>
                <p>
                  qualquer mau funcionamento ou falha da plataforma, do seu
                  software ou do serviço por ela disponibilizado
                </p>
              </li>
              <li>
                <p>uso indevido ou não autorizado da plataforma</p>
              </li>
              <li>
                <p>uso da Plataforma ou do serviço que viole estes Termos</p>
              </li>
              <li>
                <p>
                  quaisquer motivos que, para além do razoável, estejam fora da
                  nossa previsibilidade
                </p>
              </li>
              <li>
                <p>
                  falha ao salvar ou fazer backup de qualquer dado ou outro
                  conteúdo
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Qualquer perda ou dano resultante de conteúdos, programas ou
              serviços disponibilizados por terceiros, designadamente, mas sem
              limitar, os serviços de parceiros
            </p>
          </li>
          <li>
            <p>
              Qualquer perda ou dano que não seja devido a danos ou perdas
              previsíveis, indiretas, especiais, consequenciais, exemplares ou
              punitivas relativamente à plataforma ou ao serviço por ela
              disponibilizado
            </p>
          </li>
          <li>
            <p>
              Nada nestes Termos limita ou exclui qualquer das seguintes
              responsabilidades, exceto na medida em que tais responsabilidades
              possam ser renunciadas, limitadas ou excluídas nos termos das leis
              aplicáveis:
            </p>
            <ul>
              <li>
                <p>Qualquer responsabilidade por fraude</p>
              </li>
              <li>
                <p>
                  Qualquer responsabilidade por negligência que cause morte ou
                  lesão pessoal
                </p>
              </li>
              <li>
                <p>
                  Qualquer responsabilidade por negligência grave ou má conduta
                  deliberada
                </p>
              </li>
              <li>
                <p>
                  Qualquer outra responsabilidade na medida em que tal
                  culpabilidade não possa ser renunciada, limitada ou excluída
                  nos termos regulamentações e leis aplicáveis
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Não obstante quaisquer outras disposições destes Termos, nada
              neles limita ou exclui qualquer direito legal imperativo da
              jurisdição aplicável (incluindo quaisquer direitos determinados
              por leis de proteção do consumidor), na medida em que tais
              direitos legais não possam ser excluídos ou eliminados por acordo
              entre as partes.
            </p>
          </li>
        </ul>
        <p>
          Não obstante quaisquer outras disposições destes Termos, nada neles
          limita ou exclui qualquer direito legal imperativo da jurisdição
          aplicável (incluindo quaisquer direitos determinados por leis de
          proteção do consumidor), na medida em que tais direitos legais não
          possam ser excluídos ou eliminados por acordo entre as partes.
        </p>
        <p>
          O Utilizador e a sua organização (se usar a Plataforma ou o Serviço em
          nome de uma organização) concordam em indemnizar a Empresa e as suas
          afiliadas por e contra qualquer reclamação, processo, ação, demanda,
          dano, débito, perda, custo, despesa (incluindo custos de litígios e
          honorários de advogado) e responsabilidade resultantes do seu uso da
          Plataforma ou do Serviço em violação do disposto nos Termos ou na lei
          aplicável, bem como a violação de quaisquer direitos de terceiros a
          que a Empresa tenha de responder.
        </p>
      </div>
    ),
  },
  {
    id: 8,
    title: "CESSÇÃO DO REGISTO E DO USO DA PLATAFORMA",
    paragraph: (
      <div>
        <p>
          O Utilizador pode, a todo o tempo e independentemente do motivo,
          solicitar à Empresa o cancelamento do seu registo na Plataforma,
          utilizando um dos contactos disponibilizados no final destes Termos. O
          seu pedido deverá ser sempre feito por escrito, não sendo admitidos ou
          considerados quaisquer pedidos verbais de cessação.
        </p>
        <p>
          Perante um pedido de cessação, a Empresa envidará todos os esforços no
          sentido de concretizar esse pedido num prazo não superior a 15
          (quinze) dias.
        </p>
        <p>
          No caso de pretender, com a cessação do Serviço e encerramento do seu
          registo, o apagamento de todos os seus dados, deverá dirigirnos um
          pedido específico nesse sentido de acordo com o disposto na nossa
          Política de Privacidade.{" "}
        </p>
        <p>
          Por sua vez, a Empresa poderá, a todo o tempo, cessar o seu registo
          ou, por qualquer outro meio, impedir o seu acesso como utilizador
          registado ou convidado à Plataforma caso:{" "}
        </p>
        <ul>
          <li>
            <p>
              Se suspeite justificadamente que o Utilizador violou os presentes
              Termos ou não cumpriu as leis aplicáveis
            </p>
          </li>
          <li>
            <p>
              Seja necessário para realizar ações de manutenção na Plataforma
            </p>
          </li>
          <li>
            <p>
              Seja necessário por razões de segurança e de integridade da
              Plataforma ou do Sistema
            </p>
          </li>
          <li>
            <p>Tenhamos detetado uma duplicação do seu registo</p>
          </li>
          <li>
            <p>
              O seu uso da Plataforma ou do Serviço criar risco para nós ou para
              outros utilizadores da Plataforma, representar uma ameaça de
              potenciais reivindicações de terceiros contra nós ou for
              potencialmente prejudicial à nossa reputação
            </p>
          </li>
        </ul>
        <p>
          Na medida permitida por lei, por qualquer outra razão e de acordo com
          o nosso exclusivo e absoluto critério.
        </p>
        <p>
          Sempre que possível, a Empresa notificará o Utilizador da cessação do
          seu acesso à Plataforma previamente e por escrito.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    title: "ACORDO TOTAL E CESSÃO DE DIREITOS",
    paragraph: (
      <div>
        <p>
          Sujeito às regulamentações e leis aplicáveis, e sem prejuízo do
          Serviço de Parceiros que se regerão pelos termos e condições destes,
          estes Termos definem o acordo completo entre o Utilizador e a Empresa
          na utilização da Plataforma e do Serviço.{" "}
        </p>
        <p>
          O Utilizador concorda que não terá reivindicações contra nós por
          qualquer declaração que não esteja explicitamente definida nestes
          Termos. A invalidade de qualquer disposição destes Termos (ou partes
          de qualquer disposição) não afetará a validade ou exequibilidade de
          qualquer outra disposição (ou partes restantes de tal disposição). Se
          um tribunal decidir que não podemos aplicar qualquer parte destes
          Termos conforme definidos, podemos substituir estes Termos por
          similares, na medida executável pela lei aplicável, sem alterar as
          disposições restantes destes Termos.{" "}
        </p>
        <p>
          A não aplicação ou atraso na aplicação de quaisquer disposições dos
          Termos não será considerada renúncia a quaisquer direitos sob tais
          disposições. Quaisquer direitos e obrigações decorrentes destes Termos
          que, por sua natureza devam subsistir, inclusive, entre outros,
          obrigações em relação à responsabilidade das partes, ou indemnizações
          (se aplicável) dadas pelas partes respetivas, permanecerão em vigor
          após a cessação destes Termos e Condições e, em particular, os
          relativos aos direitos de propriedade intelectual e segredos de
          negócio da Empresa.{" "}
        </p>
        <p>
          O Utilizador não pode ceder a sua posição contratual nestes Termos ou
          ceder quaisquer direitos ou obrigações decorrentes dos Termos, por
          qualquer forma, sem o nosso consentimento prévio e escrito. No
          entanto, podemos ceder livremente a nossa posição contratual nestes
          Termos ou os nossos direitos e obrigações sobre os mesmos, no todo ou
          em parte, sem o consentimento do Utilizador ou aviso prévio. Podemos
          subcontratar livremente qualquer parte da nossa obrigação de
          cumprimento dos Termos a qualquer momento sem o consentimento prévio
          do Utilizador e sem que seja necessário qualquer aviso prévio nesse
          sentido.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    title: "COMUNICAÇÕES",
    paragraph: (
      <div>
        <p>
          Ao usar o Serviço BayQi ou enviar e-mails, mensagens de texto e outras
          comunicações a partir do seu computador ou dispositivo móvel para nós,
          está a comunicar eletronicamente. Nestes termos, o Utilizador consente
          em receber as nossas comunicações eletronicamente, como e-mails,
          textos, avisos de envio por telemóvel ou avisos e mensagens neste site
          ou através de outros Serviços BayQi, podendo guardar uma cópia dessas
          comunicações para seu registo. O Utilizador concorda que todos os
          acordos, avisos, divulgações e outras comunicações que lhe fornecemos
          satisfazem eletronicamente os mesmos requisitos legais das que são
          enviadas por escrito.{" "}
        </p>
        <p>
          Salvo disposição legal em sentido contrário, quaisquer notificações ou
          outras comunicações aos Utilizadores, autorizadas ou exigidas ao
          abrigo destes Termos, serão realizadas eletronicamente e enviadas pela
          Empresa ou por terceiro por si mandatado por e-mail, notificação na
          Plataforma ou serviço de mensagens (incluindo SMS).{" "}
        </p>
        <p>
          O Utilizador desde já aceita esta forma de comunicação, e renuncia,
          até ao máximo permitido por lei, a quaisquer formalidades adicionais
          nas comunicações que receba ou envie para a Empresa.
        </p>
        <p>
          Os contactos da Empresa para quaisquer comunicações ao abrigo dos
          Termos são os seguintes: E-mail: apoiocliente@bayqi.com Telefone: +244
          944 090 618
        </p>
      </div>
    ),
  },
  {
    id: 11,
    title: "JURISDIÇÃO E LEGISLAÇÃO APLICÁVEL",
    paragraph: (
      <div>
        <p>
          Até à extensão máxima permitida pela legislação aplicável, estes
          Termos serão interpretados de acordo com a legislação angolana. Se
          estiver a atuar na qualidade de consumidor e as disposições
          obrigatórias, relativas à proteção do consumidor, do seu país de
          origem forem mais benéficas para o Utilizador, essas disposições
          aplicar-se-ão, independentemente da escolha da lei angolana{" "}
        </p>
        <p>
          Enquanto consumidor, o Utilizador pode intentar quaisquer
          procedimentos judiciais relacionados com estes Termos perante o
          tribunal competente da sua área de residência ou o tribunal competente
          da sede da Empresa na Rua António Feleciano de Castilho. Se a Empresa
          pretender invocar qualquer dos seus direitos contra o Utilizador
          qualificado como consumidor, apenas o pode fazer nos tribunais da
          jurisdição da residência do Utilizador. Se estiver a agir em nome e
          representação de uma organização, o Utilizador aceita submeter-se à
          jurisdição exclusiva dos tribunais angolanos.
        </p>
        <p></p>
      </div>
    ),
  },
  {
    id: 12,
    title: "TEM ALGUMA DÚVIDA?",
    paragraph: (
      <div>
        <p>
          Caso permaneça com alguma dúvida relativamente ao tratamento dos seus
          dados pessoais, ou pretenda exercer algum dos direitos acima
          referidos, por favor contacte-nos através do +244 944 090 618 ou
          endereço e-mail apoiocliente@bayqi.com.{" "}
        </p>
      </div>
    ),
  },
];

const cookiesPolicies = [
  {
    id: 1,
    title: "O QUE SÃO COOKIES",
    paragraph: (
      <div>
        <p>
          Cookies são pequenos ficheiros de texto armazenados pelo site no
          computador do utilizador ou no seu dispositivo móvel, através do
          navegador de internet (browser). A utilização de cookies permite, por
          exemplo, reconhecer o dispositivo do utilizador numa próxima visita ou
          perceber quais os produtos ou serviços que mais lhe interessam.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "PARA QUE SERVEM COOKIES",
    paragraph: (
      <div>
        <p>
          Os cookies são utilizados para gerir o funcionamento dos sites,
          nomeadamente, para aumentar a sua eficiência e permitir aos
          proprietários do site a recolha de informações e preferências dos
          utilizadores. Desta forma, o armazenamento de cookies permite uma
          navegação mais rápida e eficiente, eliminando a necessidade de
          introduzir repetidamente as mesmas informações.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "QUE TIPO DE COOKIES UTILIZAMOS",
    paragraph: (
      <div>
        <p>
          A BayQi utiliza vários tipos de cookies, cada um deles com a sua
          função
        </p>
        <ul>
          <li>
            <p>
              Cookies essenciais – utilizados para garantir o bom funcionamento
              de todas as aplicações do site, e incluem, designadamente, os
              cookies de segurança.{" "}
            </p>
          </li>
          <li>
            <p>
              Cookies de funcionalidade – utilizados para guardar informações do
              utilizador, evitando que os mesmos tenham que digitar novamente
              essa informação (ex.: login). São utilizados em formulários de
              contacto e no processo de abertura de conta online e podem ser
              apagados pelos utilizadores a qualquer momento.{" "}
            </p>
          </li>
          <li>
            <p>
              Cookies de sessão – são temporários e permanecem nos cookies do
              seu navegador de internet até sair do site. Estes cookies têm como
              objetivo recolher informação para identificar problemas e fornecer
              uma melhor experiência de utilização
            </p>
          </li>
          <li>
            <p>
              Cookies analíticos – utilizados para analisar a forma como os
              utilizadores navegam no nosso site e identificar tendências de
              navegação para efeitos estatísticos e acções de melhoria.{" "}
            </p>
          </li>
          <li>
            <p>
              Cookies de remarketing ou publicidade comportamental - os
              prestadores de serviços da BayQi, incluindo o Google, utilizam
              cookies com o objetivo de publicitar os produtos e serviços da
              BayQi. Os utilizadores do site da BayQi podem optar, em qualquer
              momento, por excluir a utilização de cookies do Google através das
              “Definições de Anúncios do Google”
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 4,
    title: "CONTROLO E BLOQUEIO DE COOKIES",
    paragraph: (
      <div>
        <p>
          Quando aceder ao website, o utilizador será questionado sobre quais os
          cookies que aceita, através da nossa Ferramenta de Gestão de Cookies.
          O utilizador deve ter presente que a não aceitação de alguns tipos de
          cookies – designadamente os cookies funcionais – podem impedir ou
          limitar o acesso ao nosso website. Pode alterar as suas preferências a
          "" o tempo através da nossa Ferramenta de Gestão de Cookies.
        </p>
        <p>
          A maioria dos navegadores de internet (browsers) também permite o
          controlo dos cookies através das suas configurações. Por favor,
          procure no menu «ajuda» do seu navegador (browser) ou contacte o
          fornecedor do navegador (browser). A BayQi não é responsável pelas
          definições do seu navegador.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "ALTERAÇÕES",
    paragraph: (
      <div>
        <p>
          A BayQi reserva-se o direito de, a qualquer momento, sem necessidade
          de aviso prévio e com efeitos imediatos, alterar, adicionar, atualizar
          ou eliminar, parcial ou totalmente, a presente informação sobre
          cookies. O utilizador deve consultar periodicamente esta informação
          para confirmar se foram efetuadas quaisquer atualizações ou
          alterações. Para mais informação sobre cookies, visite o site.
        </p>
      </div>
    ),
  },
];

const userLinks = [
  {
    title: "Pagamento",
    links: [
      { title: "Carregamento", link: "/shipment" },
      { title: "Pagar Tv&Internet", link: "/pay-tv-internet" },
      { title: "Enviar Dinheiro", link: "/send-money" },
    ],
  },
  {
    title: "Pague com BayQ",
    links: [
      { title: "Qr Code", link: "/qr-code" },
      { title: "Pagamento Link", link: "/payment" },
    ],
  },
  {
    title: "Shopping",
    links: [
      { title: "Shop", link: "/shop" },
      { title: "Restaurantes", link: "/restaurant" },
      { title: "Supermercados", link: "/markets" },
      { title: "Cantinas", link: "/canteens" },
      { title: "Shopping Mall", link: "/shopping-malls" },
    ],
  },
];

const blogs = [
  {
    id: 1,
    title: "Artifact title",
    description: "Artifact des",
    type: "art",
    src: "/",
  },
  {
    id: 2,
    title: "Artifact title",
    description: "Artifact des",
    type: "art",
    src: "/",
  },
  {
    id: 3,
    title: "Artifact 2 title",
    description: "Artifact 2 des",
    type: "art",
    src: "/",
  },
  {
    id: 4,
    title: "news title",
    description: "news des",
    type: "news",
    src: "/",
  },
  {
    id: 5,
    title: "news2 title",
    description: "news2 des",
    type: "news",
    src: "/",
  },
  {
    id: 6,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 7,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 8,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 9,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 10,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 11,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 12,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 13,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
  {
    id: 14,
    title: "magazine title",
    description: "magzine des",
    type: "mag",
    src: "/",
  },
];

const linkPagesDetails = [
  {
    id: 1,
    title: "O que é o BayQi?",
    paragraph: (
      <div>
        <p>
          BayQi é o maior ecossistema de comércio electrónico de Angola e
          facilitador de pagamento de serviços online. Inaugurada em 2016, temos
          como missão oferecer aos nossos clientes um conjunto de soluções de
          tecnologia em toda a cadeia de comércio, adicionando valor.
          Transformando o digital em algo simples, acessível, criando uma maior
          inclusão digital e financeira
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Quais As Funcionalidades Que A App BayQi Oferece?",
    paragraph: (
      <div>
        <p>
        Com BayQi poderá pagar tudo que deseja com o seu telemóvel, além de receber pagamentos, enviar dinheiro, pagar diversos serviços (recargas, mensalidades, etc) e pagar em estabelecimentos como: cantinas, restaurantes, supermercados, boutiques, feiras.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "Como funciona?",
    paragraph: (
      <div>
        <p>
        Para pagar terá que baixar o aplicativo BayQi (Play Store/Apple Store), criar uma conta adicionar dinheiro na sua conta através de Multicaixa Express, Transferência Bancária, Internet Banking.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    title: "BayQi Tem Tarifa?",
    paragraph: (
      <div>
        <p>
          Baixar o aplicativo BayQi é grátis. Usar BayQi é grátis, não há
          anuidade, mensalidade ou qualquer taxa de adesão.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "Como funciona Enviar ou Solicitar dinheiro?",
    paragraph: (
      <div>
        <p>
          Com a opção Enviar ou Solicitar dinheiro, da secção Amigos poderá
          receber ou enviar dinheiro na hora.
        </p>
        <ul>
          <li>
            <p>Selecciona o montante que deseja enviar ou solicitar</p>
          </li>
          <li>
            <p>
              Selecciona na lista de contactos a quem deseja enviar ou solicitar
              ou pode adicionar um novo contacto
            </p>
          </li>
          <li>
            <p>Insira o seu código pin </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 6,
    title: "Quanto tempo leva para receber o dinheiro?",
    paragraph: (
      <div>
        <p>
        O envio ou solicitação são transacções automáticas, sendo aceite poderá estar disponível na hora.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    title: "Posso receber dinheiro de quem não tem carteira BayQi?",
    paragraph: (
      <div>
        <p>
        Também poderá receber dinheiro de alguém que não tinha conta na BayQi, caso o mesmo queira enviar dinheiro através de um meio de pagamento, neste caso terá que indicar o seu número de conta
        </p>
      </div>
    ),
  },
  {
    id: 8,
    title: "O que é PagaQi?",
    paragraph: (
      <div>
        <p>
        PagaQi permite que solicite a um amigo pagar a conta caso não tenha dinheiro na conta 2123 e-wallet BayQi.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    title: "Como uso um link de pagamento?",
    paragraph: (
      <div>
        <p>
          Um link de pagamento é rápido e seguro, que te permite pagar seus
          produtos e serviços pelo WhatsApp, Facebook, Instagram, e-mail ou
          qualquer rede social. O vendedor irá partilhar um link de pagamento,
          basta abri-lo, escolher o tipo de entrega e pagar!
        </p>
      </div>
    ),
  },
  {
    id: 10,
    title: "Como funciona o pagamento por Código QR BayQi? ",
    paragraph: (
      <div>
        <p>
          Código QR BayQi é um código único vinculado à conta da BayQi de um
          vendedor que permite com que possa efectuar pagamentos presencial.
        </p>
      </div>
    ),
  },
];

export {
  shoppingMalls,
  events,
  categories,
  bagItems,
  products,
  productImages,
  productDetail,
  faqsTopics,
  userLinks,
  blogs,
  linkPagesDetails,
  policies,
  termsConditions,
  cookiesPolicies,
  provinces,
  luandaDelMethods,
  notLuandaDelMethod,
};
