import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../../../config";
import UserPool from "../../../../../../UserPool";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
import ReactCodeInput from "react-verification-code-input";
import { Auth } from "aws-amplify";
import { Form, InputGroup,Spinner,Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import "./SignInSignUp.scss";
import { EmojiSmile, ExclamationSquareFill } from "react-bootstrap-icons";

class SignInSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenType: "",
      email: "",
      inputemailLogin: "",
      inputemailRegister: "",
      inputemailForgotPassword: "",
      inputPassword: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      status: "",
      modalStatus: "",
      loginError: "",
      signupError: "",
      forgotError: "",
      verifyError: "",
      passwordShown: false,
      confirmPasswordShown: false,
      loading: false
    };
  }


  componentDidMount() {
    const { screenType } = this.props;
    this.setState({ screenType });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { screenType } = this.props;
    if (screenType !== prevProps.screenType) {
      this.setState({ screenType });
    }
  }

  render() {
    const { screenType } = this.state;
    const { isOpenModal } = this.props;
    const {history} = this.props;
    console.log('history',history)

    if (!isOpenModal) {
      return null;
    } else {
      return ReactDOM.createPortal(
        <div
          onClick={(e) => this.closeModal(e)}
          id={"modal-view"}
          className={`trigger-close custom-modal ${
            isOpenModal ? "custom-modal-open" : "custom-modal-close"
          }`}
        >
          {screenType === "login" || screenType === "" ? (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div
                  className={"sign-in-modal-body d-flex flex-wrap flex-column"}
                >
                  <div className={"sign-in-modal-body-left"}>
                    <div
                      className={"sign-in-wrap d-flex flex-column flex-wrap"}
                    >
                      <div
                        className={"sign-in-head d-flex flex-column flex-wrap"}
                      >
                        <div className={"sign-in-head-title "}>
                          LogIn to BayQi
                        </div>
                        {/* <div className={ "sign-in-head-sub-title" }>Enter phone no & password below.</div> */}
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput1"
                          >
                            <Form.Label>Email*</Form.Label>

                            <Form.Control
                              type="email"
                              value={this.state.inputemailLogin}
                              placeholder="Andy132@any.com"
                              onChange={this.updateEmailLogin}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput3"
                          >
                            <Form.Label>Password*</Form.Label>
                            <InputGroup className="inp">
                              <Form.Control
                                className="inp-grp"
                                type={
                                  this.state.passwordShown ? "text" : "password"
                                }
                                onChange={this.updatePassword}
                              />
                              {/* <i
                                onClick={() => this.togglePasswordVisiblity()}
                                className="eye-btn"
                              >
                                <AiFillEye className="eye" />
                              </i> */}
                               {this.state.eyeBtntoogle === true ? 
                            <i
                              onClick={() => this.togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEye
                                className="eye"
                                color={"lightgray"}
                                size={"24"}
                              />
                            </i> : <i
                              onClick={() => this.togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEyeInvisible
                                className="eye"
                                color={"lightgray"}
                                size={"24"}
                              />
                            </i> 
                           
                            }
                            </InputGroup>
                            <a
                              onClick={() => this.ForgotPassword()}
                              className="verify"
                            >
                              Forgot Password?
                            </a>
                          </Form.Group>
                          <p className="error-msg"> {this.state.loginError}</p>
                        </Form>
                        <button
                          className="Login"
                          onClick={() => this.signInuser()}
                        >
                          {this.state.loading ?  <Spinner size="sm" animation="border"      
                              variant="light" /> : <p>Login</p> }
                        </button>
                        <br />
                        
                        <div
          onClick={() => this.closeModal1()}
         
        >  <a
                         
                          onClick={() => 
                         
                           { 
                             
                            history.push("/register")}
                          }
                          className={"create-new-account"}
                        >
                          Create New Account
                        </a>
                         
                          </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : screenType === "verify" || screenType === "" ? (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div
                  className={"sign-in-modal-body d-flex flex-wrap flex-column"}
                >
                  <div className={"sign-in-modal-body-left"}>
                    <div
                      className={"sign-in-wrap d-flex flex-column flex-wrap"}
                    >
                      <div
                        className={"sign-in-head d-flex flex-column flex-wrap"}
                      >
                        <div className={"sign-in-head-title "}>
                          Verify Your Account
                        </div>
                        {/* <div className={ "sign-in-head-sub-title" }>Enter phone no & password below.</div> */}
                        <ReactCodeInput
                          onChange={(e) =>
                            this.setState({ verificationCode: e })
                          }
                        />
                        <p className="error-msg"> {this.state.verifyError}</p>
                        <button
                          className="verify-btn"
                          onClick={() => this.verifyUser()}
                        >
                          Verify
                        </button>
                      </div>

                      {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : screenType === "confirm-msg" || screenType === "" ? (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div
                  className={"sign-in-modal-body d-flex flex-wrap flex-column"}
                >
                  <div className={"sign-in-modal-body-left"}>
                    <div
                      className={"sign-in-wrap d-flex flex-column flex-wrap"}
                    >
                      <div
                        className={"sign-in-head d-flex flex-column flex-wrap"}
                      >
                        <div className={"sign-in-head-title "}>
                          Welcome!
                          <p>{this.state.status}</p>
                        </div>
                      </div>

                      {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : screenType === "forgot-password" || screenType === "" ? (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div
                  className={"sign-in-modal-body d-flex flex-wrap flex-column"}
                >
                  <div className={"sign-in-modal-body-left"}>
                    <div
                      className={"sign-in-wrap d-flex flex-column flex-wrap"}
                    >
                      <div
                        className={"sign-in-head d-flex flex-column flex-wrap"}
                      >
                        <div className={"sign-in-head-title "}>
                          Forgot Password
                        </div>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput1"
                          >
                            <Form.Label>Email*</Form.Label>

                            <Form.Control
                              type="email"
                              value={this.state.inputemailForgotPassword}
                              placeholder="Andy132@any.com"
                              onChange={this.updateEmailForgotPassword}
                            />
                          </Form.Group>

                          <p className="error-msg"> {this.state.forgotError}</p>
                        </Form>
                        

                        <button
                          onClick={() => this.updateForgetPassword()}
                          className="forgot-btn"
                        >
                          {this.state.loading ?  <Spinner size="sm" animation="border"      
                              variant="light" /> : <p>OK</p> }
                        </button>
                        <p className="already-acc-signup">
                          Remember Password?{" "}
                          <a
                            onClick={() => this.login()}
                            className="already-login"
                          >
                            Sign in
                          </a>
                        </p>
                      </div>

                      {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : screenType === "update-forgot-password" || screenType === "" ? (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div
                  className={"sign-in-modal-body d-flex flex-wrap flex-column"}
                >
                  <div className={"sign-in-modal-body-left"}>
                    <div
                      className={"sign-in-wrap d-flex flex-column flex-wrap"}
                    >
                      <div
                        className={"sign-in-head d-flex flex-column flex-wrap"}
                      >
                        <div className={"sign-in-head-title "}>
                          Forgot Password
                        </div>
                        <Form>
                          <p>Code sent to your email</p>
                          <ReactCodeInput
                            onChange={(e) =>
                              this.setState({ verificationCode: e })
                            }
                          />
                          <p className="error-msg"> {this.state.forgotError}</p>
                        </Form>
                        <Form.Group className="mb-3" controlId="ControlInput3">
                          <Form.Label>Password*</Form.Label>
                          <InputGroup className="inp">
                            <Form.Control
                              className="inp-grp"
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                             
                              onChange={this.updatePassword}
                            />
                            {/* <i
                              onClick={() => this.togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEye className="eye" />
                            </i> */}
                             {this.state.eyeBtntoogle === true ? 
                            <i
                              onClick={() => this.togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEye
                                className="eye"
                                color={"lightgray"}
                                size={"24"}
                              />
                            </i> : <i
                              onClick={() => this.togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEyeInvisible
                                className="eye"
                                color={"lightgray"}
                                size={"24"}
                              />
                            </i> 
                           
                            }
                          </InputGroup>
                        </Form.Group>
                        <button
                          onClick={() => this.changeForgetPassword()}
                          className="verify-btn"
                        >
                          Ok
                        </button>
                      </div>

                      {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            screenType === "create" && (
              <div
                className="custom-modal-dialog"
                style={{ maxWidth: "45em !important" }}
              >
                <div className="modal-content">
                  <div className={"custom-modal-close-btn"}>
                    <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                  </div>
                  <div
                    className={"sign-up-modal-body d-flex flex-wrap flex-row"}
                  >
                    <div className={"sign-up-modal-body-left"}>
                      <div>
                        <div
                          className={
                            "sign-up-head d-flex flex-column flex-wrap mb-3"
                          }
                        >
                          <div className={"sign-in-head-title "}>
                            Sign Up to BayQi
                          </div>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="ControlInput1"
                            >
                              <Form.Label>Email*</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Andy132@any.com"
                                onChange={this.updateinputEmailRegister}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="ControlInput3"
                            >
                              <Form.Label>Full Name*</Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="ControlInput3"
                            >
                              <Form.Label>Password*</Form.Label>
                              <InputGroup className="inp">
                                <Form.Control
                                  className="inp-grp"
                                  type={
                                    this.state.passwordShown
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={this.updatePassword}
                                />
                                <i
                                  onClick={() => this.togglePasswordVisiblity()}
                                  className="eye-btn"
                                >
                                  <AiFillEye className="eye" />
                                </i>
                              </InputGroup>
                            </Form.Group>

                            <Form.Group
                              className="mb-3"
                              controlId="ControlInput3"
                            >
                              <Form.Label>Confirm Password*</Form.Label>
                              <InputGroup className="inp">
                                <Form.Control
                                  className="inp-grp"
                                  type={
                                    this.state.confirmPasswordShown
                                      ? "text"
                                      : "password"
                                  }
                                  value={this.state.ConfirmPassword}
                                  onChange={this.updateConfirmPassword}
                                />
                                <i
                                  onClick={() =>
                                    this.toggleConfirmPasswordVisiblity()
                                  }
                                  className="eye-btn"
                                >
                                  <AiFillEye className="eye" />
                                </i>
                              </InputGroup>
                            </Form.Group>
                            <p className="error-msg">
                              {" "}
                              {this.state.signupError}
                            </p>
                          </Form>

                          <button
                            onClick={() => this.signupUser()}
                            className="signup"
                          >
                            Sign Up
                          </button>

                          <p className="already-acc">
                            Already have an account?{" "}
                            <a
                              onClick={() => this.login()}
                              className="already-login"
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>,
        document.getElementById("modal-root")
      );
    }
  }

  // helpers

  login = () => {
    this.setState({ screenType: "login" });
    this.setState({ inputemail: "" });
    this.setState({ inputPassword: "" });
    this.setState({ signupError: "" });
  };
  okButton = () => {
    this.setState({ screenType: this.state.modalStatus });
  };
  togglePasswordVisiblity = () => {
    if (this.state.passwordShown == true) {
      this.setState({ passwordShown: false });
    } else {
      this.setState({ passwordShown: true });
    }
  };
  toggleConfirmPasswordVisiblity = () => {
    if (this.state.confirmPasswordShown == true) {
      this.setState({ confirmPasswordShown: false });
    } else {
      this.setState({ confirmPasswordShown: true });
    }
  };

  signUp = () => {
    this.setState({ inputemail: "" });
    this.setState({ inputPassword: "" });
    this.setState({ screenType: "create" });
    this.setState({ loginError: "" });
  };
  ForgotPassword = () => {
    this.setState({ screenType: "forgot-password" });
  };
  verifyUser = async () => {
    try {
      await Auth.confirmSignUp(this.state.email, this.state.verificationCode);
      this.setState({ status: "You are registered" });
      this.setState({ screenType: "confirm-msg" });
    } catch (error) {
      console.log("error confirming sign up", error);
      this.setState({ verifyError: "*You entered wrong code" });
      this.setState({ modalStatus: "verify" });
    }
  };

  updateinputEmailRegister = (evt) => {
    this.setState({ email: evt.target.value });
    this.setState({ inputemailRegister: evt.target.value });
    this.setState({ inputemailLogin: "" });
    this.setState({ inputemailForgotPassword: "" });
    this.setState({ signupError: "" });
  };
  updateEmailLogin = (evt) => {
    this.setState({ email: evt.target.value });
    this.setState({ inputemailLogin: evt.target.value });
    this.setState({ inputemailRegister: "" });
    this.setState({ inputemailForgotPassword: "" });
    this.setState({ loginError: "" });
    this.setState({ forgotError: "" });
    this.setState({ signupError: "" });
  };
  updateEmailForgotPassword = (evt) => {
    this.setState({ email: evt.target.value });
    this.setState({ inputemailForgotPassword: evt.target.value });
    this.setState({ forgotError: "" });
  };

  signInuser = async () => {
    this.setState({loading: true})
  
      try { 
   
        const user = await Auth.signIn(this.state.email, this.state.password);
        // TODO: save this user id as "CID" in localStorage
        localStorage.setItem("cid", user.attributes.sub);
        this.setState({ inputemail: "" });
        this.setState({ inputPassword: "" });
        this.setState({email: ''})
        this.setState({password: ''})
        this.setState({loading: false})


        this.setState({ status: "You are Logged In" });
        this.setState({ screenType: "confirm-msg" });
        this.setState({ inputemailLogin: "" });
        window.location.reload()
      } catch (error) {
        this.setState({loading: false})

        if (this.state.email === "" || this.state.password === "") {
          this.setState({ loginError: "*Email and password can not be empty." });
        } else {
        this.setState({ loginError: error.message });
        this.setState({ inputemail: "" });
        this.setState({ inputPassword: "" });
        this.setState({ modalStatus: "login" });
        }
      }
    
  };

  signupUser = async () => {
    try {
          const { user } = await Auth.signUp({
            username: this.state.email,
            password: this.state.password,
          });
          console.log(user);
          this.setState({ screenType: "verify" });
        } catch (error) {
          if ( error.message == 'Username cannot be empty'){
            this.setState({ signupError: "*Email cannot be empty" });
          }
          else if( error.message == 'Username should be either an email or a phone number.'){
            this.setState({ signupError: 'Enter valid email' });
          }
          else {
            this.setState({ signupError: error.message });
          }
          console.log("error signing up:", error);

          this.setState({ inputPassword: "" });
          this.setState({ modalStatus: "create" });
        }
      
  };
  updateForgetPassword = () => {
    
    if (this.state.email == "") {
      this.setState({ forgotError: "*Email can not be empty" });
    } else {
      Auth.forgotPassword(this.state.email)
        .then((data) => {
          this.setState({ screenType: "update-forgot-password" });
          if(!this.state.loading){
            this.setState({loading: true})
          }else{
            this.setState({loading: false})
          }
        })
        .catch((err) => {
          this.setState({
            forgotError: "*InValid Email or Limit exceed try after some time.",
          });
        });
    }
  };
  changeForgetPassword = () => {
    Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.verificationCode,
      this.state.password
    )
      .then((data) => {
        this.setState({ status: "Password changed." });
        this.setState({ screenType: "confirm-msg" });
      })
      .catch((err) => {
        if (this.state.verificationCode == "") {
          this.setState({ forgotError: "*Enter code" });
        } else {
          this.setState({ forgotError: "*InValid code" });
        }
      });
  };
  updatePassword = (evt) => {
    this.setState({ password: evt.target.value });
    this.setState({ inputPassword: evt.target.value });
    this.setState({ signupError: "" });
  };

  updateConfirmPassword = (evt) => {
    this.setState({ confirmPassword: evt.target.value });
  };

  closeModal = (e) => {
    const { closeModal } = this.props;
    try {
      if (e.target.className.includes("trigger-close")) {
        this.setState({
          screenType: "",
        });
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({ inputPassword:'' });
    this.setState({ inputemail: '' });
    this.setState({ signupError: '' });
    this.setState({ loginError: '' });
    this.setState({forgotError:''})
    this.setState({inputemailForgotPassword :''})
    
  };
    closeModal1 = () => {
 const { closeModal } = this.props;
     this.setState({screenType : ''})
     closeModal();
  };
  getLoginCredentials = (data) => {
    const { closeModal } = this.props;
    console.log("login cred ", data);
    if (data.number == "0300" && data.password == "123") {
      sessionStorage.setItem("number", data.number);
      sessionStorage.setItem("password", data.password);
      closeModal();
      toast.success("Successfully logged-In", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // this.signIn(data, (response) => {
    //   if (response.status) {
    //     console.log("user loggedIn")
    //     closeModal()
    //     sessionStorage.setItem("number", data.number)
    //     sessionStorage.setItem("password", data.password)
    //     toast.success('Successfully logged-In', {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    //   else {
    //     toast.error('Wrong Credentials', {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // })
  };

  signIn = (obj, callback) => {
    let authenticationData = {
      Username: obj.number,
      Password: obj.password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: obj.number,
      Pool: UserPool,
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        let accessToken = result.getAccessToken().getJwtToken();
        console.log(accessToken);
        const idToken = result.getIdToken().getJwtToken();
        let refreshToken = result.refreshToken.token;
        let accessTimeExp = result.accessToken.payload.exp;
        sessionStorage.setItem("token", idToken);
        sessionStorage.setItem("email", obj.email);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("accessTimeExp", accessTimeExp);
        sessionStorage.setItem("uuid", result.idToken.payload.sub);

        axios
          .get(`${baseUrl}/${result.idToken.payload.sub}`)
          .then((customer) => {
            callback({
              status: true,
              response: result.idToken.payload,
            });
          })
          .catch((error) => {
            callback({
              status: false,
              response: error.message,
            });
          });
      },

      onFailure: function (err) {
        // alert(err.message || JSON.stringify(err));
        console.log(err);
        callback({
          status: false,
          message: err && err.response && err.response.data.message,
        });
      },
    });
  };
}

export default withRouter(SignInSignUp);
