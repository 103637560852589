import React from 'react';
import ImagesLoader from "../../../../helpers/ImagesLoader";
// import './ClientSupportBar.scss';
import './ClientSupportBar.css';
import './ClientSupportBar2.scss';

const ClientSupportBar = () => {

    return (
      <div className={'client-support-bar-container'}>
      <div className={"client-support-bar-left-section"}>
        <a href="https://apps.apple.com/pk/app/bayqi/id1669111904" target="_blank" rel="noopener noreferrer">
          <div style={{
            backgroundImage: `url(${ImagesLoader('on-apple-app')})`,
            height: '2rem',
            width: '6rem',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            margin: '0 1.5em 0.2em 0',
            cursor: 'pointer'
          }}>
          </div>
        </a>

        <div style={{
          backgroundImage: `url(${ImagesLoader('on-android-app')})`,
          height: '2rem',
          width: '6rem',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer'
        }}>
        </div>
      </div>

      <ul className="social-icons-top d-flex flex-row flex-wrap">
        <li><a className="facebook" href="https://www.facebook.com/bayqi/" target="blank"><i
          className="fa fa-facebook"></i></a></li>
        <li><a className="instagram" href="https://www.instagram.com/oficialbayqi/?hl=pt" target="blank"><i
          className="fa fa-instagram"></i></a></li>
        <li><a className="youtube" href="https://www.youtube.com/channel/UCTkhgki5Zc3vRbUS3gnJIAg?view_as=subscriber"
               target="blank"><i className="fa fa-youtube"></i></a></li>
      </ul>


        {/*<ul className={'client-support-bar-wrapper'}>*/ }
        {/*  <li className={'client-support-btn-wrap'}>*/ }
        {/*    <Link className={'client-support-btn'} to="/">Client support +244 939 725 027</Link>*/ }
        {/*  </li>*/ }
        {/*  <li className={'client-support-btn-wrap'}>*/ }
        {/*    <Link className={'client-support-btn'} to="/">Track Order</Link>*/ }
        {/*  </li>*/}
        {/*  <li className={'client-support-btn-wrap'}>*/}
        {/*    <Link className={'client-support-btn'} to="/">Our Payment Methods</Link>*/}
        {/*  </li>*/}
        {/*  <li className={'client-support-btn-wrap'}>*/}
        {/*    <Link className={'client-support-btn'} to="/">Help</Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}




      </div>
    )
  }


export default ClientSupportBar;


