import React, { useState } from "react";
import "./Swing.css";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
} from "reactstrap";
import CardStats from "./CardStats";
import { useTranslation } from "react-i18next";
export default function Swing() {
  const [tableData, setTableData] = useState([0, 1, 2, 3, 4, 5]);
  const [activePage, setActivePage] = useState(1);
  const [t] = useTranslation();
  return (
    <>
      <CardStats />
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0px 25px",
          backgroundColor: "#f7f7f7",
          marginTop: "10px",
        }}
      >
        <div
          className="MainContainer"
          style={{
            backgroundColor: "#fff",
            padding: "12px 5px",
            marginTop: "40px",
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xl={5}
              lg={5}
              style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {t("Swing")}
                </span>
                {/* <div
                style={{
                  marginLeft: "14px",
                  padding: "10px",
                  height: "25px",
                  backgroundColor: "#049EFF",
                  borderRadius: "25px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  }}
                >
                  2 New
                </span>
              </div> */}
              </div>
              {/* <span style={{ fontWeight: "500", fontSize: "1rem" }}>
              Wednesday, 16 December 2020
            </span> */}
            </Col>
            <Col
              xl={3}
              lg={3}
              style={{
                //  backgroundColor: "yellow",
                height: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="input-group rounded">
                <input
                  type="search"
                  class="form-control rounded"
                  placeholder={t("Search")}
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              style={{
                //  backgroundColor: "yellow",
                height: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                  // color={'light'}
                  style={{
                    backgroundColor: "#fff",

                    borderColor: "#CED4DA",
                    color: "#333",
                    fontSize: "0.9rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret
                >
                  <span
                    style={{
                      color: "#333",
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("Show All")}
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: "5px" }}>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Another Action</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col xl={2} lg={2}>
              <div class="input-group rounded">
                <input
                  type="date"
                  class="form-control rounded"
                  placeholder={t("Search")}
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* TABLE VIEW */}
        <Table
          style={{
            marginTop: "25px",
            borderRadius: "25px",
            backgroundColor: "#f7f7f7",
            cursor: "pointer",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Period")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Closed Date")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("No of Payments")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Amount")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Commission")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>SMS</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Net Amount")}</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  <span style={{ fontSize: "0.7rem" }}>1</span>
                </td>
                <td style={{ padding: "20px" }}>2021-02-27 20:00:03</td>
                <td style={{ padding: "20px" }}>3</td>
                <td style={{ padding: "20px" }}>200,636.00 AKZ</td>
                <td style={{ padding: "20px" }}>120.00 AKZ</td>
                <td style={{ padding: "20px" }}>1,277.75 AKZ</td>
                <td style={{ padding: "20px" }}>24,277.25 AKZ</td>
              </tr>
            ))}
          </tbody>
        </Table>{" "}
        <Row>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <Pagination.Item
                      onClick={() => setActivePage(item)}
                      active={activePage === item}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    // color={'light'}
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      1
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
}
