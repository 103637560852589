import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Form, InputGroup } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import "./Register.scss";
import axios from "axios";
import ReactCodeInput from "react-verification-code-input";
import { toast } from "react-toastify";
import { baseUrl } from "../../../config";
import { useHistory } from "react-router-dom";

import NavBar from "./NavBar/NavBar";
import { FooterV2 } from "../../MainLayout/Footer";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [t] = useTranslation();
  const [screenType, setScreenType] = useState("signup");
  const [email, setEmail] = useState("");
  const [inputemailLogin, setInputemailLogin] = useState("");
  const [inputemailForgotPassword, setInputEmailForgotPassword] = useState("");
  const [inputemailRegister, setInputEmailRegister] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [status, setStatus] = useState("");
  const [modalStatus, setModalStatus] = useState("");
  const [loginError, setLoginError] = useState("");
  const [signupError, setSignupError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [username, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [eyeBtntoogle, setEyeBtntoogle] = useState(false);
  const [confirmeyeBtntoogle, setConfirmeyeBtntoogle] = useState(false);

  const history = useHistory();
  useEffect(() => {
    window.scroll(0, 0);
  });

  // helpers
  const signupUser = async () => {
    setSignupError("");
    // verify email regex
    if (
      email === "" ||
      password === "" ||
      username === "" ||
      phoneNumber === ""
    ) {
      setSignupError(t("* Fields are mandatory."));
    } else {
      if (password === confirmPassword) {
        try {
          const user = await Auth.signUp({
            username: email,
            password: password,
          });
          console.log(user.userSub);
          localStorage.setItem("cid", user.userSub);
          setScreenType("verify");

          axios
            .post(`${baseUrl}/customers`, {
              id: localStorage.getItem("cid"),
              name: username,
              email: email,
              phoneNumber: phoneNumber,
            })
            .then((res) => console.log("user api res: ", res))
            .catch((err) => console.log("user api error: ", err));
        } catch (error) {
          setSignupError(error.message);
          console.log("error signing up:", error);

          setInputPassword("");
          setModalStatus("create");
        }
      } else {
        setSignupError(t("*Password and Confirm Password must be same."));
      }
    }
  };

  const verifyUser = async () => {
    try {
      setVerifyError("");
      await Auth.confirmSignUp(email, verificationCode);
      setStatus(t("You are registered"));
      toast.success(t("User verfied"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/");
    } catch (error) {
      console.log("error confirming sign up", error);
      setVerifyError(error.message);
      setModalStatus("verify");
    }
  };

  const togglePasswordVisiblity = () => {
    if (passwordShown == true) {
      setPasswordShown(false);
      setEyeBtntoogle(false);
    } else {
      setPasswordShown(true);
      setEyeBtntoogle(true);
    }
  };

  const toggleConfirmPasswordVisiblity = () => {
    if (confirmPasswordShown == true) {
      setConfirmPasswordShown(false);
      setConfirmeyeBtntoogle(false);
    } else {
      setConfirmPasswordShown(true);
      setConfirmeyeBtntoogle(true);
    }
  };

  const updateinputEmailRegister = (evt) => {
    setEmail(evt.target.value);
    setInputEmailRegister(evt.target.value);
    setInputemailLogin("");
    setInputEmailForgotPassword("");
    setSignupError("");
  };

  const updatePassword = (evt) => {
    setPassword(evt.target.value);
    setInputPassword(evt.target.value);
    setSignupError("");
  };

  const updateConfirmPassword = (evt) => {
    setConfirmPassword(evt.target.value);
  };

  if (screenType === "signup")
    return (
      <div>
        <NavBar />
        <div
          style={{
            padding: "20px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            border: "1px solid #ced4da",
            width: "30%",
          }}
        >
          <div>
            <div className="sign-up-title">{t("Sign Up to BayQi")}</div>
            <Form>
              <Form.Group className="mb-3" controlId="ControlInput1">
                <Form.Label>
                  {t("Email")}<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={updateinputEmailRegister}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="ControlInput3">
                <Form.Label>
                  {t("Full Name")}<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Enter username")}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="ControlInput3">
                <Form.Label>
                  {t("Phone Number")}<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder={t("Enter phone number")}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="ControlInput3">
                <Form.Label>
                  {t("Password")}<span className="red">*</span>
                </Form.Label>
                <InputGroup className="inp">
                  <Form.Control
                    className="inp-grp"
                    placeholder={t("Enter Password")}
                    type={passwordShown ? "text" : "password"}
                    onChange={updatePassword}
                  />

                  {eyeBtntoogle === true ? (
                    <i
                      onClick={() => togglePasswordVisiblity()}
                      className="eye-btn"
                    >
                      <AiFillEye
                        className="eye"
                        color={"lightgray"}
                        size={"24"}
                      />
                    </i>
                  ) : (
                    <i
                      onClick={() => togglePasswordVisiblity()}
                      className="eye-btn"
                    >
                      <AiFillEyeInvisible
                        className="eye"
                        color={"lightgray"}
                        size={"24"}
                      />
                    </i>
                  )}
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="ControlInput3">
                <Form.Label>
                  {t("Confirm Password")}<span className="red">*</span>
                </Form.Label>
                <InputGroup className="inp">
                  <Form.Control
                    className="inp-grp"
                    placeholder={t("Repeat Password")}
                    type={confirmPasswordShown ? "text" : "password"}
                    value={confirmPassword}
                    onChange={updateConfirmPassword}
                  />
                  {confirmeyeBtntoogle === true ? (
                    <i
                      onClick={() => toggleConfirmPasswordVisiblity()}
                      className="eye-btn"
                    >
                      <AiFillEye
                        className="eye"
                        color={"lightgray"}
                        size={"24"}
                      />
                    </i>
                  ) : (
                    <i
                      onClick={() => toggleConfirmPasswordVisiblity()}
                      className="eye-btn"
                    >
                      <AiFillEyeInvisible
                        className="eye"
                        color={"lightgray"}
                        size={"24"}
                      />
                    </i>
                  )}
                </InputGroup>
              </Form.Group>
              <p className="error-msg"> {signupError}</p>
            </Form>

            <button onClick={() => signupUser()} className="signup">
              {t("Sign Up")}
            </button>
          </div>
        </div>
        <FooterV2 />
      </div>
    );

  if (screenType === "verify")
    return (
      <div className={`custom-modal`}>
        <div
          className="custom-modal-dialog"
          style={{ maxWidth: "45em !important" }}
        >
          <div className="modal-content">
            {/* <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div> */}
            <div className={"sign-in-modal-body d-flex flex-wrap flex-column"}>
              <div className={"sign-in-modal-body-left"}>
                <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                  <div className={"sign-in-head d-flex flex-column flex-wrap"}>
                    <div className={"sign-in-head-title"}>
                      {t("Verify Your Account")}
                    </div>
                    <p className={"sign-in-subtitle"}>
                      {t("Check your email for verification code")}
                    </p>
                    {/* <div className={ "sign-in-head-sub-title" }>Enter phone no & password below.</div> */}
                    <ReactCodeInput onChange={(e) => setVerificationCode(e)} />
                    <p className="error-msg"> {verifyError}</p>
                    {/* <p
                        onClick={() => this.setState({ screenType: "signup" })}
                        style={{
                          textAlign: "end",
                          color: "blue",
                          fontSize: "12px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Go back
                      </p> */}
                    <button className="verify-btn" onClick={() => verifyUser()}>
                      {t("Verify")}
                    </button>
                  </div>

                  {/* <SignInForm
                    getLoginCredentials={ this.getLoginCredentials }
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default withRouter(Register);
