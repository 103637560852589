const filtersTypes = {
  FETCH_FILTERS: 'FETCH_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  EDIT_FILTER: 'EDIT_FILTER',
  RESET_FILTERS: 'RESET_FILTERS',
  REDUCE_FILTERS: 'REDUCE_FILTERS'
};

export default filtersTypes;