import SobreImg3         from '../assets/images/0004f.jpg';
import SobreImg1         from '../assets/images/001f.jpg';
import SobreImg2         from '../assets/images/0089fi.jpg';
import WomenFashionImg   from '../assets/images/066a898b479358e93ecd425cb6bae239e22d3437.png';
import Product1          from '../assets/images/066a898b479358e93ecd425cb6bae239e22d3437.png';
import logo              from '../assets/images/09d3b2384bf31ea2869d2bfca4228f9ad4512026.png'
import Product2          from '../assets/images/0d36bc4295510f697a9354ba0cc53dcdd94066b3.png';
import CategorizeIcon    from '../assets/images/11categorize.png';
import WatchesImg        from '../assets/images/2f3f7cfd4c18a101351f786b823d56ad54217115.png';
import AppliancesImg     from '../assets/images/3164e758fef2faf4cde3ed4cad687c764779e7c2.png';
import cartItem1         from '../assets/images/4e836d38886bd7df76b43a2d1a934ebeb3a0ff1c.png';
import cartItem3         from '../assets/images/4e8f379f46d54fcf946a5f312cdf09645d67f1b3.png';
import LaptopImg         from '../assets/images/4e9dc0caff39d9b7c2dee16e4a8568d08de2fd74.png';
import nortonImage       from '../assets/images/586aae231fdce414493f5100.png'
import onAppleImage      from '../assets/images/5a902db97f96951c82922874.png'
import Product3Detail2   from '../assets/images/6f6806030f182682cc577d5d1575f0979cc1c02e.png';
import moneyImage        from '../assets/images/72d60eff774f1fe61b2af1bb7c5d2d85381a024d.png'
import Product3Detail3   from '../assets/images/820b7e2903f0cfed76a74daa1863463480a56e22.png';
import KidFashionImg     from '../assets/images/894e9c8c6aea76a8710c09aa5268709c9c8cb536.png';
import Phones            from '../assets/images/968cec6f5ea148b4046732c684bd00824925c1d1.png'
import MdcShopping       from '../assets/images/9a88ec093637cc8c0290c86abec76e2d6b95ea26.png';
import Product3          from '../assets/images/aa91ce45e12bbba70207f3f92b4ffccc301679d8.png';
import Product3Detail4   from '../assets/images/aa91ce45e12bbba70207f3f92b4ffccc301679d8.png';
import cartItem2         from '../assets/images/acedba1949746fd745a451a1282e1e8938f8187b.png';
import AmazonShopping    from '../assets/images/amazon-online-shopping.png';
import RingFashionImg    from '../assets/images/b0f7b4630a0a93985073de1140463445086b184e.png';
import Banner1           from '../assets/images/banner-1.jpg';
import Banner2           from '../assets/images/banner-2.jpg';
import Banner3           from '../assets/images/banner-3.jpg';
import LogoWhite         from '../assets/images/BayQi-white-logo.png';
import BlogImage1        from '../assets/images/blog-BANNER.png';
import BloggerImage1     from '../assets/images/blogger-img-1.png';
import BeautyFashionImg  from '../assets/images/c05b68f497f8d5a7f19756df98edde2637081414.png';
import QRCode            from '../assets/images/c6e1f8c15eca9b2c4d8ce5514490b7d95752376e.png';
import CookiesCover      from '../assets/images/cookies-cover.png';
import PhoneMoneyShare   from '../assets/images/credit-card.svg';
import DeliveryTruck     from '../assets/images/delivery-truck.png';
import PhoneImg          from '../assets/images/e9dd7476d73c9e8d42937855ea0612f779daaa4d.png';
import TalagonaShopping  from '../assets/images/f1ab93fbf8cc01e80197851ca527e38a814182e3.png';
import People            from '../assets/images/f73dde42b0d4100a7c37347a39636d527edccfb9.png'
import CidadeDaChina     from '../assets/images/f986a3429e27cda0bc12ea867a5e6576660403db.png';
import FaqsCover         from '../assets/images/faqs-cover.jpg';
import MenFashionImg     from '../assets/images/ff386818457f1aa057e611d734166f831760f5f9.png';
import onAndroidImage    from '../assets/images/google-play-store-button.png'
import BloggerImage2     from '../assets/images/jhon-freddy.jpg';
import QRCodeLinkPg      from '../assets/images/link-pg-qr.png';
import LinkUsPic         from '../assets/images/link-us-pic.jpg';
import lvModel           from '../assets/images/lv.png';
import PaymentCard       from '../assets/images/payment-card.png';
import PaymentLinkPg     from '../assets/images/payment-link-pg.png';
import PaymentSummary    from '../assets/images/payment-summary.png';
import PlayStoreIcon     from '../assets/images/play-store-icon-white.png';
import QRCodePic         from '../assets/images/02QrBayQi.jpg';
import Ribbon            from '../assets/images/ribbon_4899188.png';
import SendMoneyLinkPg   from '../assets/images/send-mony-link-pg.png';
import ShipmentLinkPg    from '../assets/images/shipment-link-pg.png';
import ShoppingBag       from '../assets/images/shopping-bag.png';
import ThreePhonesSecTwo from '../assets/images/three-phones-sec-two.png';
import TvInternetLinkPg  from '../assets/images/tv-internet-link-pg.png';
import WatchPic          from '../assets/images/02-couple.jpg';
import WhyPaymentLink    from '../assets/images/why-payment-link.png';


const images = [
  { id: 1, src: `${ logo }`, title: 'logo', description: 'company logo' },
  { id: 1, src: `${ LogoWhite }`, title: 'white-logo', description: 'company logo' },
  { id: 2, src: `${ nortonImage }`, title: 'norton', description: 'norton' },
  { id: 3, src: `${ moneyImage }`, title: 'pay-money', description: 'pay-money' },
  { id: 4, src: `${ onAndroidImage }`, title: 'on-android-app', description: 'on-android-app' },
  { id: 5, src: `${ onAppleImage }`, title: 'on-apple-app', description: 'on-apple-app' },
  { id: 6, src: `${ MdcShopping }`, title: 'mdc-shopping', description: '' },
  { id: 7, src: `${ QRCode }`, title: 'qr-code', description: '' },
  { id: 8, src: `${ PhoneMoneyShare }`, title: 'phone-money-share', description: '' },
  { id: 9, src: `${ TalagonaShopping }`, title: 'talagona-shopping', description: '' },
  { id: 10, src: `${ CidadeDaChina }`, title: 'cidade-da-china', description: '' },
  { id: 11, src: `${ AmazonShopping }`, title: 'amazon-shopping', description: '' },
  { id: 12, src: `${ People }`, title: 'girl', description: '' },
  { id: 13, src: `${ Phones }`, title: 'phones', description: '' },
  { id: 14, src: `${ CategorizeIcon }`, title: 'cat-icon', description: '' },
  { id: 15, src: `${ WomenFashionImg }`, title: 'women-fashion', description: '' },
  { id: 16, src: `${ MenFashionImg }`, title: 'mens-fashion', description: '' },
  { id: 17, src: `${ KidFashionImg }`, title: 'kids-fashion', description: '' },
  { id: 18, src: `${ BeautyFashionImg }`, title: 'beauty-fashion', description: '' },
  { id: 19, src: `${ RingFashionImg }`, title: 'jewelry-fashion', description: '' },
  { id: 20, src: `${ PhoneImg }`, title: 'mobiles', description: '' },
  { id: 21, src: `${ LaptopImg }`, title: 'laptops', description: '' },
  { id: 22, src: `${ AppliancesImg }`, title: 'electronics', description: '' },
  { id: 23, src: `${ WatchesImg }`, title: 'watches', description: '' },
  { id: 24, src: `${ Product1 }`, title: 'product1', description: '' },
  { id: 25, src: `${ Product2 }`, title: 'product2', description: '' },
  { id: 26, src: `${ Product3 }`, title: 'product-3-detail-1', description: '' },
  { id: 26, src: `${ Product3Detail2 }`, title: 'product-3-detail-2', description: '' },
  { id: 26, src: `${ Product3Detail3 }`, title: 'product-3-detail-3', description: '' },
  { id: 26, src: `${ Product3Detail4 }`, title: 'product-3-detail-4', description: '' },
  { id: 27, src: `${ Ribbon }`, title: 'ribbon', description: '' },
  { id: 28, src: `${ PaymentSummary }`, title: 'payment-summary', description: '' },
  { id: 28, src: `${ DeliveryTruck }`, title: 'delivery-truck', description: '' },
  { id: 28, src: `${ PaymentCard }`, title: 'payment-card', description: '' },
  { id: 29, src: `${ ShoppingBag }`, title: 'shopping-bag', description: '' },
  { id: 29, src: `${ cartItem1 }`, title: 'cart-item-1', description: '' },
  { id: 29, src: `${ cartItem2 }`, title: 'cart-item-2', description: '' },
  { id: 29, src: `${ cartItem3 }`, title: 'cart-item-3', description: '' },
  { id: 29, src: `${ lvModel }`, title: 'lv-model', description: '' },
  { id: 29, src: `${ BlogImage1 }`, title: 'blog-img-1', description: '' },
  { id: 29, src: `${ BloggerImage1 }`, title: 'blogger-img-1', description: '' },
  { id: 29, src: `${ BloggerImage2 }`, title: 'blogger-img-2', description: '' },
  { id: 29, src: `${ ThreePhonesSecTwo }`, title: 'sec-two-phones', description: '' },
  { id: 29, src: `${ FaqsCover }`, title: 'faqs', description: '' },
  { id: 29, src: `${ WhyPaymentLink }`, title: 'why-payment-link', description: '' },
  { id: 29, src: `${ PaymentLinkPg }`, title: 'payment-link-pg', description: '' },
  { id: 29, src: `${ SendMoneyLinkPg }`, title: 'send-money-link-pg', description: '' },
  { id: 29, src: `${ ShipmentLinkPg }`, title: 'shipment-link-pg', description: '' },
  { id: 29, src: `${ TvInternetLinkPg }`, title: 'tv-internet-link-pg', description: '' },
  { id: 29, src: `${ QRCodeLinkPg }`, title: 'link-pg-qr', description: '' },
  { id: 29, src: `${ Banner1 }`, title: 'banner-1', description: '' },
  { id: 29, src: `${ Banner2 }`, title: 'banner-2', description: '' },
  { id: 29, src: `${ Banner3 }`, title: 'banner-3', description: '' },
  { id: 29, src: `${ LinkUsPic }`, title: 'link-us-pic', description: '' },
  { id: 29, src: `${ QRCodePic }`, title: 'qr-code-pic', description: '' },
  { id: 29, src: `${ WatchPic }`, title: 'watch-pic', description: '' },
  { id: 29, src: `${ SobreImg1 }`, title: 'sobre1', description: '' },
  { id: 29, src: `${ SobreImg2 }`, title: 'sobre2', description: '' },
  { id: 29, src: `${ SobreImg3 }`, title: 'sobre3', description: '' },
  { id: 29, src: `${ CookiesCover }`, title: 'cookies-cover', description: '' },
  { id: 29, src: `${ PlayStoreIcon }`, title: 'play-store-icon', description: '' },
];

// FOR PUBLIC URLS
// const images = [
//   { id: 1, src: `${process.env.PUBLIC_URL}/`, title: 'logo', description: 'company logo' },
//   { id: 2, src: `${process.env.PUBLIC_URL}/`, title: 'norton', description: 'norton' }
// ];

function ImagesLoader(title) {
  try {
    return !!title && title.length > 0 ? (images.some(x => x.title === title) ? images.find(x => x.title === title).src : '') : '';

    // if (!!title && title.length > 0) {
    //   return images.some(x => x.title === title) ? images.find(x => x.title === title).src : ''
    // }
    // else {
    //   return '';
    // }
  }
  catch (e) {
    console.log(e)
  }

}

export default ImagesLoader;
