import React, { useState, useRef } from "react";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  ButtonToggle,
} from "reactstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { TextField } from "@material-ui/core";
import { FileDrop } from "react-file-drop";
import FileUploadLogo from "../../../../assets/images/uploadfile.png";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "./BankTransfer.css";
import { useTranslation } from "react-i18next";
export default function BankTransfer() {
  const [t] = useTranslation();
  const [tableData, setTableData] = useState([0, 1, 2, 3, 4, 5]);
  const [activePage, setActivePage] = useState(1);
  const fileInputRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [viewmodal, setViewModal] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          // padding: "12px 0px",
          marginTop: "50px",
          padding: "12px 5px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            xl={6}
            lg={6}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Bank Transfer")}
              </span>
            </div>
          </Col>
          <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>
          <Col
            xl={2}
            lg={2}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>
          <Col xl={2} lg={2}>
            <ButtonToggle
              style={{
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px",
              }}
            >
              <span>{t("Transfer")}</span>
            </ButtonToggle>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginTop: "2%",
          background: "#fff",
          width: "100%",
          padding: "2%",
          boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
          borderRadius: "5px",
        }}
      >
        <Row>
          <Col xl={6} style={{}}>
            <label
              htmlFor="Amount"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Balance available")}
            </label>
            <input
              disabled={true}
              style={{
                marginTop: "1%",
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "35px",
              }}
              id="Amount"
              //placeholder='Last Name'
              // type="dropdown"
              // value={values.lastName}
              // onChange={handleChange}
              // onBlur={handleBlur}
            />
          </Col>
          {/* <Col auto></Col> */}

          <Col xl={6} style={{}}>
            <label
              htmlFor="lastName"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Select Bank")}
            </label>
            <UncontrolledDropdown style={{ width: "100%", marginTop: "1%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>{t("Fixed")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>{t("Percentage")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col xl={6} style={{}}>
            <label
              htmlFor="Amount"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              IBAN
            </label>
            <input
              disabled={true}
              style={{
                marginTop: "1%",
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "35px",
              }}
              id="Amount"
              //placeholder='Last Name'
              // type="dropdown"
              // value={values.lastName}
              // onChange={handleChange}
              // onBlur={handleBlur}
            />
          </Col>
          <Col xl={6} style={{}}>
            <label
              htmlFor="lastName"
              style={{ display: "block", width: "100%", fontWeight: "600" }}
            >
              {t("Amount to Transfer")}
            </label>
            <UncontrolledDropdown style={{ width: "100%", marginTop: "1%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>{t("Fixed")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>{t("Percentage")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>
    </div>
  );
}
