import { Navbar, Nav, Image, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import Logo from "../../../assets/images/bayqi-logo.png";
import "./NavBar.css";
import { BsFillGearFill } from "react-icons/bs";
import { ImSwitch } from "react-icons/im";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router";
import { ImParagraphCenter } from "react-icons/im";
import { useTranslation } from "react-i18next";
function NavBar({ history, toggleDrawer, ...props }) {
  const [t] = useTranslation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      {/* <div
        class="navbar-header"
        style={{
          boxShadow: "0 4px 16px 0 rgba(0,0,0,0.1)",
          position: "fixed",
          zIndex: 3,
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col
            style={{
              // backgroundColor: "red",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <ImParagraphCenter
              onClick={toggleDrawer}
              style={{ marginLeft: "20px" }}
              size={"1.1rem"}
              color={"#333"}
            />

            <img
              style={{
                width: "45%",
                height: "45%",
                // backgroundColor: "green",
              }}
              src={Logo}
              // rounded
            />
          </Col>
          <Col
            // style={{ backgroundColor: "yellow" }}
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
          ></Col>
          <Col
            // style={{ backgroundColor: "orange" }}
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
          ></Col>
          <Col
            // style={{ backgroundColor: "green" }}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <Row
              style={
                {
                  // backgroundColor: "purple",
                }
              }
            >
              <Col
                xl={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  // paddingRight: "30px",
                }}
              >
                <BsFillGearFill
                  onClick={() => history.push("/AccountSettings")}
                  color={"grey"}
                  size={"1.1rem"}
                  style={{ marginRight: "20px" }}
                />
                <ImSwitch
                  style={{ marginRight: "20px" }}
                  onClick={toggle}
                  // color={"yellow"}
                  className={"switchClass"}
                  size={"1.1rem"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div> */}
      <Navbar
        style={{
          width: "100%",
          // height: "60px",
          paddingLeft: "1%",
          paddingRight: "1%",
          paddingTop: 0,
          padding: "0px",
          backgroundColor: "#fff",
          // backgroundColor: "green",
          // backgroundColor: "red",
          boxShadow: "0 4px 16px 0 rgba(0,0,0,0.1)",
          position: "fixed",
          zIndex: 3,

          // position: "-webkit-sticky",
          // position: "sticky",
          // top: "0px",
        }}
        collapseOnSelect
        expand="xl"
        bg="light"
        variant="light"
      >
        <div style={{ width: "80px", height: "60px" }}></div>
        <div
          style={{
            width: "100px",
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "red",
            // bottom: "51px",
            // backgroundColor: "red",
            padding: "5px 0px",
          }}
        >
          {/* <div style={{ width: "80px", height: "80px" }}> */}
          <img
            alt=""
            style={{
              width: "100%",
              height: "100%",
              // backgroundColor: "green",
            }}
            src={Logo}
            // rounded
          />
          {/* </div> */}
        </div>
        <Navbar.Toggle
          // style={{ backgroundColor: "red" }}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className={"navbarIcons"}>
            <Nav.Link
              style={{
                color: "#333",
                fontWeight: "bold",
                // fontSize: "0.9rem",
                marginRight: "10px",
              }}
            >
              <BsFillGearFill
                onClick={() => history.push("/panel/account-settings")}
                color={"grey"}
                size={"1.1rem"}
              />
            </Nav.Link>
            <Nav.Link
              style={{
                color: "#333",
                fontWeight: "bold",
                // fontSize: "0.9rem",
                marginRight: "10px",
              }}
              eventKey={2}
              href="#memes"
            >
              <ImSwitch
                onClick={toggle}
                // color={"yellow"}
                className={"switchClass"}
                size={"1.1rem"}
              />
            </Nav.Link>

            <Nav.Link
              style={{
                color: "#333",
                fontWeight: "bold",
                // fontSize: "0.9rem",
                marginRight: "10px",
              }}
              eventKey={2}
              href="/"
            >
              {t("Web App")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Logout?")}</ModalHeader>
        <ModalBody>
          <span style={{ color: "#333" }}>
            {t("Are you sure you want to log out of the site?")}
          </span>
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
        <ModalFooter>
          <Button color="danger" onClick={() => history.push("/login")}>
            {t("Yes")}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {t("No")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default withRouter(NavBar);
