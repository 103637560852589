import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FilterBox from "./FilterBox/FilterBox";
import "./FilterLeftSection.css";
import FilterPriceRange from "./FilterPriceRange/FilterPriceRange";
import FilterProductCategories from "./FilterProductCategories/FilterProductCategories";

function FilterLeftSection({ props }) {
  const [t] = useTranslation();
  const [filters, setFilters] = React.useState([]);
  const [filterBox, setFilterBox] = React.useState({
    title: t("Brand"),
    isSearch: true,
    list: [
      {
        name: "Huawei",
        count: "213",
        isChecked: false,
      },
      {
        name: "Nokia",
        count: "22",
        isChecked: false,
      },
      {
        name: "Sony",
        count: "65",
        isChecked: false,
      },
      {
        name: "Apple",
        count: "876",
        isChecked: false,
      },
      {
        name: "HTC",
        count: "33",
        isChecked: false,
      },
      {
        name: "Motorola",
        count: "10",
        isChecked: false,
      },
      {
        name: "Lenovo",
        count: "3",
        isChecked: false,
      },
    ],
  });

  const [internalMemoryBox, setInternalMemoryBox] = React.useState({
    title: t("Internal Memory"),
    isSearch: false,
    list: [
      {
        name: "128 GB",
        count: "213",
        isChecked: false,
      },
      {
        name: "64 GB",
        count: "22",
        isChecked: false,
      },
      {
        name: "32 GB",
        count: "65",
        isChecked: false,
      },
      {
        name: "34 GB",
        count: "876",
        isChecked: false,
      },
    ],
  });

  const [prevFilters, setPrevFilters] = React.useState([]);

  React.useEffect(() => {
    setPrevFilters(filters);
  }, [filters]);

  const setFiltersFunction = (filters) => {
    console.log("filters ", filters);
    try {
      const temp = prevFilters.concat(filters);
      setFilters(temp);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"filter-left-sec-wrap"}>
      <FilterProductCategories />
      <hr />
      <FilterPriceRange />
      <hr />
      <FilterBox filterBox={filterBox} setFilters={setFiltersFunction} />
      <hr />
      <FilterBox
        filterBox={internalMemoryBox}
        setFilters={setFiltersFunction}
      />
    </div>
  );
}

export default withRouter(connect(null)(FilterLeftSection));
