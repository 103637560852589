import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import "../FilterLeftSection.css";

function FilterProductCategories({ props }) {
  const [t] = useTranslation();
  const [opened, setOpened] = React.useState(true);
  const [categoriesTitle, setCategoriesTitle] = React.useState(
    t("Mobiles, Tablets & Accessories")
  );
  const [relatedCategories, setRelatedCategories] = React.useState([
    {
      title: t("Mobiles"),
      src: "/",
    },
    {
      title: t("Mobile Accessories"),
      src: "/",
    },
    {
      title: t("Tablets"),
      src: "/",
    },
  ]);

  //helpers
  // get list of categories
  const relatedCategoriesFunction = () => {
    try {
      return relatedCategories.map((cat, idx) => {
        return (
          <li key={idx} className={"filter-product-categories-li"}>
            <Link style={{ color: "inherit" }} to={cat.src}>
              {cat.title}
            </Link>
          </li>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"filter-product-categories-wrap"}>
      <div
        onClick={() => {
          setOpened(!opened);
        }}
        className={"filter-product-categories-head"}
      >
        <div className={"filter-product-categories-head-title"}>
          {categoriesTitle}
        </div>
        <div className={"filter-product-categories-head-icon"}>
          <i
            style={{
              fontSize: "1em",
              color: "inherit",
              width: "5%",
              fontWeight: "bold",
            }}
            className={`accordion-item__icon fa fa-angle-up ${
              opened ? "fa-flip-vertical" : ""
            }`}
          ></i>
        </div>
      </div>
      <div
        className={`filter-product-categories-body${opened ? "-opened" : ""}`}
      >
        <ul className={"filter-product-categories-ul"}>
          {relatedCategories &&
            relatedCategories.length > 0 &&
            relatedCategoriesFunction()}
        </ul>
      </div>
    </div>
  );
}

export default withRouter(FilterProductCategories);
