import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../../../config";
import UserPool from "../../../../../../UserPool";
import { AiFillEye } from "react-icons/ai";
import ReactCodeInput from "react-verification-code-input";
import { Auth } from "aws-amplify";
import { Form, InputGroup } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import "./SignInSignUp.scss";
import { EmojiSmile, ExclamationSquareFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const SignInSignUp = (props) => {
  const [t] = useTranslation();
  const [screenType, setscreenType] = useState("");
  const [email, setemail] = useState("");
  const [inputemail, setinputemail] = useState("");
  const [inputemailLogin, setinputemailLogin] = useState("");
  const [inputemailRegister, setinputemailRegister] = useState("");
  const [inputemailForgotPassword, setinputemailForgotPassword] = useState("");
  const [inputPassword, setinputPassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [verificationCode, setverificationCode] = useState("");
  const [status, setstatus] = useState("");
  const [modalStatus, setmodalStatus] = useState("");
  const [loginError, setloginError] = useState("");
  const [signupError, setsignupError] = useState("");
  const [forgotError, setforgotError] = useState("");
  const [verifyError, setverifyError] = useState("");
  const [passwordShown, setpasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     screenType: "",
  //     email: "",
  //     inputemailLogin: "",
  //     inputemailRegister: "",
  //     inputemailForgotPassword: "",
  //     inputPassword: "",
  //     password: "",
  //     confirmPassword: "",
  //     verificationCode: "",
  //     status: "",
  //     modalStatus: "",
  //     loginError: "",
  //     signupError: "",
  //     forgotError: "",
  //     verifyError: "",
  //     passwordShown: false,
  //     confirmPasswordShown: false,
  //   };
  // }

  useEffect(() => {
    const { screenType } = props;
    setscreenType(screenType);
    // this.setState({ screenType });
  }, [props.screenType]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { screenType } = this.props;
  //   if (screenType !== prevProps.screenType) {
  //     this.setState({ screenType });
  //   }
  // }

  // helpers

  const login = () => {
    setscreenType("login");
    setinputemail("");
    setinputPassword("");
    setsignupError("");
  };
  const okButton = () => {
    setscreenType(modalStatus);
  };
  const togglePasswordVisiblity = () => {
    if (passwordShown == true) {
      setpasswordShown(false);
    } else {
      setpasswordShown(true);
    }
  };
  const toggleConfirmPasswordVisiblity = () => {
    if (confirmPasswordShown == true) {
      setconfirmPasswordShown(false);
    } else {
      setconfirmPasswordShown(true);
    }
  };

  const signUp = () => {
    setinputemail("");
    setinputPassword("");
    setscreenType("create");
    setloginError("");
  };
  const ForgotPassword = () => {
    screenType("forgot-password");
  };
  const verifyUser = async () => {
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setstatus(t("You are registered"));
      setscreenType("confirm-msg");
    } catch (error) {
      console.log("error confirming sign up", error);
      setverifyError(t("*You entered wrong code"));
      setmodalStatus("verify");
    }
  };

  const updateinputEmailRegister = (evt) => {
    setemail(evt.target.value);
    setinputemailRegister(evt.target.value);
    setinputemailLogin("");
    setinputemailForgotPassword("");
    setsignupError("");
  };
  const updateEmailLogin = (evt) => {
    setemail(evt.target.value);
    setinputemailLogin(evt.target.value);
    setinputemailRegister("");
    setinputemailForgotPassword("");
    setloginError("");
    setforgotError("");
    setsignupError("");
  };
  const updateEmailForgotPassword = (evt) => {
    setemail(evt.target.value);
    setinputemailForgotPassword(evt.target.value);
    setforgotError("");
  };
  const signInuser = async () => {
    if (email === "" || password === "") {
      setloginError(t("*Email and password can not be empty."));
    } else {
      try {
        const user = await Auth.signIn(email, password);
        // TODO: save this user id as "CID" in localStorage
        localStorage.setItem("cid", user.attributes.sub);
        console.log(user);
        alert(t("logged in"));
        setinputemail("");
        setinputPassword("");
        setstatus(t("You are Logged In"));
        setscreenType("confirm-msg");
        setinputemailLogin("");
      } catch (error) {
        console.log("error signing in", error);
        setloginError(t("*You entered wrong Email or Password"));
        setinputemail("");
        setinputPassword("");
        setmodalStatus("login");
      }
    }
  };

  const signupUser = async () => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
      });
      console.log(user);
      setscreenType("verify");
    } catch (error) {
      if (error.message == t("Username cannot be empty")) {
        setsignupError(t("*Email cannot be empty"));
      } else if (
        error.message == t("Username should be either an email or a phone number.")
      ) {
        setsignupError(t("Enter valid email"));
      } else {
        setsignupError(error.message);
      }
      console.log("error signing up:", error);

      setinputPassword("");
      setmodalStatus("create");
    }
  };
  const updateForgetPassword = () => {
    if (email == "") {
      setforgotError(t("*Email can not be empty"));
    } else {
      Auth.forgotPassword(email)
        .then((data) => {
          setscreenType("update-forgot-password");
        })
        .catch((err) => {
          setforgotError(t("*InValid Email or Limit exceed try after some time."));
        });
    }
  };
  const changeForgetPassword = () => {
    Auth.forgotPasswordSubmit(email, verificationCode, password)
      .then((data) => {
        setstatus(t("Password changed."));
        setscreenType("confirm-msg");
      })
      .catch((err) => {
        if (verificationCode == "") {
          setforgotError(t("*Enter code"));
        } else {
          setforgotError(t("*InValid code"));
        }
      });
  };
  const updatePassword = (evt) => {
    setpassword(evt.target.value);
    setinputPassword(evt.target.value);
    setsignupError("");
  };

  const updateConfirmPassword = (evt) => {
    setconfirmPassword(evt.target.value);
  };

  const closeModal = (e) => {
    const { closeModal } = props;
    try {
      if (e.target.className.includes("trigger-close")) {
        setscreenType("");
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
    setinputPassword("");
    setinputemail("");
    setsignupError("");
    setloginError("");
  };
  const closeModal1 = () => {
    const { closeModal } = props;
    setscreenType("");
    closeModal();
  };
  const getLoginCredentials = (data) => {
    const { closeModal } = props;
    console.log("login cred ", data);
    if (data.number == "0300" && data.password == "123") {
      sessionStorage.setItem("number", data.number);
      sessionStorage.setItem("password", data.password);
      closeModal();
      toast.success(t("Successfully logged-In"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // this.signIn(data, (response) => {
    //   if (response.status) {
    //     console.log("user loggedIn")
    //     closeModal()
    //     sessionStorage.setItem("number", data.number)
    //     sessionStorage.setItem("password", data.password)
    //     toast.success('Successfully logged-In', {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    //   else {
    //     toast.error('Wrong Credentials', {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // })
  };

  const signIn = (obj, callback) => {
    let authenticationData = {
      Username: obj.number,
      Password: obj.password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: obj.number,
      Pool: UserPool,
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        let accessToken = result.getAccessToken().getJwtToken();
        console.log(accessToken);
        const idToken = result.getIdToken().getJwtToken();
        let refreshToken = result.refreshToken.token;
        let accessTimeExp = result.accessToken.payload.exp;
        sessionStorage.setItem("token", idToken);
        sessionStorage.setItem("email", obj.email);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("accessTimeExp", accessTimeExp);
        sessionStorage.setItem("uuid", result.idToken.payload.sub);

        axios
          .get(`${baseUrl}/${result.idToken.payload.sub}`)
          .then((customer) => {
            callback({
              status: true,
              response: result.idToken.payload,
            });
          })
          .catch((error) => {
            callback({
              status: false,
              response: error.message,
            });
          });
      },

      onFailure: function (err) {
        // alert(err.message || JSON.stringify(err));
        console.log(err);
        callback({
          status: false,
          message: err && err.response && err.response.data.message,
        });
      },
    });
  };

  const { isOpenModal } = props;
  const { history } = props;
  console.log("history", history);

  if (!isOpenModal) {
    return null;
  } else {
    return ReactDOM.createPortal(
      <div
        onClick={(e) => closeModal(e)}
        id={"modal-view"}
        className={`trigger-close custom-modal ${
          isOpenModal ? "custom-modal-open" : "custom-modal-close"
        }`}
      >
        {screenType === "login" || screenType === "" ? (
          <div
            className="custom-modal-dialog"
            style={{ maxWidth: "45em !important" }}
          >
            <div className="modal-content">
              <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div>
              <div
                className={"sign-in-modal-body d-flex flex-wrap flex-column"}
              >
                <div className={"sign-in-modal-body-left"}>
                  <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                    <div
                      className={"sign-in-head d-flex flex-column flex-wrap"}
                    >
                      <div className={"sign-in-head-title "}>
                        {t("LogIn to BayQi")}
                      </div>
                      {/* <div className={ "sign-in-head-sub-title" }>Enter phone no & password below.</div> */}
                      <Form>
                        <Form.Group className="mb-3" controlId="ControlInput1">
                          <Form.Label>{t("Email*")}</Form.Label>

                          <Form.Control
                            type="email"
                            value={inputemailLogin}
                            placeholder="Andy132@any.com"
                            onChange={updateEmailLogin}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="ControlInput3">
                          <Form.Label>{t("Password*")}</Form.Label>
                          <InputGroup className="inp">
                            <Form.Control
                              className="inp-grp"
                              type={passwordShown ? "text" : "password"}
                              onChange={updatePassword}
                            />
                            <i
                              onClick={() => togglePasswordVisiblity()}
                              className="eye-btn"
                            >
                              <AiFillEye className="eye" />
                            </i>
                          </InputGroup>
                          <a
                            onClick={() => ForgotPassword()}
                            className="verify"
                          >
                            {t("Forgot Password?")}
                          </a>
                        </Form.Group>
                        <p className="error-msg"> {loginError}</p>
                      </Form>
                      <button className="Login" onClick={() => signInuser()}>
                        {t("Log In")}
                      </button>
                      <br />

                      <div onClick={() => closeModal1()}>
                        {" "}
                        <a
                          // onClick={() => this.signUp()}
                          onClick={() => history.push("/register")}
                          className={"create-new-account"}
                        >
                          {t("Create New Account")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : screenType === "verify" || screenType === "" ? (
          <div
            className="custom-modal-dialog"
            style={{ maxWidth: "45em !important" }}
          >
            <div className="modal-content">
              <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div>
              <div
                className={"sign-in-modal-body d-flex flex-wrap flex-column"}
              >
                <div className={"sign-in-modal-body-left"}>
                  <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                    <div
                      className={"sign-in-head d-flex flex-column flex-wrap"}
                    >
                      <div className={"sign-in-head-title "}>
                        {t("Verify Your Account")}
                      </div>
                      {/* <div className={ "sign-in-head-sub-title" }>Enter phone no & password below.</div> */}
                      <ReactCodeInput
                        onChange={(e) => setverificationCode(e)}
                      />
                      <p className="error-msg"> {verifyError}</p>
                      <button
                        className="verify-btn"
                        onClick={() => verifyUser()}
                      >
                        {t("Verify")}
                      </button>
                    </div>

                    {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : screenType === "confirm-msg" || screenType === "" ? (
          <div
            className="custom-modal-dialog"
            style={{ maxWidth: "45em !important" }}
          >
            <div className="modal-content">
              <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div>
              <div
                className={"sign-in-modal-body d-flex flex-wrap flex-column"}
              >
                <div className={"sign-in-modal-body-left"}>
                  <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                    <div
                      className={"sign-in-head d-flex flex-column flex-wrap"}
                    >
                      <div className={"sign-in-head-title "}>
                        {t("Welcome!")}
                        <p>{status}</p>
                      </div>
                    </div>

                    {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : screenType === "forgot-password" || screenType === "" ? (
          <div
            className="custom-modal-dialog"
            style={{ maxWidth: "45em !important" }}
          >
            <div className="modal-content">
              <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div>
              <div
                className={"sign-in-modal-body d-flex flex-wrap flex-column"}
              >
                <div className={"sign-in-modal-body-left"}>
                  <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                    <div
                      className={"sign-in-head d-flex flex-column flex-wrap"}
                    >
                      <div className={"sign-in-head-title "}>
                        {t("Forgot Password")}
                      </div>
                      <Form>
                        <Form.Group className="mb-3" controlId="ControlInput1">
                          <Form.Label>{t("Email*")}</Form.Label>

                          <Form.Control
                            type="email"
                            value={inputemailForgotPassword}
                            placeholder="Andy132@any.com"
                            onChange={updateEmailForgotPassword}
                          />
                        </Form.Group>

                        <p className="error-msg"> {forgotError}</p>
                      </Form>

                      <button
                        onClick={() => updateForgetPassword()}
                        className="forgot-btn"
                      >
                        {t("Ok")}
                      </button>
                      <p className="already-acc-signup">
                        {t("Remember Password?")}{" "}
                        <a onClick={() => login()} className="already-login">
                          {t("Sign in")}
                        </a>
                      </p>
                    </div>

                    {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : screenType === "update-forgot-password" || screenType === "" ? (
          <div
            className="custom-modal-dialog"
            style={{ maxWidth: "45em !important" }}
          >
            <div className="modal-content">
              <div className={"custom-modal-close-btn"}>
                <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
              </div>
              <div
                className={"sign-in-modal-body d-flex flex-wrap flex-column"}
              >
                <div className={"sign-in-modal-body-left"}>
                  <div className={"sign-in-wrap d-flex flex-column flex-wrap"}>
                    <div
                      className={"sign-in-head d-flex flex-column flex-wrap"}
                    >
                      <div className={"sign-in-head-title "}>
                        {t("Forgot Password")}
                      </div>
                      <Form>
                        <p>{t("Code sent to your email")}</p>
                        <ReactCodeInput
                          onChange={(e) => setverificationCode(e)}
                        />
                        <p className="error-msg"> {forgotError}</p>
                      </Form>
                      <Form.Group className="mb-3" controlId="ControlInput3">
                        <Form.Label>{t("Password*")}</Form.Label>
                        <InputGroup className="inp">
                          <Form.Control
                            className="inp-grp"
                            type={passwordShown ? "text" : "password"}
                            value={inputPassword}
                            onChange={updatePassword}
                          />
                          <i
                            onClick={() => togglePasswordVisiblity()}
                            className="eye-btn"
                          >
                            <AiFillEye className="eye" />
                          </i>
                        </InputGroup>
                      </Form.Group>
                      <button
                        onClick={() => changeForgetPassword()}
                        className="verify-btn"
                      >
                        {t("Ok")}
                      </button>
                    </div>

                    {/* <SignInForm
                            getLoginCredentials={ this.getLoginCredentials }
                          /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          screenType === "create" && (
            <div
              className="custom-modal-dialog"
              style={{ maxWidth: "45em !important" }}
            >
              <div className="modal-content">
                <div className={"custom-modal-close-btn"}>
                  <i className="fa fa-times custom-modal-close-btn-icon trigger-close"></i>
                </div>
                <div className={"sign-up-modal-body d-flex flex-wrap flex-row"}>
                  <div className={"sign-up-modal-body-left"}>
                    <div>
                      <div
                        className={
                          "sign-up-head d-flex flex-column flex-wrap mb-3"
                        }
                      >
                        <div className={"sign-in-head-title "}>
                          {t("Sign Up to BayQi")}
                        </div>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput1"
                          >
                            <Form.Label>{t("Email*")}</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Andy132@any.com"
                              onChange={updateinputEmailRegister}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput3"
                          >
                            <Form.Label>{t("Full Name*")}</Form.Label>
                            <Form.Control type="text" />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput3"
                          >
                            <Form.Label>{t("Password*")}</Form.Label>
                            <InputGroup className="inp">
                              <Form.Control
                                className="inp-grp"
                                type={passwordShown ? "text" : "password"}
                                onChange={updatePassword}
                              />
                              <i
                                onClick={() => togglePasswordVisiblity()}
                                className="eye-btn"
                              >
                                <AiFillEye className="eye" />
                              </i>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="ControlInput3"
                          >
                            <Form.Label>{t("Confirm Password*")}</Form.Label>
                            <InputGroup className="inp">
                              <Form.Control
                                className="inp-grp"
                                type={
                                  confirmPasswordShown ? "text" : "password"
                                }
                                value={confirmPassword}
                                onChange={updateConfirmPassword}
                              />
                              <i
                                onClick={() => toggleConfirmPasswordVisiblity()}
                                className="eye-btn"
                              >
                                <AiFillEye className="eye" />
                              </i>
                            </InputGroup>
                          </Form.Group>
                          <p className="error-msg"> {signupError}</p>
                        </Form>

                        <button onClick={() => signupUser()} className="signup">
                          {t("Sign Up")}
                        </button>

                        <p className="already-acc">
                          {t("Already have an account?")}{" "}
                          <a onClick={() => login()} className="already-login">
                            {t("Sign in")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>,
      document.getElementById("modal-root")
    );
  }
};

export default withRouter(SignInSignUp);
