import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { baseUrl } from "../../../config";
import { categories, bagItems } from "../../../DummyData";
import { setCartItems } from "../../../redux/Cart/cart.actions";
import { setCategories } from "../../../redux/Categories/categories.actions";
import Toaster from "../../Toasters/Toaster";
import { ClientSupportBar } from "./ClientSupportBar";
import { NavBar } from "./NavBar";

const Header = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //fetch categories and cart items
    axios
      .get(`${baseUrl}/categories`)
      .then((categoriesResponse) => {
        // console.log("/categories ", categoriesResponse)
        if (categoriesResponse.status === 200) {
          // const cats = categoriesResponse.data.data.categories;
          dispatch(setCategories(categories));
          // dispatch(setCategories(cats))
        } else {
          // toast.error("Categories not found", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })
      .catch((error) => {
        // toast.error("Categories not found", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        console.log("error ", error.message);
      });
    dispatch(setCartItems(bagItems));
  }, [dispatch]);

  return (
    <>
      <ClientSupportBar />
      <NavBar />
      <Toaster />
    </>
  );
};

export default Header;
