import authTypes from './auth.types';

const defaultState = {
  isSignedIn: false,
  signInError: null,
  isSignedUp: false,
  signUpError: null,
  isSignedOut: false,
  signOutError: null,
  isOpenModal: false,
  modelView: ''
};

export function authReducer(state = defaultState, action) {
  switch (action.type) {
    case authTypes.IS_MODAL_OPENED:
      return {
        ...state,
        isOpenModal: action.payload.isOpenModal,
        modelView: action.payload.modelView
      };
    case authTypes.SIGNIN_REQUEST:
      return {
        ...state
      };

    case authTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        isSignedIn: true,
        modalView: 'signin'
      };

    case authTypes.SIGNIN_FAILURE:
      return {
        ...state
      };

    case authTypes.SIGNOUT_REQUEST:
      return {
        ...state
      };

    case authTypes.SIGNOUT_SUCCESS:
      return {
        ...state
      };

    case authTypes.SIGNOUT_FAILURE:
      return {
        ...state
      };

    case authTypes.SIGNUP_REQUEST:
      return {
        ...state
      };

    case authTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isSignedUp: true,
        modalView: 'signup'
      };

    case authTypes.SIGNUP_FAILURE:
      return {
        ...state
      };


    default:
      return state;
  }
}
