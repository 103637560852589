import React from "react";
import "./Home.css";
import CardStats from "./CardStats";
import Graphs from "./Graphs";
export default function Home() {
  return (
    <div className="fill-window">
      <CardStats />
      <Graphs />
      {/* <Graphs /> */}
    </div>
  );
}
