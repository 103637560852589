import React from "react";
import { useTranslation } from "react-i18next";
import { ImagesLoader } from "../../../../helpers";
import "./WhyPaymentLink.scss";
import StartImg3 from "../../../../assets/images/03lastlinkpayment.png"

function WhyPaymentLink() {
  const [t] = useTranslation();
  return (
    <div id={"sobrebayqi"}>
      <div style={{ height: "2rem", width: "100%" }}></div>

      <div className={"d-flex flex-row flex-wrap m-auto"}>
        <div className={"col-xl-6 col-lg-6 col-md-12 col-sm-12"}>
          <div className={"why-payment-left"}>
            
              <img src={StartImg3} style={{objectFit:"contain"}} className={"why-payment-left-img"} alt="" />
            
          </div>
        </div>
        <div className={"col-xl-6 col-lg-6 col-md-12 col-sm-12"}>
          <div className={"why-payment-right"}>
            <div className={"head-title"}>
              {t("Por que escolher um link de pagamento")}
            </div>
            <ul className={"why-payment-ul"}>
              <li className={"why-payment-li"}>
                <div className={"li-title-wrap"}>
                  <i className="fa fa-check-circle"></i>
                  <div className={"title"}>{t("Vende onde estiver")}</div>
                </div>
                <div className={"li-paragraph"}>
                  {t("Do sofá, escritório ou pessoalmente, partilha o link de pagamento pelo WhatsApp, Facebook, Instagram, TikTok ou por qualquer rede social.")}
                </div>
              </li>
              <li className={"why-payment-li"}>
                <div className={"li-title-wrap"}>
                  <i className="fa fa-check-circle"></i>
                  <div className={"title"}>{t("Só precisa do telemóvel.")}</div>
                </div>
                <div className={"li-paragraph"}>
                  {t("Cria o link de pagamento no Gateway de Pagamento BayQi, em apenas alguns segundos e partilha em qualquer rede social.")}
                </div>
              </li>
              <li className={"why-payment-li"}>
                <div className={"li-title-wrap"}>
                  <i className="fa fa-check-circle"></i>
                  <div className={"title"}>{t("Controla as vendas")}</div>
                </div>
                <div className={"li-paragraph"}>
                  {t("Acompanha as transações em tempo real e faz gestão do negócio, gerindo as suas vendas e fecho diariamente.")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhyPaymentLink;
