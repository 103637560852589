import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { ImagesLoader } from "../../../helpers";
import "./BlogDetailPage.scss";
import ProductsSlider from "../LandingPage/Section5/ProductCardsSlider/Slider";
import { blogs } from "../../../DummyData";
import { useTranslation } from "react-i18next";

const BlogDetailPage = (props) => {
  const [t] = useTranslation();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // state = {
  //   comment: "",
  //   rating: 0,
  // };

  const ratingChanged = (newRating) => {
    // console.log(newRating);
    setRating(newRating);
    // this.setState({ rating: newRating });
  };

  const postComment = (e) => {
    e.preventDefault();
  };

  const { title, description, author, authorBio, blogRating } = props.blog;
  return (
    <>
      <div
        className={"d-flex flex-wrap flex-row mt-4"}
        style={{ fontFamily: "Sen, sans-serif" }}
      >
        <div className={"col col-lg-9 col-md-9 col-sm-12"}>
          <div className={"blog-wrap mb-5"}>
            <div className={"blog-title-wrap"}>
              <h2 className="blog-title">
                <strong>
                  BayQi holds the 1st Edition of the Digital Book Fair
                </strong>
              </h2>
              <p className="card-text mr-auto">
                <small className="text-muted">Last updated 3 mins ago</small>
              </p>
              <img
                style={{ width: "100%", height: "auto" }}
                src={`${ImagesLoader("blog-img-1")}`}
                alt="..."
              />
            </div>

            <div className={"blog-body mt-5 mb-5"}>
              <p className="blog-text">
                On April 23, World Book Day is celebrated in several countries
                with book fairs. However, due to the COVID-19 Pandemic, and so
                that lovers of national literature do not feel the absence of
                the fair, BayQi will hold the 1st edition of the Digital Book
                Fair starting on April 23 until May 8 of the year in course.
              </p>
              <p>
                The aim of the fair is to boost Angolan literature, encourage
                reading, and enhance literary works, with the launch of Cremilda
                Lima's children's literary work highlighting the opening of the
                fair. During the fair, readers will have the opportunity to
                participate in the review of literary works, talk shows, and
                lectures by renowned national writers.
              </p>
              <p>
                Cabinda ao Cunene readers will have books they want at a
                discount of up to 25%, being able to buy through the website
                www.bayqi.com , or through the BAYQI application, in the comfort
                of their home, or anywhere else, with delivery to the chosen
                place, with a period of 24 hours for the Province of Luanda and
                2 weeks for the other Provinces.
              </p>
            </div>
          </div>
        </div>
        <div className={"col col-lg-3 col-md-3 col-sm-12"}>
          <div className="card">
            <div
              className={"blogger-img card-img-top"}
              style={{
                backgroundImage: `url(${ImagesLoader("blogger-img-2")})`,
              }}
            ></div>
            {/*<img src={`${ ImagesLoader('blogger-img-1') }`} className="card-img-top" alt="..."/>*/}
            <div className="card-body" style={{ textAlign: "center" }}>
              <h5 className="card-title">{author}</h5>
              <p className="card-text">{authorBio}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"blog-detail-rating-wrap"}>
        <div className={"rating-title"}>{t("Rate this article!")}</div>
        <div className={"rating-star"}>
          <ReactStars
            count={blogRating}
            onChange={ratingChanged}
            size={24}
            activeColor="#ffd700"
          />
        </div>
      </div>

      <ul className="social-icons mb-5">
        <li>
          <a className="facebook" href={"www.facebook.com"}>
            <i className="fa fa-facebook"></i>
          </a>
        </li>
        <li>
          <a className="twitter" href={"www.twitter.com"}>
            <i className="fa fa-twitter"></i>
          </a>
        </li>
        <li>
          <a className="linkedin" href={"www.linkedin.com"}>
            <i className="fa fa-linkedin"></i>
          </a>
        </li>
        <li>
          <a className="pinterest" href={"www.pinterest.com"}>
            <i className="fa fa-pinterest"></i>
          </a>
        </li>
      </ul>

      <div className={"related-articles-title"}>{t("Related Articles")}</div>

      <ProductsSlider>
        {blogs &&
          blogs.map((blog, idx) => {
            return (
              <div
                style={{ margin: "0 1em" }}
                className={"d-flex flex-wrap related-blog-wrap"}
              >
                <div
                  className={"related-blog-img"}
                  style={{
                    backgroundImage: `url(${ImagesLoader("lv-model")})`,
                  }}
                ></div>
                <div className={"related-blog-body"}>
                  <div className={"related-blog-title"}>
                    On April 23, World Book Day is celebrated in several
                    countries with book fairs. However, due to the COVID-19
                    Pandemic, and so that lovers of national literature do not
                    feel the absence of the fair
                  </div>
                  <a className={"related-blog-read-more"}>
                    {t("Read More")} <i className={"fa fa-chevron-right"}></i>
                  </a>
                </div>
              </div>
            );
          })}
      </ProductsSlider>

      <hr />

      <div className={"comment-wrap container mb-5"}>
        <h4>{t("Leave an answer")}</h4>
        <br />
        <form onSubmit={(e) => postComment(e)}>
          <div className="form-group">
            <label className={"comment-field"} htmlFor="Comment">
              {t("Comment")}
            </label>
            <textarea
              rows="5"
              type="text"
              className="form-control"
              id="Comment"
              onChange={(event) => setComment(event.target.value)}
            />
          </div>

          <div className={"row"}>
            <div className="form-group col-md-6">
              <div className="form-group">
                <label className={"name-field"} htmlFor="Name">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  placeholder={"Jhon linken"}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="form-group">
                <label className={"email-field"} htmlFor="Email">
                  {t("Email")}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  placeholder={"jhonlinken@info.com"}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
          </div>

          {/*<div className="form-group">*/}
          {/*  <label className={ "comment-field" } htmlFor="WebSite">Web Site</label>*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    id="WebSite"*/}
          {/*    aria-describedby="WebSite"*/}
          {/*    placeholder={ "www.website.com" }*/}
          {/*    onChange={ (event) => this.setState({ website: event.target.value }) }*/}
          {/*  />*/}
          {/*</div>*/}
          <button className={"btn btn-primary"}>{t("Post Comment")}</button>
        </form>
      </div>
    </>
  );
};
export default BlogDetailPage;
