import filtersTypes from './filters.types';

export const setFilters = filters => ({
  type: filtersTypes.SET_FILTERS,
  payload: filters
});

export const fetchFilters = filters => ({
  type: filtersTypes.FETCH_FILTERS,
  payload: filters
});

export const addFilter = filter => ({
  type: filtersTypes.ADD_FILTER,
  payload: filter
});

export const removeFilter = filter => ({
  type: filtersTypes.REMOVE_FILTER,
  payload: filter
});

export const editFilter = filter => ({
  type: filtersTypes.EDIT_FILTER,
  payload: filter
});

export const resetFilters = () => ({
  type: filtersTypes.RESET_FILTERS
});

export const reduceFilters = filters => ({
  type: filtersTypes.REDUCE_FILTERS,
  payload: filters
});
