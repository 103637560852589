import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImagesLoader from "../../../helpers/ImagesLoader";
import axios from "axios";
import { baseUrl } from "../../../config";
import { useTranslation } from "react-i18next";

const NewBlogCard = (props) => {
  const [t] = useTranslation();
  const [card, setcard] = useState({});
  const [blogdata, setBlogData] = useState([]);
  // state = {
  //   card: {}
  //   ,blogdata:[]
  // }

  useEffect(() => {
    const { card } = props;
    setcard(card);
    try {
      axios
        .get(`${baseUrl}/blogs?searchBy=`)
        .then((res) => {
          const { success, data } = res.data;
          if (success) {
            setBlogData(data);
            console.log(
              data,
              "this is rrespone",
              data[0].category,
              blogdata[1].image
            );
          } else {
            console.log("something went wrong");
          }
        })
        .catch((Ex) => {});
    } catch (e) {
      console.log(e);
    }

    // return () => {
    //   second;
    // };
  }, []);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { card } = this.props;
  //   if (JSON.stringify(card) !== JSON.stringify(prevProps.card)) {
  //     this.setState({ card })
  //   }
  // }
  const { getBlogDetail } = props;
  return (
    <>
      {blogdata.map((data) => (
        <div className="card mb-3 w-100">
          <div className="row no-gutters">
            <div className="col-md-5">
              <img
                style={{ width: "100%", height: "auto" }}
                src={`${ImagesLoader("lv-model")}`}
                alt="..."
              />
            </div>
            <div className="col-md-7">
              <div className="card-body">
                {/* <h5 className="card-title">Blog title <strong>({ data.category } : { description })</strong></h5> */}
                <h5 className="card-title">
                  {data.title} <strong>({data.category} )</strong>
                </h5>
                <p className="card-text">{data.description}...</p>
                <div className={"d-flex flex-row flex-wrap mt-2"}>
                  <p className={"card-text mr-auto"}>
                    <small>
                      <Link
                        onClick={() => getBlogDetail()}
                        to={`/blog/${data.category}-${data.title}`}
                        className=""
                      >
                        {t("Read More")} <i className={"fa fa-chevron-right"}></i>
                      </Link>
                    </small>{" "}
                  </p>
                  {/* <p className={"card-text mr-auto"}><small>
                  <Link  className="">
                    Read More <i className={"fa fa-chevron-right"}></i>
                  </Link></small> </p>  */}
                  <p className="card-text">
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewBlogCard;
