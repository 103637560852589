import React       from 'react';
import DownloadApp from '../../../../AppButtons/DownloadApp';
import "./SectionTwo.scss";

function DownloadAppSection (props) {
  const { hideTitleOne, titleOne, titleTwo, titleThree, img, backgroundStyle } = props;

  return (
    <div className={ "d-flex flex-row flex-wrap mb-4" } style={ backgroundStyle }>
      <div className={ "col-sm-12 col-md-7 col-lg-7 mt-4 mb-4" }>
        <div className={ "left-sec-wrap" }>
          { hideTitleOne && <div className={ "title-one" }>{ titleOne }</div> }
          <div className={ "title-two" }>{ titleTwo }</div>
          <div className={ "title-three" }>{ titleThree }</div>
          <div className={ "btn-wrapper" }>
            <DownloadApp/>
          </div>
        </div>
      </div>
      <div className={ "col-sm-12 col-md-5 col-lg-5 mt-4 mb-4" }>
        <div
          className={ "sec-two-img" }
          style={ {
            backgroundImage: `url(${ img })`
          } }>
        </div>
      </div>
    </div>
  )
}

export default DownloadAppSection;


