import React, { useState, useEffect, useRef } from 'react';
import { MenuItem, SubMenu, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { userLinks } from "../../../../../DummyData";

import './CategoryButton.css';

const CategoryButton = ({ history, categories }) => {
  const [utilizadoresMenu, setUtilizadoresMenu] = useState('closed');
  const [links, setLinks] = useState([]);
  const utilizadoresRef = useRef(null);

  useEffect(() => {
    setLinks(userLinks);
  }, []);

  const getCategoriesLinks = () => {
    try {
      if (!!links && links.length > 0) {
        return links.map((link, idx) => (
          <SubMenu
            key={idx}
            label={link.title}
          >
            {link.links.map((l, index) => (
              <MenuItem key={index} onClick={() => history.push(l.link)}>
                {l.title}
              </MenuItem>
            ))}
          </SubMenu>
        ));
      } else {
        return '';
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={'category-btn-wrapper'}>
      <div className={"nav-links-btn"} ref={utilizadoresRef}
           onMouseEnter={() => setUtilizadoresMenu('open')}>
        Utilizadores
        <i className="fa fa-angle-down ml-3"></i>
      </div>

      <ControlledMenu
        state={utilizadoresMenu}
        anchorRef={utilizadoresRef}
        onMouseLeave={() => setUtilizadoresMenu('closed')}
        onClose={() => setUtilizadoresMenu('closed')}>
        {links && getCategoriesLinks()}
      </ControlledMenu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories
});

export default withRouter(connect(mapStateToProps)(CategoryButton));
