import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { baseUrl } from "../../../config";
import { categories } from "../../../DummyData";
import { ImagesLoader } from "../../../helpers";
import CatProdSec from "./CatProdSec/CatProdSec";
import SectionTwo from "./Section2/SectionTwo";
import SectionThree from "./Section3/SectionThree";
import WhyPaymentLink from "./WhyPaymentLink/WhyPaymentLink";
import StartImg from "../../../assets/images/sec-threee-phones.png"
import StartImg2 from "../../../assets/images/02second.png"

const LandingPage = () => {
  const { t, i18n } = useTranslation()
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // state = {
  //   categories: [],
  //   loading: true,
  // };
  const storeState = useSelector((state) => state.categories);

  useEffect(() => {
    window.scroll(0, 0);
    try {
      axios.get(`${baseUrl}/products`).then((categoriesResponse) => {
        setCategories(categories);
        setLoading(false);
        // this.setState({ categories: categories, loading: false });
      });
    } catch (e) {
      console.log("warning: ", e.message);
      setLoading(false);
      // this.setState({ loading: false });
    }
  }, []);

  return (
    <div className={"container-fluid pl-3 pr-3"}>
      <SectionTwo
        hideTitleOne={true}
        titleOne={t("DEIXE A CARTEIRA EM CASA")}
        titleTwo={t("DESCOBRE TUDO QUE A BAYQI PODE FAZER POR VOCÊ")}
        titleThree={
          t("Pagar com BayQi é fácil, prático e seguro. Descubra os três métodos para Pagar com BayQi: Em lojas físicas, através de QR Code ou Link de Pagamento.")
        }
        img={StartImg}
        backgroundStyle={{
          backgroundImage: "inherit",
          color: "inherit",
        }}
      />

      <SectionThree />

      <SectionTwo
        hideTitleOne={false}
        titleOne={""}
        titleTwo={t("TRANSFIRA DINHEIRO PARA QUEM QUISER.")}
        titleThree={t("Receba e pague amigos sem nenhuma taxa.")}
        img={StartImg2}
        backgroundStyle={{
          backgroundImage:
            "linear-gradient(to right, #5db7fe ,#5db7fe , white)",
          color: "white",
        }}
      />

      {/* <CatProdSec type={"shop"} /> */}

      <WhyPaymentLink />
    </div>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     categories: state.categories.categories,
//   };
// };

export default LandingPage;
