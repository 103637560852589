// import 'bootstrap/dist/css/bootstrap.min.css';
// import $                                   from 'jquery';
// import Popper                              from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	withRouter,
} from "react-router-dom";
import "./App.css";
import { BaixeAppPage } from "./components/Main/BaixeAppPage";
import { BlogPage } from "./components/Main/BlogPage";
import { CanteensLinkPage } from "./components/Main/CanteensLinkPage";
import { CheckoutProcess } from "./components/Main/CheckoutProcess";
import { CookiesPolicies } from "./components/Main/CookiesPolicies";
import { FAQs } from "./components/Main/FAQsPage";
import { LandingPage } from "./components/Main/LandingPage";
import { PaymentLinkPage } from "./components/Main/PaymentLinkPage";
import { PayTvInternetPage } from "./components/Main/PayTvInternetPage";
import {PrivacyPolicies} from "./components/Main/PrivacyPolicies";
import { ProductDetailPage } from "./components/Main/ProductDetailPage";
import { QRCodePage } from "./components/Main/QRCodePage";
import { RestaurantLinkPage } from "./components/Main/RestaurantLinkPage";
import { SearchResultPage } from "./components/Main/SearchResultPage";
import { SendMoneyPage } from "./components/Main/SendMoneyPage";
import { ShipmentPage } from "./components/Main/ShipmentPage";
import { ShopLinkPage } from "./components/Main/ShopLinkPage";
import { ShoppingMallsLinkPage } from "./components/Main/ShoppingMallsLinkPage";
import { SobrePage } from "./components/Main/SobrePage";
import { SuperMarketLinkPage } from "./components/Main/SuperMarketLinkPage";
import { TermsConditions } from "./components/Main/TermsConditions";
import { Register } from "./components/Main/Register";
import { Payment } from "./components/Main/Payment";
import PanelRouter from "./components/Panel/Navigation/PanelRouter";
import Home from "./components/Panel/Pages/Home/Home";
import Transactions from "./components/Panel/Pages/Transactions/Transactions";
import AccountSettings from "./components/Panel/Pages/AccountSettings/AccountSettings";
import PrivateRoute from "./components/Panel/Navigation/PrivateRoute";
import TransactionHistory from "./components/Panel/Pages/TransactionHistory/TransactionsHistory";
import Swing from "./components/Panel/Pages/Swing/Swing";
import BankTransfer from "./components/Panel/Pages/BankTransfer/BankTransfer";
import BankTransferHistory from "./components/Panel/Pages/BankTransferHistory/BankTransferHistory";
import Categories from "./components/Panel/Pages/Categories/Categories";
import Products from "./components/Panel/Pages/Products/Products";
import Definitions from "./components/Panel/Pages/Definitions/Definitions";
import GenerateLink from "./components/Panel/Pages/GenerateLink/GenerateLink";
import PayPerLink from "./components/Panel/Pages/PayPerLink/PayPerLink";
import PayByCatalog from "./components/Panel/Pages/PaymentByCatalog/PayByCatalog";
import HeaderWithDrawer from "./components/Panel/Navigation/HeaderWithDrawer";
// import Android from "../src/components/Android"
// import Apple from "../src/components/Apple"
import Errorpage from "./404page";

import { FooterV2 } from "./components/MainLayout/Footer";
import { Header } from "./components/MainLayout/Header";
// import Amplify, { Auth } from "aws-amplify";
// import awsconfig from "./aws-exports";
// Amplify.configure(awsconfig);
require("bootstrap");

const routes = [
	// { path: "/shipment", component: ShipmentPage, exact: true },
	// { path: "/canteens", component: CanteensLinkPage, exact: true },
	// { path: "/payment", component: PaymentLinkPage, exact: true },
	// { path: "/pay-tv-internet", component: PayTvInternetPage, exact: true },
	// { path: "/qr-code", component: QRCodePage, exact: true },
	// { path: "/restaurant", component: RestaurantLinkPage, exact: true },
	// { path: "/send-money", component: SendMoneyPage, exact: true },
	// { path: "/shop", component: ShopLinkPage, exact: true },
	// { path: "/shopping-malls", component: ShoppingMallsLinkPage, exact: true },
	// { path: "/markets", component: SuperMarketLinkPage, exact: true },
	// {
	//   path: "/search-product/:searchString",
	//   component: SearchResultPage,
	//   exact: true,
	// },
	// {
	//   path: "/categories/:searchString",
	//   component: SearchResultPage,
	//   exact: true,
	// },
	// { path: "/products/:id", component: ProductDetailPage, exact: true },
	// { path: "/order-payment", component: Payment, exact: true },
	// { path: "/register", component: Register, exact: true },
	// { path: "/cart", component: CheckoutProcess, exact: true },
	{ path: "/blog", component: BlogPage, exact: true },
	{ path: "/faqs", component: FAQs, exact: true },
	{ path: "/baixeapp", component: BaixeAppPage, exact: true },
	{ path: "/sobrebayqi", component: SobrePage, exact: true },
	{ path: "/terms-conditions", component: TermsConditions, exact: true },
	{ path: "/politica-de-privacidade", component: PrivacyPolicies, exact: true },
	{ path: "/cookies-Policies", component: CookiesPolicies, exact: true },
	{ path: "/", component: LandingPage, exact: true },
];
const panelRoutes = [
	{
		path: "/panel",
		exact: true,
		name: "Home",
		component: Home,
	},
	{
		path: "/panel/transactions",
		exact: true,
		name: "Transactions",
		component: Transactions,
	},
	{
		path: "/panel/transaction-history",
		exact: true,
		name: "TransactionHistory",
		component: TransactionHistory,
	},
	{
		path: "/panel/swing",
		exact: true,
		name: "Swing",
		component: Swing,
	},
	{
		path: "/panel/account-settings",
		exact: true,
		name: "AccountSettings",
		component: AccountSettings,
	},
	{
		path: "/panel/bank-transfer",
		exact: true,
		name: "BankTransfer",
		component: BankTransfer,
	},
	{
		path: "/panel/bank-transfer-history",
		exact: true,
		name: "BankTransferHistory",
		component: BankTransferHistory,
	},
	{
		path: "/panel/categories",
		exact: true,
		name: "Categories",
		component: Categories,
	},
	{
		path: "/panel/products",
		exact: true,
		name: "Products",
		component: Products,
	},
	{
		path: "/panel/definitions",
		exact: true,
		name: "Definitions",
		component: Definitions,
	},
	{
		path: "/panel/generate-link",
		exact: true,
		name: "GenerateLink",
		component: GenerateLink,
	},
	{
		path: "/panel/pay-per-link",
		exact: true,
		name: "PayPerLink",
		component: PayPerLink,
	},
	{
		path: "/panel/pay-by-catalog",
		exact: true,
		name: "PayByCatalog",
		component: PayByCatalog,
	},
];

const panelRouteRegex = new RegExp("/panel");

const App = (props) => {
	// alert(props.location.pathname);
	return (
		<div className={"App"}>
			{props.location.pathname !== "/register"  && 
			panelRouteRegex.test(props.location.pathname) === false ? (
				<Header />
			) : null}

			{panelRouteRegex.test(props.location.pathname) === true ? (
				<HeaderWithDrawer>
					<Switch>
						{panelRoutes.map((route, index) => {
							return route.component ? (
								<PrivateRoute
									key={index}
									path={route.path}
									exact={route.exact}
									name={route.name}
									Component={route.component}
								/>
							) : null;
						})}
					</Switch>
				</HeaderWithDrawer>
			) : null}

			<Switch>
				{routes.map((route, index) => {
					return route.component ? (
						<Route
							key={index}
							exact={route.exact}
							path={route.path}
							component={route.component}
						/>
					) : null;
				})}
			</Switch>

			{props.location.pathname !== "/register" &&
			panelRouteRegex.test(props.location.pathname) === false ? (
				<FooterV2 />
			) : null}
		</div>
	);
};
export default withRouter(App);
