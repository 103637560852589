import axios from "axios";
import { t } from "i18next";
import React from "react";
import { baseUrl } from "../../../config";
import { products } from "../../../DummyData";
import CatProdSec from "../LandingPage/CatProdSec/CatProdSec";
import Products from "../LandingPage/Section5/SectionFive";
import "./RestaurantLinkPage.scss";

function RestaurantLinkPage({ props }) {
  const [relatedProducts, setRelatedProducts] = React.useState([]);
  const [fetchRelatedProductsLoading, setFetchRelatedProductsLoading] =
    React.useState([]);

  React.useEffect(() => {
    window.scroll(0, 0);
    try {
      //fetch related products
      axios
        .get(`${baseUrl}/products`)
        .then((relatedProductsResponse) => {
          console.log("relatedProductsResponse ", relatedProductsResponse);
          setRelatedProducts(products);
          setFetchRelatedProductsLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error.message);
          setRelatedProducts([]);
          setFetchRelatedProductsLoading(false);
        });
    } catch (e) {
      console.log("warning: ", e.message);
    }
  }, []);

  return (
    <div className={"shop-link-wrapper"}>
      <CatProdSec type={"restaurant"} />
      <div className={"product-detail-page-more-products"}>
        {fetchRelatedProductsLoading ? (
          <div>{t("loading..")}</div>
        ) : !!relatedProducts && relatedProducts.length > 0 ? (
          <Products
            title={t("Related Products")}
            loading={fetchRelatedProductsLoading}
            products={relatedProducts}
            activeCategory={""}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default RestaurantLinkPage;
