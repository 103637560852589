import React, { useState } from "react";
import "./Transactions.css";
import { Row, Col, Table, Pagination, ListGroup, Form } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
const ModalLayout = () => {
  const [t] = useTranslation();
  <div>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Order Number")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>#5162</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Date/time")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>26-Dec-2020</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Order Status")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>Complete</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Full Name")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>Florencia</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Phone Number")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>+8612616712</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          {t("Delivery Address")}
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>
          456 Rasheed Pararirie
        </span>
      </Col>
    </Row>
    <Row style={{ marginTop: "1%" }}>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          disabled={true}
          style={{ fontSize: "0.8rem" }}
          type="checkbox"
          label="Check me out"
        />
      </Form.Group>
    </Row>
  </div>
};
export default function Transactions() {
  const [t] = useTranslation();
  const [tableData, setTableData] = useState([0, 1, 2, 3, 4, 5]);
  const [selectedFilter, setSelectedFilter] = useState("Show All Orders");
  const [value, setValue] = React.useState(null);
  const [modal, setModal] = useState(false);
  const [items, setItems] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "40px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={5}
            lg={5}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("My Transactions")}
              </span>
            </div>
          </Col>
          <Col
            xl={3}
            lg={3}
            style={{
              //  backgroundColor: "yellow",
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder={t("Search")}
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col
            xl={2}
            lg={2}
            style={{
              //  backgroundColor: "yellow",
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",

                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {t("Show All")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <div class="input-group rounded">
              <input
                type="date"
                class="form-control rounded"
                placeholder={t("Search")}
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* TABLE VIEW */}
      {/* <MDBDataTable
      // striped
      // bordered
      small
      bord
      data={data}
    /> */}
      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Order ID")}</th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Date")}</th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Client")}</th>

            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Descriptive")}</th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Amount")}</th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("State")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr
              onClick={toggle}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ padding: "20px" }}>#3241</td>
              <td style={{ padding: "20px" }}>16 December 2020</td>
              <td style={{ padding: "20px" }}>Florebela beats </td>
              <td style={{ padding: "20px" }}>Pay per Link</td>
              <td style={{ padding: "20px" }}>2,000.00 AKZ</td>
              <td style={{ padding: "20px" }}>Complete</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <div>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination>
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <Pagination.Item
                    onClick={() => setActivePage(item)}
                    active={activePage === item}
                  >
                    {item}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                  // color={'light'}
                  style={{
                    backgroundColor: "#fff",
                    color: "#333",
                    borderColor: "#CED4DA",
                    fontSize: "0.9rem",
                    width: "100%",
                  }}
                  caret
                >
                  <span
                    style={{
                      color: "#333",
                      fontSize: "0.7rem",
                    }}
                  >
                    {t("Items per page")}
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    1
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: "5px" }}>
                  <DropdownItem>1</DropdownItem>
                  <DropdownItem>2</DropdownItem>
                  <DropdownItem>3</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </div>
      </Row>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
}
