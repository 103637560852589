import React, { useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
import { policies } from "../../../DummyData";
import Accordion from "../FAQsPage/Accordion/Accordion";
import "./PrivacyPolicies.scss";

function PrivacyPolicies() {
  const [t] = useTranslation();
  const targetSectionRef = useRef(null);
  useEffect(() => {
    // window.scroll(0, 0);
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }, []);

  return (
    <div className="privacy-div">
      <h1 style={{ fontSize: "25px", marginTop: "30px", fontWeight: "bold" }}>
        Política de Privacidade
      </h1>
      <p style={{ fontSize: "18px" }}>
        Ultima atualização: 21 de Março de 2024
      </p>
      <p>
        A BayQi está comprometida em proteger a privacidade e a segurança dos
        seus usuários. Esta Política de Privacidade explica como coletamos,
        usamos e compartilhamos os seus dados pessoais quando utiliza o nosso
        aplicativo móvel, BayQi: Conta, eWallet. Esta Política de Privacidade
        foi elaborada para explicar como tratamos seus dados pessoais ao
        utilizar a aplicação móvel BayQi, em conformidade com a Lei de Proteção
        de Dados Pessoais em vigor na República de Angola (Lei nº 22/11 de 17 de
        Junho, a seguir referida como LPD), e foi emitida ou revisada na data
        indicada abaixo.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Responsável pelo Tratamento
      </h2>
      <p style={{ fontSize: "18px" }}>
        A Empresa PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU),
        LDA. (doravante referida como BayQi), com sede na Rua António Feliciano
        De Castilho, Casa 218/220, Vila Alice, Luanda, Angola, é responsável
        pelo tratamento dos dados pessoais dos usuários da aplicação BayQi. Para
        entrar em contacto connosco, utilize os seguintes meios: Telefone: +244
        944 090 618, E-mail:{" "}
        <span>
          <a href="">apoiocliente@bayqi.com</a>
        </span>{" "}
        <br></br>O processo de utilização dos serviços da PAGAQI – SOCIEDADE
        PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA, envolve a coleta
        automática de informações, conforme definido pelos métodos
        estabelecidos. Essas informações fornecidas pelos clientes durante o uso
        dos serviços são utilizadas com o objectivo de optimizar a qualidade dos
        serviços oferecidos, garantir a segurança das contas e dos fundos dos
        clientes, além de cumprir rigorosamente os requisitos legais vigentes.{" "}
        <br></br>
        Essa abordagem baseia-se nas políticas de privacidade da empresa, que
        regem a forma como os dados pessoais dos clientes são tratados e
        protegidos. A coleta e o uso das informações dos clientes são realizados
        de acordo com as disposições estabelecidas nessas políticas, garantindo
        transparência, segurança e conformidade com as regulamentações
        pertinentes.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Coleta e Utilização dos Seus Dados Pessoais
      </h2>
      <p>
        Ao registar, o cliente cria uma conta na aplicação. Durante a primeira
        autenticação, será necessário fornecer suas informações básicas de
        identidade:
        <ul>
          <li>- Nome completo </li>
          <li>- Numero de telemóvel</li>
          <li>- E-mail endereço opcional </li>
        </ul>
        Caso o cliente não forneça essas informações, não será possível concluir
        o registo e, consequentemente, utilizar os serviços.<br></br>
        Para garantir a precisão e veracidade das informações fornecidas, a
        PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA se
        reserva o direito de validar os dados junto aos órgãos governamentais
        responsáveis pela prestação de informações.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Dados que Coletamos
      </h2>
      <p>
        Dados Pessoais:
        <ul>
          <li style={{ listStyle: "disc" }}>Nome completo</li>
          <li style={{ listStyle: "disc" }}>Número de telefone</li>
          <li style={{ listStyle: "disc" }}>Endereço de e-mail (opcional)</li>
          <li style={{ listStyle: "disc" }}>
            Foto do documento de identificação válido
          </li>
          <li style={{ listStyle: "disc" }}>Endereço</li>
          <li style={{ listStyle: "disc" }}>IBAN (opcional)</li>
        </ul>
        Caso o cliente não forneça as informações solicitadas, a PAGAQI –
        SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA reserva-se o
        direito de não aceitar o registo. Este procedimento visa garantir o
        cumprimento da legislação aplicável e proteger a segurança e integridade
        dos serviços prestados.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Recolha de Dados por Captura de Imagem
      </h2>
      <p>
        Ao utilizar a aplicação BayQi, a BayQi poderá recolher dados pessoais
        através da captura de imagem para efeitos de cadastro e registo dos
        utilizadores da aplicação. Estes dados poderão incluir fotografias dos
        utilizadores para verificação de identidade (por exemplo, selfies), bem
        como outras informações necessárias para concluir o processo de registo.{" "}
        <br></br>A recolha de dados por captura de imagem tem como objectivo
        garantir a segurança e autenticidade dos utilizadores, bem como
        assegurar que apenas indivíduos autorizados tenham acesso à aplicação.
        Todos os dados recolhidos serão tratados com a máxima confidencialidade
        e apenas serão utilizados para os fins descritos nesta política de
        privacidade.<br></br>
        Ao utilizar a aplicação, concorda com a recolha de dados por captura de
        imagem para efeitos de cadastro e registo.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Uso dos serviços da PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE
        PAGAMENTO (SU), LDA
      </h2>
      <p>
        Ao utilizar os serviços da PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE
        PAGAMENTO (SU), LDA, oferecidos por meio de nossa aplicação móvel BayQi,
        os clientes têm acesso a uma variedade de serviços de pagamento. Isso
        inclui a possibilidade de adquirir uma ampla gama de produtos e serviços
        como; fazer compras em lojas online registadas, transferir fundos para
        terceiros, pagar contas de serviços públicos, entre outros. <br></br>
        Para atender às necessidades específicas de uso dos serviços de
        pagamento, solicitamos informações pessoais dos clientes durante o
        processo utilização dos serviços:
        <ul>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Registo de Saldo e Transações:
            </span>
            Para que possa utilizar o serviço de pagamento de saldo da sua
            carteira digital, registaremos as informações do seu saldo e
            detalhes das transações. Sem o seu consentimento, não será possível
            utilizar este serviço
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Transferências</span>
            Para realizar transferências, é necessário fornecer o número da
            conta da sua conta ou de terceiro conforme indicado e seleccionar o
            valor da transação. Se esta informação não for fornecida, não poderá
            efectuar a transferência. No entanto, isso não afectará o uso de
            outras funcionalidades disponíveis na aplicação.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Pagamentos de Serviços:</span>
            Ao efectuar pagamento de serviços como; carregar telemóvel, pagar
            mensalidade de televisão, energia ou água, será necessário fornecer
            o seu número de telefone, número de cliente de adesão ao serviço
            outras informações, conforme necessário
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Outras Funcionalidades da Aplicação:
            </span>
            Se utilizar outras funcionalidades da aplicação que exijam a recolha
            de informações pessoais, comprometemo-nos a explicar separadamente o
            objetivo da recolha de informações e a solicitar o seu consentimento
          </li>
        </ul>
      </p>
      <h2 id="testing" style={{ fontWeight: "bold", textAlign: "left" }}>
        Informações de Autenticação e Verificação
      </h2>
      <p>
        Para garantir a segurança dos seus dados e transações, a PAGAQI –
        SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA implementa
        procedimentos de autenticação e verificação.<br></br>
        <br></br>
        1.1. Verificação de Transações Financeiras: <br></br>
        Durante transações com a carteira digital (conta de pagamento), é
        fundamental verificar a identidade para assegurar a segurança dos
        fundos. A aplicação BayQi sempre solicitará o PIN código como método de
        autenticação em todas as transações.<br></br>
        <br></br>
        1.2. Verificação de Operações da Conta:<br></br>
        Para assegurar a segurança da conta e dos fundos, realizamos
        verificações de identidade ao recuperar senhas, reportar perdas de
        conta, congelar fundos ou solicitar recibos electrónicos. Essas
        verificações podem requerer o fornecimento de informações de
        identificação, como cópia do Bilhete de Identidade e detalhes de
        contacto.<br></br>
        <br></br>
        1.3. Monitoramento de Segurança:<br></br>
        Com o objectivo de aprimorar a segurança dos serviços fornecidos pela
        PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA e
        mitigar os riscos, podemos coletar informações sobre o uso dos serviços,
        como modelo de dispositivo, endereço IP e padrões de uso da rede.
      </p>
      <h2 ref={targetSectionRef} style={{ fontWeight: "bold", textAlign: "left" }}>
        Recolha de Dados por Meio de Terceiros:
      </h2>
      <p>
        Caso a PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA
        tenha necessidade de obter as suas informações pessoais por intermédio
        de terceiros para fornecer serviços, iremos comunicar consigo de forma
        transparente dando acesso à fonte e a total clareza sobre o tipo de
        informações que obtemos.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>Segurança</h2>
      <p>
        PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA
        valorizamos a sua confiança ao aceitar determinadas permissões que o
        aplicativo necessita durante as configurações.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>Permissões</h2>
      <p>
        1.1. Permissões de Leitura e Escrita de Armazenamento: Ao instalar o
        aplicativo, será solicitado a conceder essa permissão para garantir o
        funcionamento estável do mesmo. O aplicativo apenas lerá ou armazenará
        as informações necessárias <br></br>
        <br></br>
        1.2. Ativação dos Serviços de Geolocalização: Somente serão coletadas
        informações sobre a localização atual do cliente e não será realizado o
        rastreamento contínuo da sua localização. Após a ativação do serviço de
        geolocalização, o cliente poderá acessar diretamente suas informações de
        localização.<br></br>
        <br></br>
        1.3. Permissão de Acesso à Câmera: Pode usar a câmera para scanear,
        tirar fotos e compartilhar. Se for necessário reconhecimento facial,
        precisará habilitar o acesso à câmera.<br></br>
        <br></br>
        1.4. Permissão de Acesso ao Álbum: Ao abrir esta função, poderá
        seleccionar fotos ou imagens em seu dispositivo para comentar ou
        fornecer provas ao serviço ao cliente sobre produtos adquiridos.
        <br></br>
        <br></br>
        1.5. Autorização para Acesso aos Contatos: Ao recarregar o seu telemóvel
        e inserir as informações de contacto, poderá utilizar os dados contidos
        na sua lista de contatos. Após conceder esta permissão, poderá
        selecionar e importar diretamente as informações de contacto desejadas
        da lista. Apenas os dados dos contactos selecionados serão transferidos.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Segurança de Dados
      </h2>
      <p>
        A PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA está
        comprometida em garantir a segurança e a protecção dos dados pessoais
        dos seus usuários. Seguimos as melhores práticas de privacidade e
        protecção de dados para evitar qualquer forma de destruição, perda,
        alteração, divulgação ou acesso não autorizado, seja acidental ou
        ilícito.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Medidas de Segurança Técnicas e Organizacionais
      </h2>
      <p>
        Implementamos normas e procedimentos de segurança aplicáveis ao
        tratamento de dados pessoais. Essas normas detalham os diferentes níveis
        de segurança, os recursos protegidos e as responsabilidades dos
        colaboradores e terceiros com acesso aos dados. Utilizamos uma variedade
        de medidas técnicas e organizacionais para garantir a segurança dos
        dados:<br></br>
        <ul>
          <li style={{ listStyle: "disc" }}>
            Transmissão criptografada de dados durante a coleta e a
            transferência de informações.
          </li>
          <li style={{ listStyle: "disc" }}>
            Utilização de firewalls e armazenamento criptografado para proteger
            os dados armazenados.
          </li>
          <li style={{ listStyle: "disc" }}>
            Controle de acesso físico e autorização de acesso rigorosamente
            controlados.
          </li>
          <li style={{ listStyle: "disc" }}>
            Estabelecimento de um sistema especial de gerenciamento e
            organização para garantir a segurança das informações, incluindo
            limitação de acesso e auditorias regulares para garantir a
            conformidade
          </li>
        </ul>
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Como utilizamos as Informações
      </h2>
      <p>
        Em conformidade com as leis e regulamentos nacionais pertinentes e com o
        intuito de fornecer serviços de qualidade, aprimorar continuamente a
        experiência do usuário e garantir um ambiente seguro e personalizado,
        podemos empregar suas informações relevantes em diversas situações.
        Tomaremos medidas adequadas para garantir a segurança durante o uso,
        conforme detalhado a seguir:
        <ul>
          <li style={{ listStyle: "upper-roman" }}>
            Utilização das informações conforme o propósito da coleta mencionado
            anteriormente, visando fornecer os serviços da A PAGAQI – SOCIEDADE
            PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA.
          </li>
          <li style={{ listStyle: "upper-roman" }}>
            Relatar às autoridades competentes conforme exigido pelos requisitos
            legais e regulatórios.
          </li>
          <li style={{ listStyle: "upper-roman" }}>
            Utilizar as informações fornecidas pelo cliente ao utilizar os
            nossos serviços para verificação de identidade, atendimento ao
            cliente, medidas de segurança, prevenção de fraudes, detecção e
            prevenção de atividades ilegais, além de realizar arquivamento e
            backups para garantir a segurança dos produtos e serviços
            oferecidos.
          </li>
          <li style={{ listStyle: "upper-roman" }}>
            Aprimorar a experiência do usuário na aplicação, recomendando novos
            serviços com base nas informações e hábitos do usuário, por meio de
            publicidades que possam ser de interesse. Caso o usuário não deseje
            receber tais recomendações, é possível optar por cancelar a
            inscrição de acordo com as opções disponíveis.
          </li>
        </ul>
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Armazenamento e Proteção de Informações
      </h2>
      <p>
        As informações pessoais dos usuários serão armazenadas em nosso servidor
        seguro e tratadas de acordo com as leis e regulamentos aplicáveis.
        Manteremos essas informações estritamente confidenciais e as
        armazenaremos pelo tempo necessário para fornecer nossos serviços e
        cumprir obrigações legais. Por exemplo, dados de identificação e
        registros de transações serão mantidos conforme os prazos estabelecidos
        pelas leis e regulamentos de segurança cibernética. Depois de recebermos
        suas informações, usaremos procedimentos rigorosos e recursos de
        segurança para tentar impedir o acesso não autorizado.<br></br>
        Após o término do período legalmente exigido, as informações serão
        excluídas ou tornadas anónimas de acordo com as disposições legais
        aplicáveis. Adoptaremos medidas razoáveis e necessárias para garantir a
        segurança das informações em todos os níveis, incluindo tecnologias de
        segurança, protecção física e controle de acesso. Ainda, se necessário,
        conservaremos os seus dados pessoais para utilização em processos de
        âmbito judicial ou administrativo em que a PAGAQI – SOCIEDADE PRESTADORA
        DE SERVIÇOS DE PAGAMENTO (SU), LDA tenha alguma obrigatoriedade de
        defender os seus interesses.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Quais são os seus direitos em relação aos seus dados pessoais?
      </h2>
      <p>
        Saber como a PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO
        (SU), LDA trata os seus dados pessoais, incluindo os métodos de
        tratamento e os tipos de dados utilizados.
        <ul>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Corrigir ou solicitar a correção de dados incompletos, imprecisos
              ou desatualizados.
            </span>
            Encorajamos a atualizar suas informações sempre que necessário para
            garantir a precisão dos seus dados e melhorar sua experiência
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Solicitar a anonimização, bloqueio ou eliminação dos seus dados,
            </span>
            caso considere que o uso dos mesmos tem sido desnecessário ou
            excessivo. Avaliaremos o seu pedido e tomaremos as medidas
            apropriadas.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Solicitar a portabilidade dos seus dados para outro fornecedor de
              produtos ou serviços
            </span>
            caso opte por utilizar outra plataforma
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Obter informações sobre o compartilhamento dos seus dados
            </span>
            com terceiros
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Negar e/ou retirar o consentimento para o tratamento dos seus
              dados
            </span>
            sempre que necessário. Informaremos sobre as consequências dessa
            decisão.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Solicitar a revisão de decisões automatizadas
            </span>
            que possam afectar o usuário.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Opor-se ao tratamento dos seus dados
            </span>
            caso discorde. Avaliaremos o seu pedido e tomaremos as medidas
            adequadas, lembrando que alguns dados podem ser necessários para o
            uso dos nossos serviços ou cumprimento de obrigações legais
          </li>
        </ul>
        Além disso, os titulares de dados têm os seguintes direitos:
        <ul>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Direito à Informação:</span>Tem
            o direito de saber as finalidades e os métodos de tratamento dos
            seus dados, assim como outras informações relevantes.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Direito de Acesso:</span>Pode
            acessar as informações sobre os seus dados, sem restrições ou custos
            excessivos
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Direito de Oposição:</span>Pode
            se opor ao tratamento dos seus dados em certas circunstâncias.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Direito à Limitação do Tratamento:
            </span>
            Pode solicitar a limitação do tratamento dos seus dados em
            determinadas situações.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>
              Direito de Retificação, Atualização e Eliminação:{" "}
            </span>
            Pode corrigir, atualizar ou excluir os seus dados, se necessário.
          </li>
          <li style={{ listStyle: "auto" }}>
            <span style={{ fontWeight: "bold" }}>Direito à Portabilidade:</span>
            Tem o direito de receber os seus dados em um formato adequado e
            transmiti-los a outro responsável pelo tratamento, se tecnicamente
            possível.
          </li>
        </ul>
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Comunicação de Dados Pessoais a Terceiros:
      </h2>
      <p>
        Os dados dos titulares podem ser compartilhados com terceiros
        contratados pela PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO
        (SU), LDA para realizar certos fins de tratamento em seu nome (por
        exemplo, manutenção, segurança, análise de dados, envio de emails, ou
        domiciliação de informações). A PAGAQI – SOCIEDADE PRESTADORA DE
        SERVIÇOS DE PAGAMENTO (SU), LDA garante que esses terceiros mantenham a
        confidencialidade dos dados e atendam aos requisitos de protecção de
        dados estabelecidos pela presente política e pela lei aplicada. Além
        disso, em circunstâncias justificadas, a PAGAQI – SOCIEDADE PRESTADORA
        DE SERVIÇOS DE PAGAMENTO (SU), LDA pode ser obrigada a compartilhar
        dados pessoais com autoridades públicas, nacionais ou internacionais ou
        proteger-nos e aos serviços contra usos fraudulentos, abusivos ou
        ilegais; auxiliar agências governamentais responsáveis pela aplicação da
        lei.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Suspensão da Coleta de Dados ao Cancelar a Conta
      </h2>
      <p>
        Se decidir cancelar ativamente sua conta na PAGAQI – SOCIEDADE
        PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA e cessar o uso dos
        serviços oferecidos, vamos cessar a coleta e o uso de suas informações
        pessoais, a menos que seja exigido de outra forma por lei, regulamentos
        ou autoridades competentes. No caso de nossos produtos ou serviços serem
        descontinuados, também iremos interromper a coleta e uso de suas
        informações pessoais imediatamente. Além disso, iremos notificá-lo sobre
        a interrupção das operações por meio de anúncio individual.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Uso de Cookies e Tecnologias Semelhantes
      </h2>
      <p>
        Os cookies são identificadores (em formato de arquivo de texto) gerados
        ou recolhidos do seu dispositivo para disponibilizar uma página para
        acesso ou identificar o seu perfil de navegação. Utilizamos cookies para
        oferecer uma experiência personalizada, analisando os seus padrões de
        navegação na internet. Ao utilizar os serviços da PAGAQI – SOCIEDADE
        PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA, o seu estado de login
        poderá ser salvo, permitindo atribuir-lhe um ou mais "Cookies". Estes
        cookies serão armazenados no disco rígido do seu computador ou na
        memória flash do terminal do equipamento e só poderão ser lidos pelo
        servidor que os configurou.<br></br>
        Para fornecer os serviços mais convenientes, rápidos, fáceis e
        personalizados ao cliente, utilizaremos os seguintes serviços por meio
        de cookies e outras tecnologias:
        <ul>
          <li style={{ listStyle: "disc" }}>
            Lembrete da sua conta, eliminando a necessidade de inserir
            repetidamente os dados de login para utilizar os serviços da PAGAQI
            – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA;
          </li>
          <li style={{ listStyle: "disc" }}>
            Análise do uso dos serviços da PAGAQI – SOCIEDADE PRESTADORA DE
            SERVIÇOS DE PAGAMENTO (SU), LDA, para entender especificamente o seu
            padrão de uso ou quais páginas ou serviços web são mais populares
            para o cliente;
          </li>
          <li style={{ listStyle: "disc" }}>
            Optimização de publicidade, mostrando informações de produtos ou
            publicidade com base nas suas visitas a sites relacionados, sem
            incluir informações pessoais que possam ser usadas para avaliar a
            eficácia da publicidade;
          </li>
          <li style={{ listStyle: "disc" }}>
            Utilização de tecnologia "web beacon" para fazer a estatística dos
            dados acessados anonimamente, sem incluir informações pessoais.
          </li>
        </ul>
        O cliente pode escolher "não usar cookies" no navegador, o que pode
        afectar o uso dos serviços ou resultar em uma experiência menos segura,
        rápida, fácil e personalizada. No entanto, isso não afectará o uso
        normal de outros serviços fornecidos pela PAGAQI – SOCIEDADE PRESTADORA
        DE SERVIÇOS DE PAGAMENTO (SU), LDA. Os cookies não concedem acesso ao
        seu dispositivo nem revelam informações além daquelas que escolheu
        partilhar connosco. Trabalhando com parceiros de negócios e prestadores
        de serviços, podemos utilizar as informações recolhidas através dos
        cookies para oferecer produtos e serviços personalizados.<br></br>
        Pode gerir ou desactivar os cookies nas configurações do seu navegador,
        mas isso pode afetar a sua experiência de navegação. Além disso, os
        cookies ajudam-nos a realizar contagens de visitantes e a personalizar o
        conteúdo do site de acordo com as suas preferências, proporcionando uma
        navegação simplificada e personalizada.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Protecção da Privacidade para Crianças
      </h2>
      <p>
        Na PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA,
        damos especial atenção à proteção das informações pessoais de menores.
        Reconhecemos a importância da participação dos pais ou tutores no uso da
        nossa aplicação por parte dos menores. De acordo com as leis e
        regulamentos aplicáveis, incentivamos os pais ou tutores a reverem
        cuidadosamente esta política e a concederem autorização para o
        fornecimento das informações pessoais dos menores. <br></br>A admissão
        de menores na App PAGAQI requer o consentimento explícito dos pais ou
        tutores. Se os pais ou tutores não concordarem com o fornecimento das
        informações pessoais dos menores ou com o uso dos serviços da PAGAQI –
        SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA, solicitamos que
        suspendam imediatamente o envio de informações ou o uso dos nossos
        serviços. Pedimos que entrem em contacto connosco o mais rápido possível
        para que possamos tomar as medidas apropriadas.<br></br>
        Além disso, pretendemos que apenas adultos com pelo menos dezoito (18)
        anos usem a nossa aplicação. A PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS
        DE PAGAMENTO (SU), LDA, não recolhe conscientemente informações pessoais
        de crianças menores de 18 anos para os seus próprios propósitos. Se
        tomarmos conhecimento de que uma criança menor de 18 anos nos forneceu
        informações pessoais, tomaremos medidas para eliminar essas informações.
        No entanto, ao usar os nossos produtos, poderá recolher informações
        sobre indivíduos que possam ser crianças. Se processar informações
        relacionadas a crianças, reconhece e concorda que será responsável por
        cumprir as leis e regulamentos aplicáveis relacionados à protecção
        dessas informações pessoais.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Comunicação e Notificações
      </h2>
      <p>
        O PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA pode
        ocasionalmente enviar notícias e atualizações da nossa política de
        privacidade através de emails, mensagens push, pop-ups, WhatsApp e SMS,
        incluindo notificações de pagamentos, novidades, pesquisas, ofertas e
        promoções. Se preferir não receber essas comunicações, você pode
        desactivar a opção de notificações nos ajustes do aplicativo ou optar
        por bloquear mensagens no WhatsApp. Em caso de continuidade no
        recebimento indevido, entre em contacto connosco através da Sugestões do
        aplicativo.<br></br>
        Também valorizamos a sua privacidade e segurança. Por isso, se receber
        alguma mensagem de outra pessoa ou empresa usando o nome do PAGAQI –
        SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA sem autorização,
        por favor, informe-nos para que possamos tomar as devidas providências.
        Recomendamos que nos informes sobre quaisquer alterações nos seus dados
        para garantir que continue a receber notificações importantes em tempo
        útil.
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>Reclamações</h2>
      <p>
        Se o titular dos dados, tiver alguma preocupação relacionada com
        violações de dados pessoais ou qualquer outra questão sobre privacidade,
        pode entrar em contacto directamente com a PAGAQI – SOCIEDADE PRESTADORA
        DE SERVIÇOS DE PAGAMENTO (SU), LDA através dos seguintes meios de
        contacto:
        <ul>
          <li style={{ listStyle: "disc" }}>Telefone: +244 944 090 618</li>
          <li style={{ listStyle: "disc" }}>E-mail: apoiocliente@bayqi.com</li>
        </ul>
        Além disso, tem o direito de apresentar uma reclamação junto à
        Autoridade Nacional de Protecção de Dados (APD).
      </p>
      <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
        Atualização da Política de Privacidade
      </h2>
      <p>
        A PAGAQI – SOCIEDADE PRESTADORA DE SERVIÇOS DE PAGAMENTO (SU), LDA pode
        atualizar esta Política de Privacidade periodicamente. As alterações
        serão notificadas por meio de avisos no aplicativo, e-mails ou mensagens
        push.
      </p>
    </div>
  );
}

export default PrivacyPolicies;
