import "../SideDrawer/SideDrawer.css";
import Login from "../Pages/Login/Login";
import HeaderWithDrawer from "./HeaderWithDrawer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Transactions from "../Pages/Transactions/Transactions";
import AccountSettings from "../Pages/AccountSettings/AccountSettings";
import PrivateRoute from "./PrivateRoute";
import TransactionHistory from "../Pages/TransactionHistory/TransactionsHistory";
import Swing from "../Pages/Swing/Swing";
import BankTransfer from "../Pages/BankTransfer/BankTransfer";
import BankTransferHistory from "../Pages/BankTransferHistory/BankTransferHistory";
import Categories from "../Pages/Categories/Categories";
import Products from "../Pages/Products/Products";
import Definitions from "../Pages/Definitions/Definitions";
import GenerateLink from "../Pages/GenerateLink/GenerateLink";
import PayPerLink from "../Pages/PayPerLink/PayPerLink";
import PayByCatalog from "../Pages/PaymentByCatalog/PayByCatalog";
import { useTranslation } from "react-i18next";



function PanelRouter() {
  const [t] = useTranslation();
  const routes = [
    {
      path: "/panel",
      exact: true,
      name: t("Home"),
      component: Home,
    },
    {
      path: "/panel/transactions",
      exact: true,
      name: t("Transactions"),
      component: Transactions,
    },
    {
      path: "/panel/transaction-history",
      exact: true,
      name: t("TransactionHistory"),
      component: TransactionHistory,
    },
    {
      path: "/panel/swing",
      exact: true,
      name: t("Swing"),
      component: Swing,
    },
    {
      path: "/panel/account-settings",
      exact: true,
      name: t("AccountSettings"),
      component: AccountSettings,
    },
    {
      path: "/panel/bank-transfer",
      exact: true,
      name: t("BankTransfer"),
      component: BankTransfer,
    },
    {
      path: "/panel/bank-transfer-history",
      exact: true,
      name: t("BankTransferHistory"),
      component: BankTransferHistory,
    },
    {
      path: "/panel/categories",
      exact: true,
      name: t("Categories"),
      component: Categories,
    },
    {
      path: "/panel/products",
      exact: true,
      name: t("Products"),
      component: Products,
    },
    {
      path: "/panel/definitions",
      exact: true,
      name: t("Definitions"),
      component: Definitions,
    },
    {
      path: "/panel/generate-link",
      exact: true,
      name: t("GenerateLink"),
      component: GenerateLink,
    },
    {
      path: "/panel/pay-per-link",
      exact: true,
      name: t("PayPerLink"),
      component: PayPerLink,
    },
    {
      path: "/panel/pay-by-catalog",
      exact: true,
      name: t("PayByCatalog"),
      component: PayByCatalog,
    },
  ];
  return (
    // <Route exact path="/login" component={Login} />
    <HeaderWithDrawer>
      {routes.map((route, idx) => {
        return route.component ? (
          <PrivateRoute
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            Component={route.component}
          />
        ) : null;
      })}
    </HeaderWithDrawer>
  );
}

export default PanelRouter;
