import React, { useState, useEffect } from "react";
import ErrorAnimation from './errorAnimation';

const Error = (props) => {
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  useEffect(() => {
    // Equivalent to componentDidCatch lifecycle method
    const handleCatch = (error, errorInfo) => {
      console.log('catch Error', error, errorInfo);
      setError(error);
      setErrorInfo(errorInfo);
      // You can also log error messages to an error reporting service here
    };

    // Add event listener for errors
    window.addEventListener('error', handleCatch);

    // Cleanup function to remove listener on unmount
    return () => window.removeEventListener('error', handleCatch);
  }, []); // Empty dependency array to mimic componentDidCatch behavior

  if (errorInfo) {
    // Error path
    return (
      <div>
        {/*<h2>Something went wrong.</h2>*/}
        {/*<details style={ { whiteSpace: 'pre-wrap' } }>*/ }
        {/* { error && error.toString() }*/}
        {/* <br/>*/ }
        {/* { errorInfo.componentStack }*/}
        {/*</details>*/ }
        <ErrorAnimation />
      </div>
    );
  } else {
    // Normally, just render children
    return props.children;
  }
};

export default Error;