import filtersTypes                                        from './filters.types';
import { handleReduceFilterItems, handleRemoveFilterItem } from './filters.utils'

const INITIAL_STATE = {
  filters: [],
};

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case filtersTypes.SET_FILTERS:
      return {
        ...state,
        filters: state.filters.concat(action.payload)
      }
    case filtersTypes.ADD_FILTER:
      return {
        ...state,
        filters: state.filters.concat(action.payload)
      }
    case filtersTypes.REMOVE_FILTER:
      return {
        ...state,
        filters: handleRemoveFilterItem({
          prevFilters: state.filters,
          filterItemToRemove: action.payload
        })
      }
    case filtersTypes.EDIT_FILTER:
      return {
        ...state,
        filters: state.filters.concat(action.payload)
      }
    case filtersTypes.REDUCE_FILTERS:
      return {
        ...state,
        filters: handleReduceFilterItems({
          prevFilters: state.filters,
          filterItemsToReduce: action.payload
        })
      }
    case filtersTypes.RESET_FILTERS:
      return {
        ...state,
        filters: []
      }
    default:
      return state;
  }
};

export default filtersReducer;