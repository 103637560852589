import productsTypes from './products.types';
import productTypes  from './products.types';

const INITIAL_STATE = {
  productLines: [],
  savedProducts: [],
  product: {},
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productTypes.SET_PRODUCTS:
      return {
        ...state,
        productLines: state.productLines.concat(action.payload)
      }
    case productsTypes.SET_PRODUCT:
      return {
        ...state,
        product: action.payload
      }
    case productsTypes.SAVE_PRODUCT_FOR_LATER:
      return {
        ...state,
        savedProducts: state.savedProducts.concat(action.payload)
      }
    default:
      return state;
  }
};

export default productsReducer;