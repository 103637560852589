import React, { useState } from "react";
import "./Definitions.css";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import {
  ButtonToggle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";
import classnames from "classnames";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
export default function Definitions() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [DeliveryTable, setDeliveryTable] = useState([0, 1, 2, 3, 4, 5]);
  const [activePageDelivery, setActivePageDelivery] = useState(1);
  const [HouseTable, setHouseTable] = useState([0, 1, 2, 3, 4, 5]);
  const [activePageHouse, setActivePageHouse] = useState(1);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          marginTop: "50px",
          padding: "12px 5px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            xl={12}
            lg={12}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Definitions")}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={{
          marginTop: "2%",
          alignSelf: "center",
        }}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("Delivery")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("House of Operation")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane style={{}} tabId="1">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row style={{ width: "100%" }}>
                <Col xl={7} lg={7}></Col>
                <Col xl={2} lg={2}></Col>
                <Col xl={2} lg={2}></Col>
                <Col xl={1} lg={1}>
                  <ButtonToggle
                    // onClick={openAddModal}
                    style={{
                      backgroundColor: "#049EFF",
                      borderWidth: 0,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0px",
                    }}
                  >
                    <span>{t("Add")}</span>
                  </ButtonToggle>
                </Col>
              </Row>
              <Table
                style={{
                  cursor: "pointer",
                  marginTop: "25px",
                  backgroundColor: "#f7f7f7",
                  //   borderRadius: "25px",
                }}
                borderless={true}
                hover
              >
                <thead>
                  <tr>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Country")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Neighbourhood")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Delivery Time")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Weight")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Class Weight")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Amount")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("State")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {DeliveryTable.map((item, index) => (
                    <tr
                      onClick={toggle}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                        fontSize: "12px",
                        color: "#4f4f4f",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        Talotona
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        999
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        2 Days
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        2 Kg
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        3 Kg
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        22 AKZ
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        Active
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pagination>
                        {[1, 2, 3, 4, 5].map((item, index) => (
                          <Pagination.Item
                            onClick={() => setActivePageDelivery(item)}
                            active={activePageDelivery === item}
                          >
                            {item}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          // color={'light'}
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            1
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem>1</DropdownItem>
                          <DropdownItem>2</DropdownItem>
                          <DropdownItem>3</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row style={{ width: "100%" }}>
                <Col xl={7} lg={7}></Col>
                <Col xl={2} lg={2}></Col>
                <Col xl={2} lg={2}></Col>
                <Col xl={1} lg={1}>
                  <ButtonToggle
                    // onClick={openAddModal}
                    style={{
                      backgroundColor: "#049EFF",
                      borderWidth: 0,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0px",
                    }}
                  >
                    <span>{t("Add")}</span>
                  </ButtonToggle>
                </Col>
              </Row>
              <Table
                style={{
                  cursor: "pointer",
                  marginTop: "25px",
                  backgroundColor: "#f7f7f7",
                  //   borderRadius: "25px",
                }}
                borderless={true}
                hover
              >
                <thead>
                  <tr>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Day of the Week")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Opening")}
                    </th>
                    <th style={{ alignSelf: "center", padding: "20px" }}>
                      {t("Closing")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {HouseTable.map((item, index) => (
                    <tr
                      onClick={toggle}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                        fontSize: "12px",
                        color: "#4f4f4f",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        Monday
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        08:30 H/M
                      </td>
                      <td style={{ alignSelf: "center", padding: "20px" }}>
                        22:00 H/M
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pagination>
                        {[1, 2, 3, 4, 5].map((item, index) => (
                          <Pagination.Item
                            onClick={() => setActivePageHouse(item)}
                            active={activePageHouse === item}
                          >
                            {item}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          // color={'light'}
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            1
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem>1</DropdownItem>
                          <DropdownItem>2</DropdownItem>
                          <DropdownItem>3</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
