import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faqsTopics } from "../../../DummyData";
import { ImagesLoader } from "../../../helpers";
import Accordion from "./Accordion/Accordion";
import "./FAQs.scss";

const FAQs = () => {
  const [t] = useTranslation();
  const [tabs, setTabs] = useState(faqsTopics);
  const [selectedTab, setSelectedTab] = useState(faqsTopics[0].id);
  const [newTabs, setNewTabs] = useState([faqsTopics[0]]);
  const [loading, setLoading] = useState(false);

  // state = {
  //   tabs: [],
  //   selectedTab: "",
  //   newTabs: [],
  //   loading: true,
  // };

  // useEffect(() => {
  //   window.scroll(0, 0);
  //   try {
  //     // setTabs(faqsTopics);
  //     // setSelectedTab(faqsTopics[0].id);
  //     // setNewTabs([faqsTopics[0]]);
  //     // setLoading(false);
  //     // this.setState({
  //     //   tabs: faqsTopics,
  //     //   selectedTab: faqsTopics[0].id,
  //     //   newTabs: [faqsTopics[0]],
  //     //   loading: false,
  //     // });
  //   } catch (e) {
  //     console.log("warning: ", e.message);
  //   }
  // });

  // load selected tab
  const loadSelectedTab = (tabId) => {
    try {
      const filterTabData = tabs.filter((x) => x.id === tabId);
      setSelectedTab(tabId);
      setNewTabs(filterTabData);
      // this.setState({ selectedTab: tabId, newTabs: filterTabData });
    } catch (e) {
      console.log(e);
    }
  };
  // get specs tabs
  const getTopicsTabs = () => {
    try {
      return tabs.map((tab, idx) => {
        return (
          <li
            onClick={() => loadSelectedTab(tab.id)}
            className={`faqs-left-li ${
              selectedTab === tab.id ? "active-tab" : ""
            }`}
          >
            {tab.title}
          </li>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  // get tab detail
  const getTopicTabDetail = () => {
    try {
      return newTabs.map((tab, idx) => {
        return (
          <div
            key={idx}
            className="tab-pane fade show active"
            id={`${tab.title.replaceAll(" ", "-").toLowerCase()}`}
            role="tabpanel"
            aria-labelledby={`${tab.title
              .replaceAll(" ", "-")
              .toLowerCase()}-tab`}
          >
            <Accordion tabData={tab.detail} />
          </div>
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  const selectedTabTitle = tabs.find((tab) => tab.id === selectedTab)
    ? tabs.find((tab) => tab.id === selectedTab).title
    : "";
  return (
    <div id={"faqs-sec"} className={"d-flex flex-column flex-wrap faqs-wrap"}>
      <div className={"faqs-header-wrap"}>
        <div
          style={{
            width: "100%",
            height: "30rem",
            backgroundImage: `url(${ImagesLoader("faqs")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      {loading ? (
        <div>{t("loading..")}</div>
      ) : (
        !!tabs &&
        tabs.length > 0 && (
          <div className={"d-flex flex-row flex-wrap faqs-body-wrap"}>
            <div className={"col col-xl-4 col-lg-4 col-md-4 col-sm-4"}>
              <div className={"faqs-left-toggles"}>
                <ul className={"faqs-left-ul"}>{getTopicsTabs()}</ul>
              </div>
            </div>
            <div className={"col col-xl-8 col-lg-8 col-md-8 col-sm-8"}>
              <div className={"faqs-right-toggles"}>
                <div className={"faqs-right-title"}>{selectedTabTitle}</div>
                <div className={"faqs-right-detail-toggles"}>
                  {getTopicTabDetail()}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FAQs;
