import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import ProductCard from "./ProductCard/ProductCard";
import ProductsSlider from "./ProductCardsSlider/Slider";
import "./SectionFive.scss";

// import {products} from '../../../../DummyData'

const Products = (props) => {
  const [product, setProduct] = useState([]);

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     products: [],
  //   };
  // }
  useEffect(() => {
    const { products } = props;
    // console.log("These are the products yoo: ", products);
    try {
      // fetch products for given shape and then remove dummy data from state
      setProduct(products);
      // this.setState({
      //   products: products,
      // });
      // this.props.dispatch(setProducts(trendingProducts))
    } catch (e) {
      console.log(e);
    }
  }, [props.activeCategory, JSON.stringify(props.products)]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { activeCategory, products } = props;
  //   if (
  //     activeCategory !== prevProps.activeCategory ||
  //     JSON.stringify(products) !== JSON.stringify(prevProps.products)
  //   ) {
  //     this.setState({ products: products });
  //   }
  // }

  //helper to get products
  const getProducts = () => {
    const products = product;
    // console.log("Huzoor ye hain wo products: ", products);
    try {
      return products.map((pro, idx) => {
        return (
          <ProductCard
            key={idx}
            id={pro._id}
            img={pro.img}
            label={pro.label}
            discount={pro.discount}
            originalPrice={pro.originalPrice}
            discountedPrice={pro.discountedPrice}
            category={pro.category}
            categoryName={pro.categoryName}
            {...pro}
          />
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  const products = product;
  const { loading, title } = props;
  return (
    <div style={{ margin: "0 1rem" }} className={"d-flex flex-wrap"}>
      <div
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          fontFamily: "'Sen', sans-serif",
        }}
        className={"related-products-title"}
      >
        {title}
      </div>
      {loading ? (
        <div className={"products-loader-wrap"}>
          <div className={"product-loader"}>
            <Skeleton />
          </div>
          <div className={"product-loader"}>
            <Skeleton />
          </div>
          <div className={"product-loader"}>
            <Skeleton />
          </div>
          <div className={"product-loader"}>
            <Skeleton />
          </div>
        </div>
      ) : (
        !!products &&
        products.length > 0 && <ProductsSlider>{getProducts()}</ProductsSlider>
      )}
    </div>
  );
};

export default connect(null)(Products);
