import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import { ImagesLoader } from "../../../helpers";
import { isModalOpened } from "../../../redux/auth/auth.actions";
import TermsConditions from "./TERMS AND CONDITIONS.pdf";
import PrivacyPolicy from "./POLÍTICA DE PRIVACIDADE.pdf";
import "./FooterV2.scss";
function FooterV2() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();

  const createAccount = () => {
    dispatch(isModalOpened({ isOpenModal: true, modelView: "login" }));
  };

  const signUp = () => {
    dispatch(isModalOpened({ isOpenModal: true, modelView: "create" }));
  };
  return (
    <div className={"d-flex flex-wrap flex-column w-100"}>
      <footer className="site-footer">
        <div className={"container p-0"}>
          <div className={"logo-row mb-2"}>
            <Link to={"/"}>
              <div
                style={{
                  backgroundImage: `url(${ImagesLoader("white-logo")})`,
                  margin: "0 2rem 0 0",
                }}
                className={"logo white-logo"}
              ></div>
            </Link>

            <ul className="social-icons social-icons-footer m-0">
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/bayqi/"
                  target="blank"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/oficialbayqi/?hl=pt"
                  target="blank"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  className="youtube"
                  href="https://www.youtube.com/channel/UCTkhgki5Zc3vRbUS3gnJIAg?view_as=subscriber"
                  target="blank"
                >
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  className="whatsapp"
                  href="https://wa.me/244939725027"
                  target="blank"
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="links-sec-wrap w-100">
            <div className="links-sec ">
              <h6 id="contacts" style={{ fontWeight: "bold" }}>
                Contacts
              </h6>
              <ul className="footer-links">
                <li>
                  <span>
                    <i className="fa fa-phone-square"></i> +244 944 090 618
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fa fa-envelope"></i> apoiocliente@bayqi.com
                  </span>
                </li>
                <li>
                  <Link to={"/"}>Rua António Feliciano</Link>
                </li>
                <li>
                  <Link to={"/"}>Castilho Vila Alice</Link>
                </li>
              </ul>
            </div>

            <div className="links-sec">
              <h6 style={{ fontWeight: "bold" }}>Conheça-nos</h6>
              <ul className="footer-links">
                <li>
                  <Link to={"/sobrebayqi"}>Sobre BayQi</Link>
                </li>
                <li>
                  <Link to={"/baixeapp"}>Baixe App</Link>
                </li>
                <li>
                  <Link to={"/faqs"}>Centro de Ajuda</Link>
                </li>
                {/* <li><Link to={ "/blog" }>Blog</Link></li> */}
              </ul>
            </div>

            {/* <div className="links-sec ">
                <h6 style={ { fontWeight: "bold" } }>Serviços</h6>
                <ul className="footer-links">
                  <li><Link to={ "/shipment" }>Pagamento</Link></li>
                  <li><Link to={ "/qr-code" }>Pague com BayQi</Link></li>
                  <li><Link to={ "/shop" }>Shopping</Link></li>
                </ul>
              </div> */}

            <div className="links-sec">
              <h6 style={{ fontWeight: "bold" }}>Informação</h6>
              <ul className="footer-links">
                {/* <li><a href={PrivacyPolicy+ "#toolbar=0"} target='_blank'>Politica de Privacidade</a> */}
                <li>
                  <a href="/politica-de-privacidade" target="_blank">
                    Politica de Privacidade
                  </a>
                </li>
                <li>
                  <a href="/terms-conditions" target="_blank">
                    Termos e Condições
                  </a>
                </li>

                <li>
                  <Link to={"/cookies-Policies"}>Politica de Cookies</Link>
                </li>
              </ul>
            </div>

            {/* <div className="links-sec">
                <h6 style={ { fontWeight: "bold" } }>Quick Links</h6>
                <ul className="footer-links">
                  <li><a onClick={ () => this.createAccount() }><i className="fa fa-user-circle"></i> Create Account</a>
                  </li>
                  <li><a onClick={ () => this.signUp() }><i className="fa fa-sign-in"></i> Sign Up</a></li>
                </ul>
              </div> */}
          </div>
          <hr></hr>
        </div>

        <div className="container">
          <div className="row w-100">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                All Rights Reserved. &copy; BayQi 2021
              </p>
            </div>

            <div
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
              className="col-md-4 col-sm-6 col-xs-12"
            >
              <div
                style={{ justifyContent: "end" }}
                className={"d-flex flex-row flex-wrap w-100"}
              >
                <a
                  href="https://apps.apple.com/pk/app/bayqi/id1669111904"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <Link to="apps.apple.com/pk/app/bayqi/id1669111904"> */}
                  <div
                    style={{
                      backgroundImage: `url(${ImagesLoader("on-apple-app")})`,
                      height: "3em",
                      width: "9em",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      margin: "0 1.5em 0.2em 0",
                      cursor: "pointer",
                    }}
                  ></div>
                  {/* </Link> */}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.stackone.bayqi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    style={{
                      backgroundImage: `url(${ImagesLoader("on-android-app")})`,
                      height: "3em",
                      width: "9em",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                    }}
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

// createAccount = () => {
//   this.props.dispatch(isModalOpened({ isOpenModal: true, modelView: 'login' }))
// }

// signUp = () => {
//   this.props.dispatch(isModalOpened({ isOpenModal: true, modelView: 'create' }))
// }

// }

export default React.memo(FooterV2);
// export default withRouter(connect(null)(FooterV2));
