import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {
  const [t] = useTranslation();
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* <Container fluid> */}
        <div className="header-body">
          {/* Card stats */}
          <Row style={{ padding: "0px 25px" }}>
            <Col lg="3" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        {t("Accounting Balance")}
                      </CardTitle>
                      <span className="h4 font-weight-bold mb-0">
                        276,706.70 AKZ
                      </span>
                    </div>
                    <Col className="col-auto">
                      {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow"> */}
                      <i className="fas fa-chart-bar" />
                      {/* </div> */}
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>{" "}
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        {t("Balance available")}
                      </CardTitle>
                      <span className="h4 font-weight-bold mb-0">
                        276,766.70 AKZ
                      </span>
                    </div>
                    <Col className="col-auto">
                      {/* <div className="icon icon-shape bg-warning text-white rounded-circle shadow"> */}
                      <i className="fas fa-chart-pie" />
                      {/* </div> */}
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-danger mr-2">
                      <i className="fas fa-arrow-down" /> 3.48%
                    </span>{" "}
                    <span className="text-nowrap">Since last week</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        {t("captive balance")}
                      </CardTitle>
                      <span className="h4 font-weight-bold mb-0">
                        1,937,002.00 AKZ
                      </span>
                    </div>
                    <Col className="col-auto">
                      {/* <div className="icon icon-shape bg-yellow text-white rounded-circle shadow"> */}
                      <i className="fas fa-users" />
                      {/* </div> */}
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-warning mr-2">
                      <i className="fas fa-arrow-down" /> 1.10%
                    </span>{" "}
                    <span className="text-nowrap">Since yesterday</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3"></Col>
          </Row>
        </div>
        {/* </Container> */}
      </div>
    </>
  );
};

export default Header;
