import React, { useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./RangeSlider.scss";

export default function RangeSlider({ props }) {
  const [values, setValues] = React.useState({ min: 265, max: 1569 });

  const setValue = (value) => {
    setValues(values);
    props.setRangeValues(value);
  };

  return (
    <InputRange
      step={2}
      maxValue={10000}
      minValue={0}
      value={this.state.value}
      onChange={(value) => setValue(value)}
    />
  );

}

export const RangeSliderHook = ({ setRangeValues }) => {
  const [value, setValue] = useState({ min: 265, max: 1569 });
  const setCustomValue = (newValue) => {
    setValue(newValue);
    setRangeValues(newValue);
  };

  return (
    <InputRange
      step={2}
      maxValue={10000}
      minValue={0}
      value={value}
      onChange={(value) => setCustomValue(value)}
    />
  );
};
