import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import Error from "./Error/Error";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
// import Amplify, { Auth } from "aws-amplify";
// import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Errorpage from "./404page";
import './i18n';
// Amplify.configure(awsconfig);


ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <Error>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </Error>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
