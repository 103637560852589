import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduceFilters } from "../../../../../redux/Filters/filters.actions";
import "../FilterLeftSection.css";
import FilterSearchBox from "../SearchBox/SearchBox";

function FilterBox({ props }) {
  const [t] = useTranslation();
  const [opened, setOpened] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [isSearch, setIsSearch] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [prevProps, setPrevProps] = React.useState(props);

  const { filters } = props;
  React.useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(prevProps.filters)) {
      if (filters && filters.length > 0) {
        const values = filters.map((x) => x.value);
        const newList = list.map((x) => {
          if (!values.includes(x.name)) {
            return {
              ...x,
              isChecked: false,
            };
          } else {
            return x;
          }
        });
        setList(newList);
      } else {
        let temp = list.map((x) => ({ ...x, isChecked: false }));
        setList(temp);
      }
    }

    setPrevProps(props);
  }, [props.filters]);

  //handlers

  const handleClearAll = () => {
    try {
      const filters = list
        .filter((x) => x.isChecked)
        .map((x) => ({ label: x.name, value: x.name }));
      !!filters && filters.length > 0 && props.dispatch(reduceFilters(filters));
      const reducedList = list.map((list) => {
        return {
          ...list,
          isChecked: false,
        };
      });
      setList(reducedList);
    } catch (e) {
      console.log(e);
    }
  };

  //helpers

  const updateCheckedBoxes = (name) => {
    this.setState({
      list: list.map((brand, idx) => {
        return {
          ...brand,
          isChecked:
            brand.name === name && !brand.isChecked ? true : brand.isChecked,
        };
      }),
    });
  };

  // get brand list
  const getBrandsList = () => {
    try {
      return list.map((brand, idx) => {
        return (
          <FilterSearchBox
            key={idx}
            id={idx}
            name={brand.name}
            count={brand.count}
            isChecked={brand.isChecked}
            updateCheckedBoxes={updateCheckedBoxes}
          />
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={"filter-wrap"}>
      <div onClick={() => setOpened(!opened)} className={"filter-head"}>
        <div className={"filter-head-title"}>{title}</div>
        <div className={"filter-head-icon"}>
          <i
            style={{ fontSize: "0.8em !important", color: "#cccccc" }}
            className={`accordion-item__icon fa ${
              opened ? "fa-minus" : "fa-plus"
            }`}
          ></i>
        </div>
      </div>
      <div className={`filter-search-body${opened ? "-opened" : ""}`}>
        {isSearch && (
          <div className={"filter-search-bar"}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="search-brands"
                placeholder={t("Search")}
              />
            </div>
          </div>
        )}

        <div className={"filter-search-list"}>
          {!!list && list.length > 0 && list.some((x) => x.isChecked) && (
            <div
              onClick={handleClearAll}
              className={"filter-search-list-clear-all"}
            >
              {t("Clear All")}
            </div>
          )}
          <ul className={"filter-search-list-ul"}>{getBrandsList()}</ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters.filters,
  };
};

export default withRouter(connect(mapStateToProps)(FilterBox));
